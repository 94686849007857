import React from "react";
import { StyleSheet, ScrollView, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigation, useRoute } from "@react-navigation/native";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import KeyboardAvoidingView from "../../components/KeyboardAvoidingView";
import TextInputField from "../../components/TextInputField";
import Button from "../../components/Button";
import { colors } from "../../theme";
import { REQUEST_RESET } from "./gql";
import Dialog from "../../components/Dialog";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  formContainer: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  scrollViewInner: {
    paddingHorizontal: 20,
    marginTop: 20,
  },
});

const Schema = Yup.object().shape({
  emailAddress: Yup.string()
    .email("Email address must be a valid email")
    .required("Email address is required"),
});

const ForgotPasswordScreen = () => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const auth = getAuth();

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <Dialog
        title="Instructions sent"
        description="If we had a Bodyart account on file for the email address you gave you should receive instructions shortly."
        isOpen={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        confirmLabel="OK"
        onConfirm={() => {
          setConfirmOpen(false);
          navigation.navigate("LoginViaEmail", { ...params });
        }}
      />

      <KeyboardAvoidingView style={styles.formContainer}>
        <Formik
          initialValues={{ emailAddress: "" }}
          validationSchema={Schema}
          onSubmit={async (input) => {
            await sendPasswordResetEmail(auth, input.emailAddress);
            setConfirmOpen(true);
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            touched,
            errors,
            isSubmitting,
            isValid,
          }) => {
            return (
              <>
                <Container>
                  <ScrollView
                    style={styles.scrollView}
                    contentContainerStyle={styles.scrollViewInner}
                  >
                    <TextInputField
                      autoFocus
                      autoCompleteType="email"
                      name="emailAddress"
                      textContentType="emailAddress"
                      autoCapitalize="none"
                      keyboardType="email-address"
                      placeholder="Email Address"
                      hasError={!!errors?.emailAddress}
                      onChangeText={(text) =>
                        handleChange("emailAddress")(text.trim())
                      }
                      onBlur={handleBlur("emailAddress")}
                      value={values.emailAddress}
                      touched={!!touched.emailAddress}
                      error={errors.emailAddress}
                    />
                  </ScrollView>
                </Container>
                <StickyFooter>
                  <Button
                    title="Submit"
                    onPress={handleSubmit}
                    disabled={!isValid}
                    isLoading={isSubmitting}
                  />
                </StickyFooter>
              </>
            );
          }}
        </Formik>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default ForgotPasswordScreen;

import { useQuery } from "@apollo/client";
import { omit } from "lodash";
import React, { FunctionComponent } from "react";
import { StyleSheet, ScrollView, ActivityIndicator } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import Header from "../../components/Header";
import { bootstrap } from "../../state/consentFlow";
import { colors } from "../../theme";
import { FETCH_CUSTOMER } from "./gql";
import EditableSection from "../../components/EditableSection";
import CustomerRow from "./CustomerRow";
import { Customer } from "../../types";
import { RootState } from "../../state";
import { hasCompletedProfile } from "../../utils/customer";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";
import { useFirebaseAuth } from "../../firebase/AuthProvider";
import useCustomer from "../../hooks/useCustomer";
import { formatDateOfBirth } from "../../utils/customer";
import Text from "../../components/Text";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  scrollView: {
    paddingHorizontal: 20,
  },
  sectionText: {
    color: colors.midGrey,
  },
});

interface Props {
  navigation: any;
}

const ExistingCustomerScreen: FunctionComponent<Props> = ({ navigation }) => {
  const dispatch = useDispatch();
  const { currentCustomer } = useFirebaseAuth();
  const consentState = useSelector((state: RootState) => state.consentFlow);
  const subject = useCustomer(
    consentState.subject.id || (currentCustomer?.id as string),
  );

  const { loading } = useQuery(FETCH_CUSTOMER, {
    variables: { customerId: consentState.subject.id || currentCustomer?.id },
    onCompleted: ({ customer }) => {
      dispatch(
        bootstrap({
          medicalHistory: customer?.medicalHistory?.medicalConditions,
          medicalConcerns: {
            answers:
              omit(customer?.medicalHistory, [
                "id",
                "additionalNotes",
                "medicalConditions",
                "__typename",
              ]) || [],
            additionalNotes: customer?.medicalHistory?.additionalNotes,
          },
        }),
      );
    },
  });

  const hasProfileInformation = hasCompletedProfile(
    currentCustomer as Customer,
  );

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <Container>
        <ScrollView style={styles.scrollView}>
          <Header description="Please check to make sure all the information listed for you is correct and not out of date." />

          {loading && <ActivityIndicator color={colors.yellow} />}

          {consentState.subject.role !== "self" && (
            <EditableSection
              label="Minor Info"
              onEditPress={() => {
                navigation.navigate("EditProfileScreen", {
                  customerId: subject?.id,
                });
              }}
            >
              <Text style={styles.sectionText}>
                {subject?.firstName} {subject?.lastName}
              </Text>
              <Text style={styles.sectionText}>
                DOB: {formatDateOfBirth(subject?.dateOfBirth)}
              </Text>
            </EditableSection>
          )}

          {!loading && (
            <EditableSection
              label="Your Info"
              onEditPress={() => {
                navigation.navigate("IdVerification");
              }}
            >
              <CustomerRow customer={currentCustomer as Customer} />
            </EditableSection>
          )}
        </ScrollView>
      </Container>
      <StickyFooter>
        <Button
          title="Next"
          isLoading={loading}
          onPress={() => {
            navigation.navigate(
              hasProfileInformation ? "NewServiceForVisit" : "IdVerification",
            );
          }}
          disabled={loading}
        />
      </StickyFooter>
    </SafeAreaView>
  );
};

export default ExistingCustomerScreen;

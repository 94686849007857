import React from "react";
import { StyleSheet, View } from "react-native";
import { colors } from "../../theme";
import { ServiceTypeOffering, StoreEmployee } from "../../types";
import Avatar from "../Avatar";
import Heading from "../Heading";
import Text from "../Text";

const showServiceTypeOffering = (
  serviceTypeOffering: ServiceTypeOffering | null,
): string => {
  switch (serviceTypeOffering) {
    case ServiceTypeOffering.Tattoo:
      return "Tattoo";
    case ServiceTypeOffering.Piercing:
      return "Piercing";
    case ServiceTypeOffering.PermanentMakeup:
      return "PermanentMakeup";
    default:
      return "";
  }
};

const Artist = ({ artist }: { artist: StoreEmployee }) => {
  return (
    <View style={styles.container}>
      <Avatar size="l" imageUrl={artist.person.profileImageUrl} />
      <View style={styles.textContainer}>
        <Heading size="m" style={{ marginBottom: 0 }}>
          {artist.person.name}
        </Heading>
        <Text size="s" variant="bold" style={styles.artistServices}>
          {artist.services?.map(showServiceTypeOffering).join(", ")}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  textContainer: {
    marginLeft: 10,
    marginTop: 5,
  },
  artistServices: {
    color: colors.darkGrey,
    marginTop: -5,
  },
});

export default Artist;

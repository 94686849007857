import React, { FunctionComponent, useRef } from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import { padStart, omit } from "lodash";
import { SafeAreaView } from "react-native-safe-area-context";
import { TextInputMask } from "react-native-masked-text";
import { Formik } from "formik";
import Button from "../../components/Button";
import KeyboardAvoidingView from "../../components/KeyboardAvoidingView";
import Text from "../../components/Text";
import { colors, fonts } from "../../theme";
import { parse, differenceInYears } from "date-fns";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";
import Header from "../../components/Header";
import {
  DEFAULT_MAX_AGE,
  DEFAULT_MIN_AGE,
  NewVisitFlowScreens,
} from "../../constants";
import { useMutation } from "@apollo/client";
import { SET_DOB } from "./gql";
import { InputError } from "../../types";
import { formatDateOfBirth } from "../../utils/customer";
import { useFirebaseAuth } from "../../firebase/AuthProvider";

type Input = {
  dateOfBirth: string;
};
interface Props {
  route: any;
  navigation: any;
}

const DateOfBirthInputScreen: FunctionComponent<Props> = ({
  route,
  navigation,
}) => {
  const inputRef = useRef<TextInputMask>();
  const [setDateOfBirth] = useMutation(SET_DOB);

  const { params } = route;
  const nextScreen = params?.nextScreen || NewVisitFlowScreens.InitialScreen;
  const { currentCustomer, refetch } = useFirebaseAuth();

  const navigateToNextScreen = (input: Input) => {
    return navigation.navigate(nextScreen, {
      ...omit(route.params, ["nextScreen"]),
      dob: input.dateOfBirth,
    });
  };

  const initialDob =
    currentCustomer?.dateOfBirth &&
    formatDateOfBirth(currentCustomer.dateOfBirth);

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <KeyboardAvoidingView style={styles.formContainer}>
        <Formik
          initialValues={{ dateOfBirth: initialDob || "" }}
          enableReinitialize={true}
          onSubmit={async (input, { setFieldError }) => {
            // Handle the case where this screen needs to update the current customer
            const [dateOfBirth, _] = new Date(input.dateOfBirth)
              .toISOString()
              .split("T");
            const result = await setDateOfBirth({
              variables: { input: { dateOfBirth } },
            });
            await refetch();
            const errors: [InputError] = result.data?.result?.errors || [];

            if (errors.length > 0) {
              errors.forEach(({ field, message }) =>
                setFieldError(field, message),
              );
              return;
            }

            // Always handle the next screen navigation
            navigateToNextScreen(input);
          }}
          validate={(values) => {
            const errors: { dateOfBirth?: string } = {};
            const dateOfBirth = values.dateOfBirth;
            const parts = dateOfBirth.split("/");

            const partsChars = parts.join("");

            const date = parse(dateOfBirth, "MM/dd/yyyy", new Date());
            const yearsOld = differenceInYears(new Date(), date);

            const isValid =
              (!isNaN(date.getTime()) && partsChars.length === 8) || initialDob;

            if (!isValid) {
              errors.dateOfBirth = "Please enter date in format MM/DD/YYYY";
              return errors;
            }

            if (yearsOld > DEFAULT_MAX_AGE) {
              errors.dateOfBirth = "Please provide your actual date of birth";
            }

            if (yearsOld < DEFAULT_MIN_AGE) {
              errors.dateOfBirth = `You must be older than ${DEFAULT_MIN_AGE}yrs to have a procedure`;
            }

            return errors;
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            isValid,
            isSubmitting,
          }) => {
            return (
              <>
                <Container>
                  <ScrollView style={styles.scrollView}>
                    <Header description="To have a service performed we need to know your date of birth. This will be verified against your ID." />
                    <View style={styles.formInner}>
                      <Text style={styles.formHeader}>Date of Birth</Text>
                      <TextInputMask
                        type="datetime"
                        options={{ format: "MM/DD/YYYY" }}
                        value={values.dateOfBirth}
                        keyboardType="numbers-and-punctuation"
                        autoFocus
                        placeholder="MM/DD/YYYY"
                        onBlur={handleBlur("dateOfBirth")}
                        onChangeText={(val) => {
                          const parts = val.split("/");

                          // Handle padding the zero for > 2 && < 10
                          if (
                            parts.length === 1 &&
                            parts[0] !== "" &&
                            parts[0] !== "0" &&
                            parts[0] !== "1"
                          ) {
                            parts[0] = padStart(parts[0], 2, "0");
                          }

                          // Handle padding > 3 && < 10
                          if (
                            parts.length === 2 &&
                            parts[1] !== "" &&
                            parseInt(parts[1], 10) > 3
                          ) {
                            parts[1] = padStart(parts[1], 2, "0");
                          }

                          handleChange("dateOfBirth")(parts.join("/"));
                        }}
                        ref={(ref: TextInputMask) => (inputRef.current = ref)}
                        style={styles.input}
                      />
                      {errors &&
                        Object.keys(errors).map((field) => (
                          <Text
                            key={`error-${field}`}
                            style={{ color: colors.red }}
                          >
                            {(errors as any)[field]}
                          </Text>
                        ))}
                    </View>
                  </ScrollView>
                </Container>

                <StickyFooter>
                  <Button
                    title="Next"
                    onPress={handleSubmit}
                    isLoading={isSubmitting}
                    disabled={!isValid}
                  />
                </StickyFooter>
              </>
            );
          }}
        </Formik>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  input: {
    borderWidth: 1,
    backgroundColor: colors.white,
    paddingHorizontal: 10,
    paddingVertical: 12,
    borderRadius: 5,
    fontSize: 16,
    color: colors.black,
    fontFamily: fonts.regular,
    marginBottom: 10,
  },
  formContainer: {
    flex: 1,
  },
  scrollView: {
    paddingHorizontal: 20,
    flex: 1,
  },
  formInner: {
    marginVertical: 20,
  },
  formHeader: {
    fontFamily: fonts.medium,
    color: colors.white,
    textTransform: "uppercase",
    marginBottom: 5,
  },
});

export default DateOfBirthInputScreen;

import React, { FunctionComponent } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import { colors } from "../../theme";
import Header from "../../components/Header";
import Text from "../../components/Text";
import Button from "../../components/Button";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  formContainer: {
    flex: 1,
    marginTop: 20,
  },
  scrollView: {
    paddingHorizontal: 20,
    flex: 1,
  },
  text: {
    color: colors.white,
    marginTop: 20,
  },
});

interface NoMinorsProps {
  navigation: any;
}

const NoMinors: FunctionComponent<NoMinorsProps> = ({ navigation }) => (
  <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
    <Container>
      <ScrollView style={styles.scrollView}>
        <Header title="Sorry, you're not allowed to do that" />
        <Text size="l" style={styles.text}>
          We're sorry but minors aren't allowed to schedule visits. Please speak
          to your parent or guardian
        </Text>
      </ScrollView>
    </Container>
    <StickyFooter>
      <Button
        title="Go back"
        onPress={() => {
          navigation.navigate("Home");
        }}
      />
    </StickyFooter>
  </SafeAreaView>
);

export default NoMinors;

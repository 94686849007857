import { MedicalConcerns } from "../state/consentFlow";

interface MedicalHistoryPayload {
  medicalConditions: string[];
  additionalNotes: string | undefined;
}

export function buildMedicalHistoryPayload(
  medicalHistory: string[],
  medicalConcerns: MedicalConcerns,
): MedicalHistoryPayload {
  return {
    ...medicalConcerns.answers,
    medicalConditions: medicalHistory,
    additionalNotes: medicalConcerns.additionalNotes,
  };
}

import React from "react";
import { Text, StyleSheet } from "react-native";
import { fonts, colors } from "../../theme";

type size = "label" | "smallcaps" | "s" | "m" | "l" | "xl";

const styles = StyleSheet.create({
  xl: {
    fontSize: 26,
    lineHeight: 30,
  },
  l: {
    fontSize: 21,
    lineHeight: 24,
  },
  m: {
    fontSize: 18,
    lineHeight: 21,
  },
  s: {
    fontSize: 15,
    lineHeight: 18,
  },
  smallcaps: {
    fontFamily: fonts.medium,
    fontSize: 15,
    lineHeight: 20,
    textTransform: "uppercase",
  },
  label: {
    fontFamily: fonts.medium,
    fontSize: 12,
    lineHeight: 20,
    textTransform: "uppercase",
  },
  _base: {
    fontFamily: fonts.semiBold,
    color: colors.black,
  },
});

type Props = {
  size: size;
  style?: object;
};

const Heading: React.FC<Props> = ({ children, size, style }) => (
  <Text style={[styles._base, styles[size], style]}>{children}</Text>
);

export default Heading;

import React from "react";
import { StyleSheet } from "react-native";
import { useDispatch } from "react-redux";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMutation } from "@apollo/client";
import { useNavigation, useRoute } from "@react-navigation/native";

import KeyboardAvoidingView from "../../components/KeyboardAvoidingView";
import { colors } from "../../theme";
import { InputError } from "../../types";
import { ADD_MINOR } from "./gql";
import Form from "./Form";
import { setSubject } from "../../state/consentFlow";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  formContainer: {
    flex: 1,
  },
});

const MinorInformationInputScreen = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { dob: dateOfBirth } = route.params as any;
  const [addMinor] = useMutation(ADD_MINOR);
  const dispatch = useDispatch();
  const [formattedDate] = new Date(dateOfBirth).toISOString().split("T");

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <KeyboardAvoidingView style={styles.formContainer}>
        <Form
          onSubmit={async (input, { setFieldError }) => {
            const result = await addMinor({
              variables: {
                input: { ...input, dateOfBirth: formattedDate },
              },
            });

            const errors: [InputError] = result.data?.result?.errors || [];

            if (errors.length > 0) {
              errors.forEach(({ field, message }) =>
                setFieldError(field, message),
              );
              return;
            }

            const minorId = result.data.result.customer.id;

            dispatch(setSubject({ id: minorId, role: "minor" }));

            navigation.navigate("MinorAdded", {
              ...route.params,
              minorId,
            });
          }}
        />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default MinorInformationInputScreen;

import { gql } from "@apollo/client";

export const SET_DOB = gql`
  mutation SetDateOfBirth($input: SetDateOfBirthInput!) {
    result: setDateOfBirth(input: $input) {
      customer {
        id
        dateOfBirth
      }
      errors {
        field
        message
      }
    }
  }
`;

import React, { FunctionComponent, useState } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  ActivityIndicator,
  Image,
  TouchableHighlight,
  TouchableOpacity,
} from "react-native";
import { get } from "lodash";
import { SafeAreaView } from "react-native-safe-area-context";

import Button from "../../components/Button";
import Text from "../../components/Text";
import { colors, fonts } from "../../theme";
import Header from "../../components/Header";
import { useQuery } from "@apollo/client";
import { MINORS } from "./gql";
import Heading from "../../components/Heading";
import { Customer } from "../../types";
import { formatAgeInYears, hasCompletedProfile } from "../../utils/customer";
import { setSubject } from "../../state/consentFlow";
import { useDispatch } from "react-redux";
import NoMinors from "./NoMinors";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";
import { useFirebaseAuth } from "../../firebase/AuthProvider";

interface SelectableCellProps {
  name: string | null | undefined;
  age: string | null | undefined;
  isSelected: boolean;
  onSelect: () => void;
}

const SelectableCell: FunctionComponent<SelectableCellProps> = ({
  name,
  age,
  isSelected,
  onSelect,
}) => (
  <TouchableOpacity
    onPress={onSelect}
    style={[
      styles.selectableContainer,
      isSelected && styles.selectableContainer__Selected,
    ]}
  >
    <View style={{ flex: 1 }}>
      <Heading size="m">{name}</Heading>
      <Text>{age}</Text>
    </View>

    {isSelected && (
      <Image
        source={require("../../../assets/icons/check.png")}
        style={styles.selectableTick}
      />
    )}
  </TouchableOpacity>
);

const AddNewMinor = "ADD_NEW_MINOR";

interface Props {
  route: any;
  navigation: any;
}

const VisitSubjectScreen: FunctionComponent<Props> = ({
  route,
  navigation,
}) => {
  const dispatch = useDispatch();
  const { currentCustomer } = useFirebaseAuth();
  const [selected, setSelected] = useState<string | null>(null);

  const { data, loading, refetch } = useQuery(MINORS);
  const minors = get(data, "viewer.minors", []);

  const addMinorSelected = selected === AddNewMinor;
  const { nextScreen } = route.params;

  // Minors cannot book procedures
  if (currentCustomer?.isMinor) {
    return <NoMinors navigation={navigation} />;
  }

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <Container>
        <ScrollView style={styles.scrollView}>
          <Header title="Who's getting the procedure?" />
          <View style={styles.formInner}>
            {loading && (
              <ActivityIndicator size="small" color={colors.yellow} />
            )}

            <SelectableCell
              name="Myself"
              age={
                formatAgeInYears(currentCustomer?.dateOfBirth as string) ||
                "Unknown"
              }
              onSelect={() => {
                if (selected === currentCustomer?.id) {
                  setSelected(null);
                } else {
                  setSelected(currentCustomer?.id as string);
                }
              }}
              isSelected={selected === currentCustomer?.id}
            />

            {minors.map((minor: Customer) => {
              const isSelected = selected === minor.id;

              return (
                <SelectableCell
                  key={minor.id}
                  name={minor.name}
                  age={formatAgeInYears(minor.dateOfBirth)}
                  onSelect={() => {
                    if (isSelected) {
                      setSelected(null);
                    } else {
                      setSelected(minor.id);
                    }
                  }}
                  isSelected={isSelected}
                />
              );
            })}

            <TouchableHighlight
              style={[
                styles.addMinorContainer,
                addMinorSelected && styles.addMinorContainer__Selected,
              ]}
              onPress={() => setSelected(addMinorSelected ? null : AddNewMinor)}
            >
              <>
                <Image
                  source={
                    addMinorSelected
                      ? require("../../../assets/icons/add-unselected.png")
                      : require("../../../assets/icons/add-selected.png")
                  }
                  style={{ width: 24, height: 24 }}
                />
                <Heading
                  size="smallcaps"
                  style={[
                    styles.addMinorText,
                    addMinorSelected && styles.addMinorText__Selected,
                  ]}
                >
                  A new minor
                </Heading>

                {addMinorSelected && (
                  <Image
                    source={require("../../../assets/icons/check.png")}
                    style={styles.selectableTick}
                  />
                )}
              </>
            </TouchableHighlight>
          </View>
        </ScrollView>
      </Container>
      <StickyFooter>
        <Button
          title="Next"
          onPress={() => {
            if (selected !== AddNewMinor) {
              dispatch(
                setSubject({
                  id: selected,
                  role: selected === currentCustomer?.id ? "self" : "minor",
                }),
              );

              // TODO: Figure out why this nextscreen property is set incorrectly
              const correctNextScreen =
                nextScreen === "VisitSubject" ? "ExistingVisit" : nextScreen;
              const nextCustomerScreen = hasCompletedProfile(
                currentCustomer as Customer,
              )
                ? "ExistingCustomerReview"
                : "IdVerification";

              navigation.navigate(correctNextScreen || nextCustomerScreen);
            } else {
              navigation.navigate("MinorDateOfBirth");
            }
          }}
          disabled={!selected}
        />
      </StickyFooter>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  input: {
    borderWidth: 1,
    backgroundColor: colors.white,
    paddingHorizontal: 10,
    paddingVertical: 12,
    borderRadius: 5,
    fontSize: 16,
    color: colors.black,
    fontFamily: fonts.regular,
    marginBottom: 10,
  },
  formContainer: {
    flex: 1,
    marginTop: 20,
  },
  scrollView: {
    paddingHorizontal: 20,
    flex: 1,
  },
  formInner: {
    marginVertical: 15,
  },
  addMinorContainer: {
    borderWidth: 2,
    borderColor: colors.yellow,
    borderRadius: 20,
    padding: 25,
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 20,
  },
  addMinorContainer__Selected: {
    backgroundColor: colors.yellow,
  },
  addMinorText: {
    color: colors.yellow,
    fontSize: 16,
    letterSpacing: 1,
    lineHeight: 25,
    marginLeft: 20,
  },
  addMinorText__Selected: {
    color: colors.black,
  },
  selectableContainer: {
    borderRadius: 20,
    backgroundColor: colors.white,
    paddingHorizontal: 20,
    paddingVertical: 20,
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  selectableContainer__Selected: {
    backgroundColor: colors.yellow,
  },
  selectableTick: {
    marginLeft: "auto",
    width: 24,
    height: 24,
  },
});

export default VisitSubjectScreen;

import { gql } from "@apollo/client";
import { Customer } from "../../fragments";

export const ADD_MINOR = gql`
  mutation AddMinor($input: AddMinorInput!) {
    result: addMinor(input: $input) {
      errors {
        message
        field
      }
      customer {
        ...Customer
      }
    }
  }
  ${Customer}
`;

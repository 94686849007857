import Constants from "expo-constants";

const ENV = {
  dev: {
    apiUrl: "http://localhost:4000",
    wsUrl: "ws://localhost:4000",
    segmentWriteKey: null,
    googlePlacesApiKey: "AIzaSyCAGy5m_WNw6OCnAVJtzeGYWRL1zeoj_CI",
    firebaseApiKey:
      Constants.manifest.extra?.firebaseApiKey ||
      "AIzaSyCd-t5McLCUA0UIni-OYHwajAwmr0uQGhM",
    firebaseAuthDomain:
      Constants.manifest.extra?.firebaseAuthDomain ||
      "bodyart-322618.firebaseapp.com",
    firebaseProjectId:
      Constants.manifest.extra?.firebaseProjectId || "bodyart-322618",
    firebaseStorageBucket:
      Constants.manifest.extra?.firebaseStorageBucket ||
      "bodyart-322618.appspot.com",
    firebaseMessagingSenderId:
      Constants.manifest.extra?.firebaseMessagingSenderId ||
      "1:870037772362:web:11ed2cfcd7875b2c386eb3",
    firebaseAppId: Constants.manifest.extra?.firebaseAppId || "G-5HXSC41Q16",
  },
  prod: {
    apiUrl: Constants.manifest.extra?.apiUrl || "",
    wsUrl: Constants.manifest.extra?.wsUrl || "",
    firebaseApiKey: Constants.manifest.extra?.firebaseApiKey || "",
    firebaseAuthDomain: Constants.manifest.extra?.firebaseAuthDomain || "",
    firebaseProjectId: Constants.manifest.extra?.firebaseProjectId || "",
    firebaseStorageBucket:
      Constants.manifest.extra?.firebaseStorageBucket || "",
    firebaseMessagingSenderId:
      Constants.manifest.extra?.firebaseMessagingSenderId || "",
    firebaseAppId: Constants.manifest.extra?.firebaseAppId || "",
    segmentWriteKey: "mJBCfIzZ5twIvoiahnPhXa6Ekza14kZt",
    googlePlacesApiKey: "AIzaSyCAGy5m_WNw6OCnAVJtzeGYWRL1zeoj_CI",
  },
};

export const DEV_STORE_ID =
  "U3RvcmU6N2ZhODViZGQtOTNkNi00ZDk1LWI1NTEtOGVlYjYyYzVmYmMx";

export default function getEnv(env = Constants.manifest.releaseChannel) {
  if (__DEV__) {
    return ENV.dev;
  } else {
    return ENV.prod;
  }
}

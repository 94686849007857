import React, { FunctionComponent, useState } from "react";
import { StyleSheet, ScrollView, ActivityIndicator } from "react-native";
import { useQuery } from "@apollo/client";
import { get } from "lodash";
import { SafeAreaView } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import Button from "../../components/Button";
import Header from "../../components/Header";
import ProcedureList from "../../components/ProcedureList";
import useCustomer from "../../hooks/useCustomer";
import { RootState } from "../../state";
import { colors } from "../../theme";
import { ServiceType } from "../../types";
import { ageInYears } from "../../utils/customer";
import { QUERY_PROCEDURES } from "./gql";
import { filterServiceTypes } from "../../utils/service";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  scrollView: {
    paddingHorizontal: 20,
  },
});

interface Props {
  route: any;
  navigation: any;
}

const NewServiceForVisitScreen: FunctionComponent<Props> = ({
  route,
  navigation,
}) => {
  const { store } = route.params;
  const [
    selectedServiceType,
    setSelectedServiceType,
  ] = useState<ServiceType | null>(null);

  const { id: subjectId } = useSelector(
    (state: RootState) => state.consentFlow.subject,
  );

  // The subject for the visit may or may not be the current customer
  // e.g they may have selected a minor
  const subject = useCustomer(subjectId as string);
  const subjectAge = ageInYears(subject?.dateOfBirth);

  const { data, loading } = useQuery(QUERY_PROCEDURES, {
    variables: { id: store.id, minimumAge: subjectAge },
  });

  const serviceTypes = get(data, "store.serviceTypes", []);
  const filteredServiceTypes = filterServiceTypes(serviceTypes);

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <Container>
        <ScrollView style={styles.scrollView}>
          <Header
            title="The Procedure"
            description="Please select what you’re looking to get done (you can select an
            additional procedure at the end)."
          />
          {loading && <ActivityIndicator color={colors.white} size="small" />}
          {!loading && (
            <ProcedureList
              procedures={filteredServiceTypes}
              onSelect={(category) => setSelectedServiceType(category)}
              selectedProcedure={selectedServiceType}
            />
          )}
        </ScrollView>
      </Container>
      <StickyFooter>
        <Button
          title="Next"
          onPress={() => {
            if (!selectedServiceType) {
              return;
            }

            const categories = selectedServiceType.categories || [];

            if (categories.length > 1) {
              navigation.navigate("ServiceCategorySelectionForVisit", {
                ...route.params,
                serviceType: selectedServiceType,
              });
            } else {
              navigation.navigate("ServiceSelectionForVisit", {
                ...route.params,
                serviceType: selectedServiceType,
                serviceTypeCategory: categories[0],
              });
            }
          }}
          disabled={!selectedServiceType}
        />
      </StickyFooter>
    </SafeAreaView>
  );
};

export default NewServiceForVisitScreen;

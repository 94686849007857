import React from "react";

export default function useUppy(factory: () => any) {
  const uppy = React.useMemo(factory, []);

  React.useEffect(() => {
    return () => uppy.close();
  }, []);

  return uppy;
}

import { gql } from "@apollo/client";
import { Store } from "../types";
import { Session, Customer, fullVisit, storeInformation } from "../fragments";

export const LIST_STORES = gql`
  query ListStores {
    stores(first: 50) {
      edges {
        node {
          ...storeInformation
        }
      }
    }
  }
  ${storeInformation}
`;

export const GET_STORE_QUERY = gql`
  query Store($id: ID!) {
    store: node(id: $id) {
      ... on Store {
        ...storeInformation
      }
    }
  }
  ${storeInformation}
`;

export interface GetStoreData {
  node: Store;
}

export interface GetStoreVars {
  id: string;
}

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    exchangeRefreshToken(refreshToken: $refreshToken) {
      ...Session
    }
  }
  ${Session}
`;

export const CURRENT_CUSTOMER = gql`
  query Viewer {
    viewer {
      ...Customer
    }
  }
  ${Customer}
`;

// TODO: This needs pagination
export const VISITS = gql`
  query VisitsForListing($filters: VisitFilters!) {
    visits(filterBy: $filters, first: 200) {
      edges {
        node {
          ...fullVisit
        }
      }
    }
  }
  ${fullVisit}
`;

import { gql } from "@apollo/client";
import { Customer, medicalHistory } from "../../fragments";

export const FETCH_CUSTOMER = gql`
  query FetchFullCustomer($customerId: ID!) {
    customer: node(id: $customerId) {
      ... on Customer {
        ...Customer
        medicalHistory {
          ...medicalHistory
        }
      }
    }
  }
  ${Customer}
  ${medicalHistory}
`;

import React, { FunctionComponent } from "react";
import { StyleSheet, View, Image } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import Button, { Appearance } from "../../components/Button";
import { colors, fonts } from "../../theme";
import Heading from "../../components/Heading";
import Text from "../../components/Text";
import Container from "../../components/Container";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    paddingHorizontal: 20,
    flex: 1,
  },
  innerContainer: {
    flex: 1,
  },
  logoHeader: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
  },
  storeInfo: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: "auto",
    marginBottom: "auto",
  },
  poweredBy: {
    fontSize: 12,
    lineHeight: 20,
    color: colors.darkGrey,
    textTransform: "uppercase",
    letterSpacing: 1,
    fontFamily: fonts.medium,
    marginBottom: 5,
  },
  footer: {
    marginTop: "auto",
    paddingBottom: 15,
  },
});

interface VisitNotAllowedScreenProps {
  onBackPress: () => void;
}

const VisitNotAllowedScreen: FunctionComponent<VisitNotAllowedScreenProps> = ({
  onBackPress,
}) => {
  return (
    <SafeAreaView style={styles.container}>
      <Container>
        <View style={styles.logoHeader}>
          <Text style={styles.poweredBy}>Powered By</Text>
          <Image
            source={require("../../../assets/logo/logo-yellow.png")}
            style={{ width: 150, height: 40 }}
          />
        </View>
        <View style={styles.storeInfo}>
          <Heading
            size="xl"
            style={{
              color: colors.white,
              marginVertical: 20,
              textAlign: "center",
            }}
          >
            No visits allowed right now
          </Heading>
          <Text size="l" style={{ color: colors.midGrey, textAlign: "center" }}>
            We're sorry but at the current time this store is not available for
            a new procedure. Please speak to the staff at the store.
          </Text>
        </View>
        <View style={styles.footer}>
          <Button
            title="Continue"
            onPress={onBackPress}
            appearance={Appearance.primary}
            style={{ marginTop: "auto" }}
          />
        </View>
      </Container>
    </SafeAreaView>
  );
};

export default VisitNotAllowedScreen;

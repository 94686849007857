import React, { FunctionComponent } from "react";
import {
  Service,
  ServiceType,
  ServiceTypeCategory,
  ServiceTypeOffering,
} from "../../types";
import SelectFromManyServices from "./SelectFromManyServices";
import SingleServiceEntry from "../ServiceDetailsEntryForVisitScreen/SingleServiceEntry";

interface ServiceSelectionForVisitScreenProps {
  route: any;
  navigation: any;
}

const ServiceSelectionForVisitScreen: FunctionComponent<ServiceSelectionForVisitScreenProps> = ({
  route,
  navigation,
}) => {
  const {
    serviceType,
    serviceTypeCategory,
  }: {
    serviceType: ServiceType;
    serviceTypeCategory: ServiceTypeCategory;
  } = route.params;

  const services = serviceTypeCategory.services as Service[];

  if (serviceType?.type !== ServiceTypeOffering.Tattoo) {
    return (
      <SelectFromManyServices
        services={services}
        serviceType={serviceType}
        navigation={navigation}
      />
    );
  } else {
    return (
      <SingleServiceEntry
        service={services[0]}
        serviceType={serviceType}
        navigation={navigation}
      />
    );
  }
};

export default ServiceSelectionForVisitScreen;

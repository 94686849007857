import { gql } from "@apollo/client";

export const GENERATE_UPLOAD = gql`
  mutation GenerateImageUpload {
    result: generateUpload {
      authKey
      signature
      expires
      templateId
    }
  }
`;

import React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { Formik } from "formik";
import * as Yup from "yup";

import TextInputField from "../../components/TextInputField";
import Button from "../../components/Button";
import { colors, fonts } from "../../theme";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";

const initialValues = {
  firstName: "",
  lastName: "",
};

const Schema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
});

const styles = StyleSheet.create({
  scrollView: {
    padding: 20,
    flex: 1,
  },
  formInner: {
    marginBottom: 40,
  },
  formHeader: {
    fontFamily: fonts.medium,
    color: colors.white,
    textTransform: "uppercase",
    marginBottom: 5,
  },
  dualInput: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flexInput: {
    flex: 1,
  },
});

type Props = {
  onSubmit: (values: {}, actions: any) => Promise<unknown>;
};

const Form = ({ onSubmit }: Props) => (
  <Formik
    initialValues={initialValues}
    validationSchema={Schema}
    onSubmit={onSubmit}
  >
    {({
      handleChange,
      handleBlur,
      handleSubmit,
      values,
      errors,
      touched,
      isSubmitting,
      isValid,
    }) => {
      return (
        <>
          <Container>
            <ScrollView style={styles.scrollView}>
              <View style={styles.formInner}>
                <Text style={styles.formHeader}>Name</Text>
                <TextInputField
                  name="firstName"
                  placeholder="First name"
                  hasError={!!errors?.firstName}
                  onChangeText={(text) => handleChange("firstName")(text)}
                  onEndEditing={(e) => {
                    handleChange("firstName")(e.nativeEvent.text.trim());
                    handleBlur("firstName");
                  }}
                  value={values.firstName}
                  touched={!!touched.firstName}
                  error={errors.firstName}
                />
                <TextInputField
                  name="lastName"
                  placeholder="Last name"
                  hasError={!!errors?.lastName}
                  onChangeText={(text) => handleChange("lastName")(text)}
                  onEndEditing={(e) => {
                    handleChange("lastName")(e.nativeEvent.text.trim());
                    handleBlur("lastName");
                  }}
                  value={values.lastName}
                  touched={!!touched.lastName}
                  error={errors.lastName}
                />
              </View>
            </ScrollView>
          </Container>

          <StickyFooter>
            <Button
              title="Next"
              onPress={handleSubmit}
              disabled={!isValid}
              isLoading={isSubmitting}
            />
          </StickyFooter>
        </>
      );
    }}
  </Formik>
);

export default Form;

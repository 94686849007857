import { gql } from "@apollo/client";
import { Customer } from "../../fragments";

export const SET_ID_IMAGES = gql`
  mutation SetIdImages($input: SetIdImagesInput!) {
    result: setIdImages(input: $input) {
      errors {
        message
        field
      }
      customer {
        ...Customer
      }
    }
  }
  ${Customer}
`;

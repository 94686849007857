import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  TextInputProps,
} from "react-native";
import { colors, fonts } from "../../theme";

export type TextInputFieldProps = {
  name: string;
  label?: string;
  hasError?: boolean;
  error?: string;
  touched?: boolean;
  style?: Object;
} & TextInputProps;

const TextInputField: React.FC<TextInputFieldProps> = ({
  label,
  style,
  error,
  ...props
}) => {
  const hasError = !!error;
  return (
    <View style={[styles.container, style]}>
      {label && <Text style={styles.label}>{label}</Text>}
      <TextInput
        style={[styles.textField, hasError && styles.textField__hasError]}
        {...props}
      />
      <Text style={styles.errorMessage}>{error || " "}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  textField: {
    borderWidth: 1,
    backgroundColor: colors.white,
    paddingHorizontal: 10,
    paddingVertical: 12,
    borderRadius: 5,
    fontSize: 16,
    lineHeight: 20,
    color: colors.black,
    fontFamily: fonts.regular,
  },
  textField__hasError: {
    borderColor: colors.red,
  },
  errorMessage: {
    color: colors.red,
    marginTop: 5,
  },
  label: {
    fontFamily: fonts.medium,
    color: colors.white,
    textTransform: "uppercase",
    marginBottom: 5,
  },
});

export default TextInputField;

import React, { FunctionComponent } from "react";
import { Platform, StyleSheet, ScrollView } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import Button from "../../components/Button";
import Header from "../../components/Header";
import Text from "../../components/Text";

import { RootState } from "../../state";
import { partialService } from "../../fragments";
import { colors } from "../../theme";
import { Service } from "../../types";
import EditableSection from "../../components/EditableSection";
import { formatDateOfBirth } from "../../utils/customer";
import useCustomer from "../../hooks/useCustomer";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";
import { useApolloClient } from "@apollo/client";
import { useFirebaseAuth } from "../../firebase/AuthProvider";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  scrollView: {
    paddingHorizontal: 20,
  },
  sectionText__Text: {
    color: colors.midGrey,
  },
  sectionText__TextError: {
    color: colors.statusRed,
  },
  conditionText__Linkwrap: {},
});

const ReviewVisitScreen: FunctionComponent<{ route: any; navigation: any }> = ({
  route,
  navigation,
}) => {
  const consentState = useSelector((state: RootState) => state.consentFlow);
  const client = useApolloClient();
  const { currentCustomer } = useFirebaseAuth();
  const subject = useCustomer(
    consentState.subject.id || (currentCustomer?.id as string),
  );

  const serviceIds = Object.keys(consentState.services);

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <Container>
        <ScrollView style={styles.scrollView}>
          <Header description="Check to make sure everything looks right." />

          {consentState.subject.role !== "self" && (
            <EditableSection
              label="Minor Info"
              onEditPress={() => {
                navigation.navigate("EditProfileScreen", {
                  customerId: subject?.id,
                });
              }}
            >
              <Text style={styles.sectionText__Text}>
                {subject?.firstName} {subject?.lastName}
              </Text>
              <Text style={styles.sectionText__Text}>
                DOB: {formatDateOfBirth(subject?.dateOfBirth)}
              </Text>
            </EditableSection>
          )}

          <EditableSection
            label="Your Info"
            onEditPress={() => {
              navigation.navigate("IdVerification", {
                nextScreenForSection: "ReviewVisit",
              });
            }}
          >
            <Text style={styles.sectionText__Text}>
              {currentCustomer?.firstName} {currentCustomer?.lastName}
            </Text>
            <Text style={styles.sectionText__Text}>
              DOB: {formatDateOfBirth(currentCustomer?.dateOfBirth)}
            </Text>

            {(currentCustomer?.idImageUrls || []).length > 0 && (
              <Text style={styles.sectionText__Text}>ID Uploaded</Text>
            )}
          </EditableSection>
          <EditableSection
            label="Procedure Details"
            onEditPress={() => {
              navigation.navigate("ReviewServicesForVisit", {
                nextScreenForSection: "ReviewVisit",
              });
            }}
          >
            {serviceIds.map((key, idx) => {
              const serviceDetails = consentState.services[key];
              const service: Service | null = client.readFragment({
                fragment: partialService,
                fragmentName: "partialService",
                id: `Service:${serviceDetails.serviceId}`,
              });

              return service ? (
                <Text key={`service-${idx}`} style={styles.sectionText__Text}>
                  {service.serviceType.name} - {service.name}
                </Text>
              ) : null;
            })}

            {serviceIds.length === 0 && (
              <Text
                style={[
                  styles.sectionText__Text,
                  styles.sectionText__TextError,
                ]}
              >
                No procedures selected
              </Text>
            )}
          </EditableSection>
          <EditableSection
            label="Health Info"
            onEditPress={() => {
              navigation.navigate("MedicalHistoryForVisit");
            }}
          >
            {consentState.medicalHistory.length > 0 &&
              consentState.medicalHistory.map((item, idx) => (
                <Text key={idx} style={styles.sectionText__Text}>
                  {item}
                </Text>
              ))}
            {consentState.medicalHistory.length === 0 && (
              <Text style={styles.sectionText__Text}>
                No medical history of note
              </Text>
            )}
          </EditableSection>
        </ScrollView>
      </Container>
      <StickyFooter>
        <Button
          title={"Next: Sign & Submit"}
          onPress={() => {
            const nextScreen = Platform.select({
              native: "SignatureCapture",
              default: "SignatureCaptureWeb",
            });
            navigation.navigate(nextScreen, {
              ...route.params,
            });
          }}
          disabled={serviceIds.length === 0}
        />
      </StickyFooter>
    </SafeAreaView>
  );
};

export default ReviewVisitScreen;

import { gql } from "@apollo/client";
import {
  serviceForCategoryListing,
  serviceType,
  serviceTypeCategory,
} from "../../fragments";

export const QUERY_PROCEDURES = gql`
  query Store($id: ID!, $minimumAge: Int) {
    store: node(id: $id) {
      ... on Store {
        id
        serviceTypes {
          ...serviceType
          categories {
            ...serviceTypeCategory
            services(filterBy: { minimumAge: $minimumAge }) {
              ...serviceForCategoryListing
            }
          }
        }
      }
    }
  }
  ${serviceType}
  ${serviceTypeCategory}
  ${serviceForCategoryListing}
`;

import React, { FunctionComponent } from "react";
import { Service, ServiceType } from "../../types";
import SingleServiceEntry from "./SingleServiceEntry";

interface Props {
  route: any;
  navigation: any;
}

const ServiceDetailsEntryForVisitScreen: FunctionComponent<Props> = ({
  route,
  navigation,
}) => {
  const {
    serviceType,
    service,
  }: {
    serviceType: ServiceType;
    service: Service;
  } = route.params;

  return (
    <SingleServiceEntry
      service={service}
      serviceType={serviceType as ServiceType}
      navigation={navigation}
    />
  );
};

export default ServiceDetailsEntryForVisitScreen;

import React, { FunctionComponent } from "react";
import { StyleSheet, View, Image } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import Button, { Appearance } from "../../components/Button";
import { colors, fonts } from "../../theme";
import Avatar from "../../components/Avatar";
import Heading from "../../components/Heading";
import Text from "../../components/Text";
import { summarizeAddress } from "../../utils/address";
import { NewVisitFlowScreens } from "../../constants";
import Container from "../../components/Container";
import { Customer } from "../../types";
import { useFirebaseAuth } from "../../firebase/AuthProvider";
import {
  GoogleLogin,
  FacebookLogin,
  AppleLogin,
} from "../../components/SocialButton";
import { getAuth } from "firebase/auth";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    paddingHorizontal: 20,
    flex: 1,
  },
  innerContainer: {
    flex: 1,
  },
  logoHeader: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
  },
  storeInfo: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: "auto",
    marginBottom: "auto",
  },
  poweredBy: {
    fontSize: 12,
    lineHeight: 20,
    color: colors.darkGrey,
    textTransform: "uppercase",
    letterSpacing: 1,
    fontFamily: fonts.medium,
    marginBottom: 5,
  },
  footer: {
    marginTop: "auto",
    paddingBottom: 15,
  },
});

function nextScreenForCustomer(navigation: any, customer: Customer) {
  return navigation.navigate("DateOfBirthInput", {
    updateProfile: true,
    nextScreen: NewVisitFlowScreens.InitialScreen,
  });
}

interface Props {
  route: any;
  navigation: any;
}

const NewVisitScreen: FunctionComponent<Props> = ({ route, navigation }) => {
  const { store, guestOnly } = route.params;
  const { currentCustomer } = useFirebaseAuth();
  const auth = getAuth();

  return (
    <SafeAreaView style={styles.container}>
      <Container>
        <View style={styles.logoHeader}>
          <Text style={styles.poweredBy}>Powered By</Text>
          <Image
            source={require("../../../assets/logo/logo-yellow.png")}
            style={{ width: 150, height: 40 }}
          />
        </View>
        <View style={styles.storeInfo}>
          <Avatar imageUrl={store.profileImageUrl} size="xl" hasBorder />
          <Heading
            size="xl"
            style={{ color: colors.white, marginTop: 20, textAlign: "center" }}
          >
            {store.name}
          </Heading>
          <Text size="l" style={{ color: colors.midGrey, textAlign: "center" }}>
            {summarizeAddress(store.address)}
          </Text>
        </View>
        <View style={styles.footer}>
          {guestOnly && (
            <Button
              title="Continue"
              onPress={() => navigation.navigate("DateOfBirthInput")}
              appearance={Appearance.primary}
              style={{ marginTop: "auto" }}
            />
          )}

          {!guestOnly && currentCustomer && (
            <>
              <Button
                title="Continue"
                onPress={() =>
                  nextScreenForCustomer(navigation, currentCustomer)
                }
                appearance={Appearance.primary}
                style={{ marginTop: "auto" }}
              />
              <Button
                title="Log out"
                onPress={async () => {
                  await auth.signOut();
                  navigation.navigate("NewVisitScreen");
                }}
                appearance={Appearance.primary}
                style={{ marginTop: 10 }}
              />
            </>
          )}

          {!currentCustomer && !guestOnly && (
            <>
              <GoogleLogin
                onSuccess={() =>
                  navigation.navigate("DateOfBirthInput", {
                    nextScreen: NewVisitFlowScreens.InitialScreen,
                  })
                }
              />
              <AppleLogin
                onSuccess={() =>
                  navigation.navigate("DateOfBirthInput", {
                    nextScreen: NewVisitFlowScreens.InitialScreen,
                  })
                }
              />
              <FacebookLogin
                onSuccess={() =>
                  navigation.navigate("DateOfBirthInput", {
                    nextScreen: NewVisitFlowScreens.InitialScreen,
                  })
                }
              />
              <Button
                title="Log in with Email"
                onPress={() =>
                  navigation.navigate("LoginViaEmail", {
                    nextScreen: "DateOfBirthInput",
                  })
                }
                appearance={Appearance.ghost}
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <Button
                title="Sign up with Email"
                onPress={() =>
                  navigation.navigate("RegisterViaEmail", {
                    nextScreen: "DateOfBirthInput",
                  })
                }
              />
            </>
          )}
        </View>
      </Container>
    </SafeAreaView>
  );
};

export default NewVisitScreen;

import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Customer } from "../types";
import { FETCH_CUSTOMER } from "../screens/ExistingCustomerScreen/gql";

export default function useCustomer(customerId: string) {
  const [customer, setCustomer] = useState<Customer | null>(null);
  const client = useApolloClient();

  useEffect(() => {
    if (customerId) {
      const subscription = client
        .watchQuery({
          query: FETCH_CUSTOMER,
          variables: {
            customerId: customerId,
          },
        })
        .subscribe({ next: (next) => setCustomer(next?.data?.customer) });

      return () => subscription.unsubscribe();
    } else {
      setCustomer(null);
    }
  }, [customerId]);

  return customer;
}

import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, Image } from "react-native";
import FadeIn from "react-native-fade-in-image";
import { colors, fonts } from "../../theme";
import { avatarName } from "../../utils/avatar";

type Props = {
  name: string;
  iconUrl: string | null;
  onSelect: () => void;
  isSelected: boolean;
  windowWidth: number;
};

const OUTER_GUTTER = 45;
const CELL_GUTTER = 10;

const Cell: React.FC<Props> = ({
  name,
  iconUrl,
  onSelect,
  isSelected,
  windowWidth,
}) => {
  const cols = windowWidth > 450 ? 3 : 2;
  const totalGutter = OUTER_GUTTER + CELL_GUTTER * cols;
  const locationCellWidth = (windowWidth - totalGutter) / cols;
  const imageSize = locationCellWidth - totalGutter;

  return (
    <TouchableOpacity
      onPress={onSelect}
      style={[
        styles.location,
        { width: locationCellWidth, height: locationCellWidth },
        isSelected && styles.location__selected,
      ]}
    >
      <View style={styles.locationInner}>
        {iconUrl && (
          <View
            style={[
              styles.locationImageContainer,
              { width: imageSize, height: imageSize },
            ]}
          >
            <FadeIn placeholderStyle={styles.locationImagePlaceholder}>
              <Image
                source={{ uri: iconUrl }}
                resizeMode="contain"
                style={[
                  styles.locationImage,
                  { width: imageSize, height: imageSize },
                ]}
              />
            </FadeIn>
          </View>
        )}

        {!iconUrl && (
          <View
            style={[
              styles.blankContainer,
              { width: imageSize - 20, height: imageSize - 20 },
            ]}
          >
            <Text style={styles.blankContainerText}>{avatarName(name)}</Text>
          </View>
        )}

        <Text style={styles.locationName}>{name}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  location: {
    borderWidth: 1,
    borderColor: colors.darkestGrey,
    borderRadius: 20,
    backgroundColor: colors.white,
    marginBottom: CELL_GUTTER,
    marginHorizontal: CELL_GUTTER / 2,
  },
  location__selected: {
    borderColor: colors.yellow,
    backgroundColor: colors.yellow,
  },
  locationInner: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 15,
    position: "relative",
  },
  locationName: {
    fontFamily: fonts.semiBold,
    textTransform: "uppercase",
    letterSpacing: 1,
    fontSize: 15,
    lineHeight: 21,
    color: colors.black,
    marginTop: "auto",
    marginBottom: 10,
    textAlign: "center",
  },
  locationImageContainer: {
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 25,
  },
  locationImagePlaceholder: { backgroundColor: "transparent" },
  locationImage: {},
  blankContainer: {
    borderRadius: 100,
    backgroundColor: colors.midGrey,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 30,
  },
  blankContainerText: {
    fontSize: 24,
    fontFamily: fonts.medium,
    letterSpacing: 2,
    color: colors.black,
  },
});

export default Cell;

import React from "react";
import {
  getAuth,
  OAuthProvider,
  signInWithPopup,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import SocialButton from "./SocialButton";
import { SocialLoginType } from "../../types";

const provider = new OAuthProvider("apple.com");

interface Props {
  onSuccess: () => Promise<void>;
}

const FacebookLogin = ({ onSuccess }: Props) => {
  const auth = getAuth();

  return (
    <SocialButton
      title="Log In with Apple"
      social={SocialLoginType.Apple}
      onPress={async () => {
        try {
          await signInWithPopup(auth, provider);
          await onSuccess();
        } catch (err) {
          if (err.code === "auth/account-exists-with-different-credential") {
            const data = err.customData;
            console.log(data);
            const email = data.email;
            const methods = await fetchSignInMethodsForEmail(auth, email);
            console.log(methods);
          }
        }
      }}
    />
  );
};

export default FacebookLogin;

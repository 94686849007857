import React from "react";
import { View, StyleSheet } from "react-native";
import { colors } from "../../theme";

type Props = {};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderTopColor: colors.darkestGrey,
    borderTopWidth: 1,
  },
});

const StickyFooter: React.FC<Props> = ({ children }) => (
  <View style={styles.container}>{children}</View>
);

export default StickyFooter;

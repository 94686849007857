import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, Image } from "react-native";
import { colors, fonts } from "../../theme";
import { SocialLoginType } from "../../types";
import { spacing } from "../../styles/sizing";

export enum Appearance {
  primary = "primary",
  ghost = "ghost",
  unstyled = "unstyled",
}

const SocialIcon = {
  [SocialLoginType.Facebook]: require(`../../../assets/icons/facebook.png`),
  [SocialLoginType.Google]: require(`../../../assets/icons/google.png`),
  [SocialLoginType.Apple]: require(`../../../assets/icons/apple.png`),
};

interface Props {
  isLoading?: boolean;
  disabled?: boolean;
  title: string;
  onPress: any;
  social: SocialLoginType;
}

const SocialButton: React.FC<Props> = ({
  title,
  disabled = false,
  onPress = () => {},
  social,
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      accessibilityRole="button"
      style={[styles.base, disabled && styles.disabled]}
    >
      <View style={[styles.iconContainer]}>
        <Image
          source={SocialIcon[social]}
          style={styles.icon}
          resizeMode="contain"
        />
      </View>
      <Text style={[styles.text]}>{title}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  base: {
    borderWidth: 2,
    borderColor: "transparent",
    borderRadius: 60,
    padding: 10,
    height: 45,
    width: "100%",
    maxWidth: 450,
    alignSelf: "center",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "row",
    backgroundColor: colors.white,
    marginTop: spacing.x10,
  },
  iconContainer: {
    position: "absolute",
    top: 9,
    left: 12,
  },
  icon: {
    width: 24,
    height: 24,
    backgroundSize: "contain",
  },
  disabled: {
    backgroundColor: colors.darkestGrey,
  },
  text: {
    fontFamily: fonts.semiBold,
    textTransform: "uppercase",
    color: colors.black,
    fontSize: 15,
    letterSpacing: 1,
    textAlign: "center",
  },
});

export default SocialButton;

import { gql } from "@apollo/client";
import { Customer } from "../../fragments";

export const MINORS = gql`
  query MinorsForCustomer {
    viewer {
      id
      minors {
        ...Customer
      }
    }
  }
  ${Customer}
`;

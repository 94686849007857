import React from "react";
import { Text as RNText, StyleSheet, TextProps } from "react-native";
import { colors, fonts } from "../../theme";

const styles = StyleSheet.create({
  base: {
    color: colors.black,
    fontFamily: fonts.regular,
  },
  size__s: {
    fontSize: 12,
    lineHeight: 15,
  },
  size__m: {
    fontSize: 15,
    lineHeight: 18,
  },
  size__l: {
    fontSize: 18,
    lineHeight: 24,
  },
  variant__regular: {
    fontFamily: fonts.regular,
  },
  variant__bold: {
    fontFamily: fonts.medium,
  },
});

type Props = {
  variant?: "regular" | "bold";
  size?: "s" | "m" | "l";
} & TextProps;

const Text: React.FC<Props> = ({
  children,
  size = "m",
  variant = "regular",
  style,
  ...props
}) => (
  <RNText
    {...props}
    style={[
      styles.base,
      (styles as any)[`size__${size}`],
      (styles as any)[`variant__${variant}`],
      style,
    ]}
  >
    {children}
  </RNText>
);

export default Text;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createStackNavigator } from "@react-navigation/stack";
import * as SplashScreen from "expo-splash-screen";
import * as Segment from "./analytics";
import { useQuery } from "@apollo/client";

import { NavigatorParamList } from "./navigators/types";
import { CURRENT_CUSTOMER } from "./screens/queries";
import { commonHeader } from "./navigators/AuthNavigator";
import { ScreenHeaderTitle } from "./components/ScreenHeader";
import GuestVisitFlow from "./navigators/GuestVisitFlow";
import NewVisitFlow from "./navigators/NewVisitFlow";
import WelcomeGuestScreen from "./screens/WelcomeGuestScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import VisitScreen from "./screens/VisitScreen";

import { RootState } from "./state";
import { colors } from "./theme";

const MainStack = createStackNavigator<NavigatorParamList>();

const NavigationWeb = () => {
  const [didLoad, setDidLoad] = useState(false);
  const token = useSelector((store: RootState) => store.auth.token);
  const { data, loading } = useQuery(CURRENT_CUSTOMER, {
    skip: !token,
    onCompleted: ({ viewer }) => {
      if (viewer) {
        Segment.identifyWithTraits(viewer.id, {
          name: viewer.name,
          email: viewer.emailAddress,
        });
      }
    },
  });

  useEffect(() => {
    // If we already loaded, don't perform this step again
    if (didLoad) return;

    if ((!loading && data) || (!loading && !data)) {
      setTimeout(() => {
        setDidLoad(true);
        SplashScreen.hideAsync();
      }, 400);
    }
  }, [data, loading]);

  // If we already loaded the app once, we don't want to show this empty state
  // again to avoid some really janky re-renders of screens
  if (loading && !didLoad) {
    return null;
  }

  return (
    <MainStack.Navigator
      screenOptions={{
        headerTitleAlign: "center",
        cardStyle: { backgroundColor: colors.black },
      }}
    >
      <MainStack.Screen
        name="Main"
        component={WelcomeGuestScreen}
        options={{ headerShown: false, title: "Welcome" }}
      />
      <MainStack.Screen
        name="NewVisitFlow"
        component={(props) => <NewVisitFlow {...props} isUnscheduled={false} />}
        options={{ headerShown: false, title: "New Visit" }}
      />
      <MainStack.Screen
        name="NewVisitUnscheduledFlow"
        component={(props) => <NewVisitFlow {...props} isUnscheduled={true} />}
        options={{ headerShown: false, title: "New Visit" }}
      />
      <MainStack.Screen
        name="Visit"
        component={VisitScreen}
        options={
          {
            ...commonHeader,
            title: "Visit Details",
            headerTitle: (props: any) => <ScreenHeaderTitle {...props} />,
          } as any
        }
      />
      <MainStack.Screen
        name="ResetPassword"
        component={ResetPasswordScreen}
        options={
          {
            ...commonHeader,
            title: "Reset Password",
            headerTitle: (props: any) => <ScreenHeaderTitle {...props} />,
          } as any
        }
        initialParams={{ isGuestFlow: true }}
      />
    </MainStack.Navigator>
  );
};

export default NavigationWeb;

import React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { Formik } from "formik";
import { includes } from "lodash";
import {
  GooglePlaceDetail,
  GooglePlacesAutocomplete,
} from "react-native-google-places-autocomplete";
import * as Yup from "yup";
import TextInputField from "../../components/TextInputField";
import Button from "../../components/Button";
import { colors, fonts } from "../../theme";
import { Customer, UpdateProfileInformationInput } from "../../types";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";
import getEnv from "../../environment";

const { apiUrl, googlePlacesApiKey } = getEnv();

const Schema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  address: Yup.object().shape({
    addressLineOne: Yup.string().required("Address line 1 is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zipCode: Yup.string().required("Zipcode is required"),
  }),
});

const styles = StyleSheet.create({
  scrollView: {
    padding: 20,
    flex: 1,
  },
  formInner: {
    marginBottom: 40,
  },
  formHeader: {
    fontFamily: fonts.medium,
    color: colors.white,
    textTransform: "uppercase",
    marginBottom: 5,
  },
  dualInput: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flexInput: {
    flex: 1,
  },
});

type FormProps = {
  customer: Customer | null;
  onSubmit: (
    values: UpdateProfileInformationInput,
    actions: any,
  ) => Promise<unknown>;
};

// This function will autocomplete the address fields from the google place detail
// object returned in the autocomplete.
function autofillAddressFromDetails(
  { address_components: addressParts }: GooglePlaceDetail,
  onChange: (name: string, value: string) => void,
  validateForm: () => any,
) {
  const number = addressParts.find((a) => includes(a.types, "street_number"));
  const street = addressParts.find((a) => includes(a.types, "route"));
  const city = addressParts.find(
    (a) =>
      includes(a.types, "sublocality_level_1") || includes(a.types, "locality"),
  );
  const state = addressParts.find((a) =>
    includes(a.types, "administrative_area_level_1"),
  );
  const zipCode = addressParts.find((a) => includes(a.types, "postal_code"));

  if (number && street) {
    onChange(
      "address.addressLineOne",
      `${number.short_name} ${street.short_name}`,
    );
  }

  if (city) {
    onChange("address.city", city.short_name);
  }

  if (state) {
    onChange("address.state", state.short_name);
  }

  if (zipCode) {
    onChange("address.zipCode", zipCode.short_name);
  }

  // This needs to occur on next tick, otherwise the form will show as invalid
  setTimeout(() => validateForm(), 10);
}

interface Values {
  firstName: string;
  lastName: string;
  address: {
    addressLineOne: string;
    addressLineTwo: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  };
}

const Form = ({ customer, onSubmit }: FormProps) => {
  const customerAddress = customer?.address;
  const {
    addressLineOne = "",
    addressLineTwo = "",
    city = "",
    state = "",
    zipCode = "",
    country = "US",
  } = customerAddress || {};

  const initialValues: Values = {
    firstName: customer?.firstName || "",
    lastName: customer?.lastName || "",
    address: {
      addressLineOne,
      addressLineTwo: addressLineTwo || "",
      city,
      state,
      zipCode,
      country,
    },
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        isSubmitting,
        isValid,
        setFieldValue,
        validateForm,
      }) => {
        return (
          <>
            <Container>
              <ScrollView
                keyboardShouldPersistTaps="handled"
                style={styles.scrollView}
              >
                <View style={styles.formInner}>
                  <Text style={styles.formHeader}>Name</Text>
                  <TextInputField
                    name="firstName"
                    placeholder="First name"
                    textContentType="givenName"
                    hasError={!!errors?.firstName}
                    onChangeText={(text) => handleChange("firstName")(text)}
                    onEndEditing={(e) => {
                      handleChange("firstName")(e.nativeEvent.text.trim());
                      handleBlur("firstName");
                    }}
                    value={values.firstName}
                    touched={!!touched.firstName}
                    error={errors.firstName}
                  />
                  <TextInputField
                    name="lastName"
                    placeholder="Last name"
                    textContentType="familyName"
                    hasError={!!errors?.lastName}
                    onChangeText={(text) => handleChange("lastName")(text)}
                    onEndEditing={(e) => {
                      handleChange("lastName")(e.nativeEvent.text.trim());
                      handleBlur("lastName");
                    }}
                    value={values.lastName}
                    touched={!!touched.lastName}
                    error={errors.lastName}
                  />

                  <Text style={styles.formHeader}>Address</Text>

                  <GooglePlacesAutocomplete
                    placeholder="Search for your address"
                    onPress={(_data, details) => {
                      autofillAddressFromDetails(
                        details as GooglePlaceDetail,
                        setFieldValue,
                        validateForm,
                      );
                    }}
                    fetchDetails
                    query={{
                      key: googlePlacesApiKey,
                      language: "en",
                      components: "country:us",
                    }}
                    requestUrl={{
                      useOnPlatform: "web",
                      url: apiUrl,
                    }}
                    styles={{ container: { marginBottom: 5 } }}
                    debounce={300}
                  />

                  <TextInputField
                    name="address.addressLineOne"
                    placeholder="Street Address Line 1"
                    textContentType="streetAddressLine1"
                    hasError={!!errors?.address?.addressLineOne}
                    onChangeText={handleChange("address.addressLineOne")}
                    onBlur={handleBlur("address.addressLineOne")}
                    value={values.address.addressLineOne}
                    touched={!!touched.address?.addressLineOne}
                    error={errors.address?.addressLineOne}
                  />
                  <TextInputField
                    name="address.addressLineTwo"
                    placeholder="Street Address Line 2"
                    textContentType="streetAddressLine2"
                    hasError={!!errors?.address?.addressLineTwo}
                    onChangeText={handleChange("address.addressLineTwo")}
                    onBlur={handleBlur("address.addressLineTwo")}
                    value={values?.address?.addressLineTwo || undefined}
                    touched={!!touched.address?.addressLineTwo}
                    error={errors.address?.addressLineTwo}
                  />
                  <TextInputField
                    name="address.city"
                    placeholder="City"
                    textContentType="addressCity"
                    hasError={!!errors?.address?.city}
                    onChangeText={handleChange("address.city")}
                    onBlur={handleBlur("address.city")}
                    value={values.address.city}
                    touched={!!touched.address?.city}
                    error={errors.address?.city}
                  />
                  <View style={styles.dualInput}>
                    <TextInputField
                      name="address.state"
                      placeholder="State"
                      textContentType="addressState"
                      hasError={!!errors?.address?.state}
                      onChangeText={handleChange("address.state")}
                      onBlur={handleBlur("address.state")}
                      value={values.address.state}
                      style={[styles.flexInput, { marginRight: 5 }]}
                      touched={!!touched.address?.state}
                      error={errors.address?.state}
                    />
                    <TextInputField
                      name="address.zipCode"
                      placeholder="Zip code"
                      textContentType="postalCode"
                      hasError={!!errors?.address?.zipCode}
                      onChangeText={handleChange("address.zipCode")}
                      onBlur={handleBlur("address.zipCode")}
                      value={values.address.zipCode}
                      style={[styles.flexInput, { marginLeft: 5 }]}
                      touched={!!touched.address?.zipCode}
                      error={errors.address?.zipCode}
                    />
                  </View>
                </View>
              </ScrollView>
            </Container>
            <StickyFooter>
              <Button
                title="Next"
                onPress={handleSubmit}
                disabled={!isValid}
                isLoading={isSubmitting}
              />
            </StickyFooter>
          </>
        );
      }}
    </Formik>
  );
};

export default Form;

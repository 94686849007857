import { ServiceTypeOffering } from "../../types";

export const ServiceSelectionTitle = {
  [ServiceTypeOffering.Tattoo]: "About Your Tattoo",
  [ServiceTypeOffering.PermanentMakeup]: "About Your Permanent Makeup",
  [ServiceTypeOffering.Piercing]: "About Your Piercing",
};

export const ServiceSelectionDescription = {
  [ServiceTypeOffering.Tattoo]:
    "Please tell us as much as you can about your desired tattoo.",
  [ServiceTypeOffering.PermanentMakeup]:
    "Please tell us more about you’re looking to get done.",
  [ServiceTypeOffering.Piercing]:
    "Please tell us more about you’re looking to get done.",
};

import * as Sentry from 'sentry-expo';
import * as Segment from "./src/analytics";
import App from "./src/App";
import getEnv from './src/environment';

const { segmentWriteKey } = getEnv();

if (segmentWriteKey) {
  Segment.initialize(segmentWriteKey);
}

Sentry.init({
  dsn: "https://28d896733f4f48c1bdca0bbb854456c8@o498544.ingest.sentry.io/5576201",
  enableInExpoDevelopment: false,
  debug: false,
});

export default App;

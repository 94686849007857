import React from "react";
import { StyleSheet, View, Image } from "react-native";

import { colors } from "../../theme";
import Text from "../../components/Text";
import Avatar from "../Avatar";
import { ServiceTypeOffering, VisitService } from "../../types";

type variant = "s" | "m" | "l";

type RowProps = {
  visitService: VisitService;
  variant: variant;
  showArtist?: boolean;
  isLast?: boolean;
};

function serviceName(visitService: VisitService) {
  if (visitService.service.serviceType.type === ServiceTypeOffering.Tattoo) {
    const location = visitService.submittedInformation?.find(
      (si) => si.name === "location",
    );
    const size = visitService.submittedInformation?.find(
      (si) => si.name === "size",
    );

    if (location || size) {
      const details = [location?.value, size?.value]
        .filter((i) => !i)
        .join(", ");

      return `Tattoo - ${details}`;
    }

    return "Tattoo";
  }

  return `${visitService.service.serviceType.name} - ${visitService.service.name}`;
}

const VisitServiceRow: React.FC<RowProps> = ({
  visitService,
  variant,
  showArtist,
  isLast = false,
}) => {
  const artist = visitService.assignedEmployee;

  return (
    <View style={[styles.procedure, isLast && styles.procedure__last]}>
      {showArtist && (
        <Avatar size={variant} imageUrl={artist?.person?.profileImageUrl} />
      )}
      <View style={{ marginLeft: showArtist ? 10 : 0 }}>
        <Text size={variant} variant="bold">
          {serviceName(visitService)}
        </Text>
        {showArtist && (
          <Text
            size="s"
            style={[
              styles.procedureArtist,
              artist && { color: colors.darkGrey },
            ]}
          >
            {artist?.person?.name || "Unassigned Artist"}
          </Text>
        )}
      </View>
    </View>
  );
};

type SummaryProps = {
  services: VisitService[];
  variant?: variant;
  showArtist?: boolean;
};

const VisitServicesSummary: React.FC<SummaryProps> = ({
  services,
  variant = "m",
  showArtist = true,
}) => {
  const totalServices = services.length;

  return (
    <>
      {services.map((visitService, idx) => (
        <VisitServiceRow
          key={visitService.id}
          visitService={visitService}
          variant={variant}
          showArtist={showArtist}
          isLast={totalServices === idx + 1}
        />
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  procedure: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  procedure__last: {
    marginBottom: 0,
  },
  procedureImage: {
    backgroundColor: colors.lightGrey,
    marginRight: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  procedureImage__s: {
    width: 20,
    height: 20,
    borderRadius: 20,
  },
  procedureImage__m: {
    width: 30,
    height: 30,
    borderRadius: 30,
  },
  procedureImage__l: {
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  procedureImage__Icon: {
    width: 17,
    height: 17,
    marginTop: -2,
  },
  procedureArtist: {
    color: colors.midGrey,
  },
});

export default VisitServicesSummary;

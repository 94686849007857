import { gql } from "@apollo/client";

export const INVITE_MINOR = gql`
  mutation InviteMinor($input: SendMinorInviteInput!) {
    result: sendMinorInvite(input: $input) {
      customer {
        id
        emailAddress
      }
      errors {
        field
        message
      }
    }
  }
`;

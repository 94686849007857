import React from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Image,
  GestureResponderEvent,
} from "react-native";
import Heading from "../Heading";
import { colors } from "../../theme";

type Props = {
  label: string;
  onEditPress: (e: GestureResponderEvent) => void;
  isDisabled?: boolean;
};

const EditableSection: React.FC<Props> = ({
  label,
  children,
  onEditPress,
  isDisabled = false,
}) => {
  return (
    <View style={styles.section}>
      <View style={styles.sectionHeader}>
        <Heading size="m" style={styles.sectionTitle}>
          {label}
        </Heading>

        {!isDisabled && (
          <TouchableOpacity onPress={onEditPress} style={styles.sectionEdit}>
            <Image
              source={require("../../../assets/icons/edit-selected.png")}
              style={{ width: 24, height: 24 }}
            />
          </TouchableOpacity>
        )}
      </View>
      <View style={styles.sectionText}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    borderBottomWidth: 1,
    borderBottomColor: colors.darkestGrey,
    paddingVertical: 20,
  },
  sectionTitle: {
    color: colors.white,
    textTransform: "uppercase",
    letterSpacing: 1,
  },
  sectionHeader: {
    flexDirection: "row",
  },
  sectionEdit: {
    marginLeft: "auto",
  },
  sectionText: {},
});

export default EditableSection;

import React, { FunctionComponent } from "react";
import { StyleSheet, View, Image } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import Button from "../../components/Button";
import { colors, fonts } from "../../theme";
import { DEV_STORE_ID } from "../../environment";
import Heading from "../../components/Heading";
import Text from "../../components/Text";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    paddingHorizontal: 20,
    flex: 1,
  },
  formContainer: {
    flex: 1,
  },
  logoHeader: {
    borderBottomColor: colors.darkestGrey,
    borderBottomWidth: 1,
    alignItems: "center",
    marginTop: 30,
    paddingBottom: 20,
  },
  signupButtons: {
    flex: 1,
    justifyContent: "center",
    marginVertical: 50,
  },
  loginButtons: {
    marginTop: "auto",
    borderTopColor: colors.darkestGrey,
    borderTopWidth: 0.5,
    paddingVertical: 20,
  },
  loginMessage: {
    fontFamily: fonts.regular,
    color: colors.darkGrey,
    textAlign: "center",
    letterSpacing: 1,
    fontSize: 13,
    marginBottom: 10,
  },
  illustrationContainer: {
    alignItems: "center",
    marginBottom: 20,
  },
});

interface Props {
  navigation: any;
}

const WelcomeGuestScreen: FunctionComponent<Props> = ({ navigation }) => {
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.logoHeader}>
        <Image
          source={require("../../../assets/logo/logo-yellow.png")}
          style={{ width: 150, height: 40 }}
        />
      </View>

      <View style={styles.signupButtons}>
        <View style={styles.illustrationContainer}>
          <Image
            source={require("../../../assets/illustrations/woman-in-app.png")}
            style={{ width: 240, height: 240 }}
          />
        </View>

        <View>
          <Heading
            size="xl"
            style={{
              color: colors.white,
              textAlign: "center",
              marginBottom: 15,
            }}
          >
            Welcome to BodyArt
          </Heading>
          <Text style={{ color: colors.midGrey, textAlign: "center" }}>
            Please scan a QR code in store or follow a deep link to get started.
          </Text>
        </View>
      </View>

      {__DEV__ && (
        <Button
          title="Initiate Guest Flow"
          onPress={() =>
            navigation.navigate("NewVisitFlow", { storeId: DEV_STORE_ID })
          }
          disabled={false}
          isLoading={false}
          style={{ marginTop: "auto", marginBottom: 20 }}
        />
      )}
    </SafeAreaView>
  );
};

export default WelcomeGuestScreen;

import { ServiceType, ServiceTypeCategory } from "../types";

export function filterServiceTypes(serviceTypes: ServiceType[]): ServiceType[] {
  // Remove the service type if any empty categories
  return serviceTypes.filter((serviceType) => {
    return (serviceType.categories || []).some((stc) => {
      return (stc.services || []).length > 0;
    });
  });
}

export function filterCategories(
  categories: ServiceTypeCategory[],
): ServiceTypeCategory[] {
  return categories.filter((stc) => (stc.services || []).length > 0);
}

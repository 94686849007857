import { gql } from "@apollo/client";
import { Customer } from "../../fragments";

export const VERIFY_TOKEN = gql`
  query ValidateResetPasswordToken($token: String!) {
    result: validateResetPasswordToken(token: $token) {
      success
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    result: resetPassword(input: $input) {
      errors {
        message
        field
      }
      session {
        token
        refreshToken
        customer {
          ...Customer
        }
      }
    }
  }
  ${Customer}
`;

import React, { FunctionComponent, useEffect, useState } from "react";
import { ScrollView, View, Text, StyleSheet } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import * as Segment from "../../analytics";
import Header from "../../components/Header";
import Button, { Appearance } from "../../components/Button";
import { colors, fonts } from "../../theme";
import ImagePicker from "../../components/ImagePicker";
import { useMutation } from "@apollo/client";
import { SET_ID_IMAGES } from "./gql";
import Container from "../../components/Container";
import { useFirebaseAuth } from "../../firebase/AuthProvider";

interface Props {
  route: any;
  navigation: any;
}

const IdVerificationScreen: FunctionComponent<Props> = ({
  route,
  navigation,
}) => {
  const { currentCustomer: customer } = useFirebaseAuth();
  const [imageUrl, setImageUrl] = useState(
    customer?.idImageUrls ? customer.idImageUrls[0] : null,
  );

  useEffect(() => {
    if (customer?.idImageUrls) {
      setImageUrl(customer.idImageUrls[0]);
    }
  }, [customer, setImageUrl]);

  const [setIdForCustomer, { loading }] = useMutation(SET_ID_IMAGES, {
    onCompleted: ({ result }) => {
      if (!result.errors) {
        navigation.navigate("PersonalInformationInput", {
          ...route.params,
        });
      }
    },
  });

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <Container>
        <ScrollView style={styles.formContainer}>
          <>
            <Header
              title="ID Verification"
              description="Accepted forms of ID include: Driver’s license, Passport, Student ID, etc."
            />
            <View style={{ flex: 1 }}>
              <ImagePicker
                blankMessage="Select or take picture of a drivers license or any valid ID"
                onUpload={(file) => {
                  Segment.track("ID Image Selected");
                  setImageUrl(file.ssl_url);
                }}
                value={imageUrl}
              />
            </View>
          </>
          <View style={styles.footer}>
            <Text style={styles.disclaimer}>
              * If you skip now, you must show your ID to the shop employee upon
              arrival for your appointment.
            </Text>

            <Button
              title="Skip"
              onPress={() =>
                navigation.navigate("PersonalInformationInput", {
                  ...route.params,
                })
              }
              appearance={Appearance.ghost}
              style={{ marginBottom: 15 }}
            />

            <Button
              title="Next"
              onPress={() =>
                setIdForCustomer({
                  variables: { input: { idImageUrls: [imageUrl] } },
                })
              }
              isLoading={loading}
              disabled={!imageUrl}
            />
          </View>
        </ScrollView>
      </Container>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  formContainer: {
    flex: 1,
    paddingHorizontal: 20,
  },
  footer: {
    marginTop: "auto",
    marginBottom: 15,
  },
  imageContainer: {
    backgroundColor: colors.darkestGrey,
    borderRadius: 20,
    overflow: "hidden",
    height: 365,
    marginTop: 20,
    marginBottom: 20,
  },
  disclaimer: {
    fontSize: 13,
    lineHeight: 15,
    fontFamily: fonts.regular,
    fontStyle: "italic",
    textAlign: "center",
    color: colors.darkGrey,
    marginBottom: 20,
  },
});

export default IdVerificationScreen;

import React, { FunctionComponent } from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import Button, { Appearance } from "../../components/Button";
import { colors, fonts } from "../../theme";
import { DEV_STORE_ID } from "../../environment";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    paddingHorizontal: 20,
    flex: 1,
  },
  formContainer: {
    flex: 1,
  },
  logoHeader: {
    borderBottomColor: colors.darkestGrey,
    borderBottomWidth: 1,
    alignItems: "center",
    marginTop: 30,
    paddingBottom: 20,
  },
  signupButtons: {
    flex: 1,
    justifyContent: "center",
    marginVertical: 50,
  },
  loginButtons: {
    marginTop: "auto",
    borderTopColor: colors.darkestGrey,
    borderTopWidth: 0.5,
    paddingVertical: 20,
  },
  loginMessage: {
    fontFamily: fonts.regular,
    color: colors.darkGrey,
    textAlign: "center",
    letterSpacing: 1,
    fontSize: 13,
    marginBottom: 10,
  },
  illustrationContainer: {
    alignItems: "center",
    marginBottom: 20,
  },
});

interface Props {
  navigation: any;
}

const AuthenticationScreen: FunctionComponent<Props> = ({ navigation }) => {
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.logoHeader}>
        <Image
          source={require("../../../assets/logo/logo-yellow.png")}
          style={{ width: 150, height: 40 }}
        />
      </View>

      <View style={styles.signupButtons}>
        <View style={styles.illustrationContainer}>
          <Image
            source={require("../../../assets/illustrations/woman-in-app.png")}
            style={{ width: 240, height: 240 }}
          />
        </View>

        {__DEV__ && (
          <>
            <Button
              title="Simulate QR Scan"
              onPress={() =>
                navigation.navigate("NewVisitFlow", { storeId: DEV_STORE_ID })
              }
              disabled={false}
              isLoading={false}
              style={{ marginTop: 10 }}
            />
            <Button
              title="Invite Flow"
              onPress={() =>
                navigation.navigate("InviteFlow", {
                  // If you're testing this, you'll need to generate a new invite
                  token: "XQLz6KSH1VV36p3ssq0z5TANsETenfh7iidqEYIPPvI",
                })
              }
              disabled={false}
              isLoading={false}
              style={{ marginTop: 10 }}
            />
          </>
        )}
      </View>
      <View style={styles.loginButtons}>
        <Text style={styles.loginMessage}>Already have an account?</Text>

        <Button
          title="Log in"
          onPress={() => navigation.navigate("LoginViaEmail")}
          appearance={Appearance.ghost}
        />

        <Button
          title="Sign up with Email"
          onPress={() => navigation.navigate("RegisterViaEmail")}
          disabled={false}
          isLoading={false}
          style={{ marginTop: 15 }}
        />
      </View>
    </SafeAreaView>
  );
};

export default AuthenticationScreen;

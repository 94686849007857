import React from "react";
import { StyleSheet, View, useWindowDimensions } from "react-native";
import { Service, ServiceType } from "../../types";
import Cell from "../ProcedureLocationList/Cell";

interface Props {
  serviceType: ServiceType;
  services: Service[];
  onSelect: (service: Service) => any;
  selectedService: Service | null;
}

const ServiceList: React.FC<Props> = ({
  services,
  onSelect,
  selectedService,
}) => {
  const { width: windowWidth } = useWindowDimensions();

  return (
    <View style={styles.container}>
      {services.map((service, idx) => {
        const isSelected = selectedService && service.id === selectedService.id;

        const icon = service.icon
          ? isSelected
            ? service.icon.selectedUrl
            : service.icon.unselectedUrl || service.icon.selectedUrl
          : null;

        return (
          <Cell
            key={service.id}
            name={service.name}
            iconUrl={icon}
            isSelected={!!isSelected}
            onSelect={() => onSelect(service)}
            windowWidth={Math.min(windowWidth, 590)}
          />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "flex-start",
  },
});

export default ServiceList;

import React from "react";
import { View, StyleSheet, GestureResponderEvent } from "react-native";
import { colors } from "../../theme";
import Button, { Appearance } from "../Button";
import Heading from "../Heading";
import Text from "../Text";
import Modal from "../Modal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  confirmLabel: string;
  onConfirm: (e: GestureResponderEvent) => void;
  dismissLabel?: string;
  onDismiss?: (e: GestureResponderEvent) => void;
  isLoading?: boolean;
};

const Dialog: React.FC<Props> = ({
  isOpen,
  title,
  description,
  confirmLabel,
  onConfirm,
  dismissLabel,
  onDismiss,
  isLoading,
}) => {
  const hasDismiss = !!(onDismiss && dismissLabel);

  return (
    <Modal isVisible={isOpen} style={{ margin: 0 }}>
      <View style={styles.container}>
        <Heading size="xl" style={styles.heading}>
          {title}
        </Heading>
        <Text size="m" style={styles.description}>
          {description}
        </Text>

        <View style={[styles.footer, hasDismiss && styles.footer__withDismiss]}>
          <Button
            title={confirmLabel}
            onPress={onConfirm}
            isLoading={isLoading}
          />

          {hasDismiss && (
            <Button
              title={dismissLabel as string}
              onPress={onDismiss}
              appearance={Appearance.unstyled}
              style={styles.dismissButton}
            />
          )}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderRadius: 20,
    padding: 20,
    paddingBottom: 0,
    maxWidth: 400,
    marginHorizontal: "auto",
  },
  heading: {
    marginBottom: 10,
  },
  description: {},
  footer: {
    borderTopColor: colors.midGrey,
    borderTopWidth: 1,
    paddingTop: 20,
    marginTop: 20,
    paddingBottom: 20,
  },
  footer__withDismiss: {
    paddingBottom: 10,
  },
  dismissButton: {
    marginTop: 5,
  },
});

export default Dialog;

import React from "react";
import { StyleSheet } from "react-native";
import { colors } from "../../theme";
import Text from "../../components/Text";
import { Customer } from "../../types";
import { formatDateOfBirth } from "../../utils/customer";

type Props = {
  customer: Customer;
};

const CustomerRow: React.FC<Props> = ({ customer }) => {
  return (
    <>
      <Text style={styles.text}>
        {customer?.firstName
          ? `${customer?.firstName} ${customer?.lastName}`
          : "N/A"}
      </Text>
      <Text style={styles.text}>
        DOB: {formatDateOfBirth(customer?.dateOfBirth)}
      </Text>

      {(customer?.idImageUrls || []).length > 0 && (
        <Text style={styles.text}>ID Uploaded</Text>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  text: {
    color: colors.midGrey,
  },
});

export default CustomerRow;

import React from "react";
import RNModal from "modal-enhanced-react-native-web";
import { ModalProps } from "react-native-modal";

const Modal: React.FC<Partial<ModalProps>> = ({ children, ...props }) => (
  <RNModal ariaHideApp={false} {...props}>
    {children}
  </RNModal>
);

export default Modal;

export const colors = {
  black: "#151829",
  offWhite: "#FBF4E5",
  darkestGrey: "#3B3C46",
  white: "#FFFFFF",
  lightGrey: "#F3F3F3",
  midGrey: "#B0B1B7",
  secondaryMidGrey: "#dddddd",
  darkGrey: "#666A74",
  green: "#5FB6A8",
  red: "#FF766C",
  yellow: "#FEB94C",
  statusBlue: "#326AEB",
  statusGreen: "#2D9B42",
  statusRed: "#FC3D28",
};

export const fonts = {
  regular: "Poppins_400Regular",
  medium: "Poppins_500Medium",
  semiBold: "Poppins_600SemiBold",
  bold: "Poppins_700Bold",
};

import React, { FunctionComponent } from "react";
import { ActivityIndicator, Platform, View } from "react-native";
import { useQuery } from "@apollo/client";
import { createStackNavigator } from "@react-navigation/stack";
import { useRoute } from "@react-navigation/native";

import NewVisitScreen from "../screens/NewVisitScreen";
import LoginViaEmailScreen from "../screens/LoginViaEmailScreen";
import RegisterViaEmailScreen from "../screens/RegisterViaEmailScreen";
import PersonalInformationInputScreen from "../screens/PersonalInformationInputScreen";
import PhoneNumberInputScreen from "../screens/PhoneNumberInputScreen";
import NewServiceForVisitScreen from "../screens/NewServiceForVisitScreen";
import ServiceCategorySelectionForVisitScreen from "../screens/ServiceCategorySelectionForVisitScreen";
import ServiceSelectionForVisitScreen from "../screens/ServiceSelectionForVisitScreen";
import ReviewServicesForVisitScreen from "../screens/ReviewServicesForVisitScreen";
import MedicalHistoryForVisitScreen from "../screens/MedicalHistoryForVisitScreen";
import MedicalConcernsForVisitScreen from "../screens/MedicalConcernsForVisitScreen";
import VisitConfirmedScreen from "../screens/VisitConfirmedScreen";
import ReviewVisitScreen from "../screens/ReviewVisitScreen";
import SignatureCapture from "../screens/SignatureCapture/SignatureCaptureWeb";
import IdVerificationScreen from "../screens/IdVerificationScreen";
import ExistingCustomerScreen from "../screens/ExistingCustomerScreen";
import ConsentQuestions from "../screens/ConsentQuestions";
import DateOfBirthInputScreen from "../screens/DateOfBirthInputScreen";
import ServiceDetailsEntryForVisitScreen from "../screens/ServiceDetailsEntryForVisitScreen/ServiceDetailsEntryForVisitScreen";
import VisitSubjectScreen from "../screens/VisitSubjectScreen";
import MinorDateOfBirthInputScreen from "../screens/MinorDateOfBirthInputScreen";
import MinorInformationInputScreen from "../screens/MinorInformationInputScreen";
import MinorAddedScreen from "../screens/MinorAddedScreen";
import ForgotPasswordScreen from "../screens/ForgotPasswordScreen";
import VisitNotAllowedScreen from "../screens/VisitNotAllowedScreen";
import EditProfileScreen from "../screens/EditProfileScreen";

import { GET_STORE_QUERY } from "../screens/queries";
import {
  ScreenHeaderTitleWithProgress,
  ScreenHeaderTitle,
} from "../components/ScreenHeader";
import { colors } from "../theme";
import { useFirebaseAuth } from "../firebase/AuthProvider";

const Stack = createStackNavigator();

const headerHeight =
  Platform.OS === "web" ? 80 : Platform.OS === "ios" ? 110 : 120;

export const commonVisitHeader = {
  headerStyle: {
    height: headerHeight,
    backgroundColor: colors.black,
    shadowColor: "transparent",
    elevation: 0,
    borderBottomWidth: 1,
    borderBottomColor: colors.darkestGrey,
  },
  headerTintColor: "#fff",
  headerBackTitleVisible: false,
};

const totalSteps = 5;

type StepHeaderParams = {
  title: any;
  totalSteps: any;
  position: any;
};

export const stepHeader = ({
  title,
  position,
  ...props
}: StepHeaderParams) => ({
  ...commonVisitHeader,
  ...props,
  title,
  headerTitle: (props: any) => (
    <ScreenHeaderTitleWithProgress
      {...props}
      steps={totalSteps}
      position={position}
    />
  ),
});

interface NewVisitFlowProps {
  navigation: any;
  isUnscheduled: boolean;
}

const NewVisitFlow: FunctionComponent<NewVisitFlowProps> = ({
  navigation,
  isUnscheduled,
}) => {
  const { params } = useRoute();
  const { currentCustomer } = useFirebaseAuth();
  const { loading, data, error } = useQuery(GET_STORE_QUERY, {
    variables: { id: params?.storeId },
  });

  if (loading) {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: colors.black,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ActivityIndicator size="large" color={colors.yellow} />
      </View>
    );
  }

  if (!data || error) {
    return <VisitNotAllowedScreen onBackPress={() => navigation.goBack()} />;
  }

  const store = data.store;

  return (
    <Stack.Navigator screenOptions={{ headerTitleAlign: "center" }}>
      <Stack.Screen
        name="NewVisit"
        component={NewVisitScreen}
        options={{ headerShown: false }}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="DateOfBirthInput"
        component={DateOfBirthInputScreen}
        options={stepHeader({
          title: "Basic Info",
          totalSteps,
          position: 1,
        })}
        initialParams={{ store }}
      />

      {!currentCustomer && (
        <>
          <Stack.Screen
            name="LoginViaEmail"
            component={LoginViaEmailScreen}
            options={{
              ...commonVisitHeader,
              title: "Login",
              headerTitle: (props) => <ScreenHeaderTitle {...props} />,
            }}
            initialParams={{ store }}
          />
          <Stack.Screen
            name="ForgotPassword"
            component={ForgotPasswordScreen}
            options={{
              ...commonVisitHeader,
              title: "Forgot Password",
              headerTitle: (props) => <ScreenHeaderTitle {...props} />,
            }}
            initialParams={{ store }}
          />
          <Stack.Screen
            name="RegisterViaEmail"
            component={RegisterViaEmailScreen}
            options={stepHeader({
              title: "Basic Info",
              totalSteps,
              position: 1,
            })}
            initialParams={{ store }}
          />
        </>
      )}
      <Stack.Screen
        name="VisitSubject"
        component={VisitSubjectScreen}
        options={{
          ...commonVisitHeader,
          title: "Get Started",
          headerTitle: (props) => <ScreenHeaderTitle {...props} />,
        }}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="ExistingCustomerReview"
        component={ExistingCustomerScreen}
        options={stepHeader({
          title: "Your Info",
          totalSteps,
          position: 1,
        })}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="EditProfileScreen"
        component={EditProfileScreen}
        options={{
          ...commonVisitHeader,
          title: "Edit",
          headerTitle: (props) => <ScreenHeaderTitle {...props} />,
        }}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="MinorDateOfBirth"
        component={MinorDateOfBirthInputScreen}
        options={stepHeader({
          title: "Minor's Basic Info",
          totalSteps,
          position: 1,
        })}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="MinorDetails"
        component={MinorInformationInputScreen}
        options={stepHeader({
          title: "Minor's Basic Info",
          totalSteps,
          position: 1,
        })}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="MinorAdded"
        component={MinorAddedScreen}
        options={stepHeader({
          title: "Minor's Basic Info",
          totalSteps,
          position: 1,
        })}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="IdVerification"
        component={IdVerificationScreen}
        options={stepHeader({
          title: "Your Info",
          totalSteps,
          position: 1,
        })}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="PersonalInformationInput"
        component={PersonalInformationInputScreen}
        options={stepHeader({
          title: "Your Info",
          totalSteps,
          position: 1,
        })}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="PhoneNumberInput"
        component={PhoneNumberInputScreen}
        options={stepHeader({
          title: "Your Info",
          totalSteps,
          position: 1,
        })}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="NewServiceForVisit"
        component={NewServiceForVisitScreen}
        options={stepHeader({
          title: "Visit Details",
          totalSteps,
          position: 2,
        })}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="ServiceCategorySelectionForVisit"
        component={ServiceCategorySelectionForVisitScreen}
        options={stepHeader({
          title: "Visit Details",
          totalSteps,
          position: 2,
        })}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="ServiceSelectionForVisit"
        component={ServiceSelectionForVisitScreen}
        options={stepHeader({
          title: "Visit Details",
          totalSteps,
          position: 2,
        })}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="ServiceDetailsEntryForVisit"
        component={ServiceDetailsEntryForVisitScreen}
        options={stepHeader({
          title: "Visit Details",
          totalSteps,
          position: 2,
        })}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="ReviewServicesForVisit"
        component={ReviewServicesForVisitScreen}
        options={stepHeader({
          title: "Visit Details",
          totalSteps,
          position: 2,
        })}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="MedicalHistoryForVisit"
        component={MedicalHistoryForVisitScreen}
        options={stepHeader({
          title: "Health Info",
          totalSteps,
          position: 3,
        })}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="MedicalConcernsForVisit"
        component={MedicalConcernsForVisitScreen}
        options={stepHeader({
          title: "Health Info",
          totalSteps,
          position: 3,
        })}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="ConsentQuestions"
        component={ConsentQuestions}
        options={stepHeader({
          title: "Consent",
          totalSteps,
          position: 4,
        })}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="ReviewVisit"
        component={ReviewVisitScreen}
        options={stepHeader({
          title: "Review",
          totalSteps,
          position: 5,
        })}
        initialParams={{ store }}
      />
      <Stack.Screen
        name="SignatureCaptureWeb"
        options={stepHeader({
          title: "Signature",
          totalSteps,
          position: totalSteps + 1,
        })}
        initialParams={{ store }}
      >
        {(props) => (
          <SignatureCapture {...props} isUnscheduled={isUnscheduled} />
        )}
      </Stack.Screen>
      <Stack.Screen
        name="VisitConfirmed"
        component={VisitConfirmedScreen}
        options={{ headerShown: false }}
        initialParams={{ store, shouldFillConsent: true }}
      />
    </Stack.Navigator>
  );
};

export default NewVisitFlow;

import React, { FunctionComponent, useEffect } from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import { useApolloClient } from "@apollo/client";
import { SafeAreaView } from "react-native-safe-area-context";

import Button from "../../components/Button";
import { colors } from "../../theme";
import { fullVisit } from "../../fragments";
import { Visit } from "../../types";
import Heading from "../../components/Heading";
import Text from "../../components/Text";
import Container from "../../components/Container";
import VisitSummaryCard from "../VisitConfirmedScreen/VisitSummaryCard";
import StickyFooter from "../../components/StickyFooter";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  scrollContainer: {
    padding: 20,
  },
  header: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderBottomColor: colors.darkestGrey,
    borderBottomWidth: 1,
    alignItems: "center",
  },
  header__Title: {
    color: colors.white,
    textAlign: "center",
    marginHorizontal: 15,
    marginBottom: 10,
  },
  header__Body: {
    color: colors.midGrey,
    textAlign: "center",
  },
  footerNote: {
    marginBottom: 15,
    color: colors.darkGrey,
    textAlign: "center",
  },
});

interface VisitConfirmedScreenProps {
  route: any;
  navigation: any;
}

const VisitConfirmedScreen: FunctionComponent<VisitConfirmedScreenProps> = ({
  route,
  navigation,
}) => {
  const { visitId, shouldFillConsent } = route.params;
  const client = useApolloClient();

  const visit: Visit | null = client.readFragment({
    fragment: fullVisit,
    fragmentName: "fullVisit",
    id: `Visit:${visitId}`,
  });

  useEffect(() => {
    navigation.addListener("beforeRemove", (e: any) => {
      e.preventDefault();
    });
  }, [navigation]);

  if (!visit) {
    // This should never happen :-)
    return null;
  }

  return (
    <SafeAreaView
      style={styles.container}
      edges={["right", "bottom", "top", "left"]}
    >
      <Container>
        <ScrollView style={styles.scrollContainer}>
          <View style={styles.header}>
            <Heading size="xl" style={styles.header__Title}>
              Your information has been submitted!
            </Heading>
            {shouldFillConsent ? (
              <Text style={styles.header__Body}>
                We’re excited to see you. Remember to bring a form of ID with
                you to your appointment.
              </Text>
            ) : (
              <Text style={styles.header__Body}>
                We'll need you to come back and fill out some additional
                questions on the day of your visit. We'll be in touch soon.
              </Text>
            )}
          </View>
          <VisitSummaryCard visit={visit} />
        </ScrollView>
      </Container>
      <StickyFooter>
        <Text style={styles.footerNote}>
          You will receive email updates about your visit.
        </Text>

        <Button
          title="Done"
          onPress={() => {
            navigation.navigate("Home", {
              visitConfirmed: true,
            });
          }}
        />
      </StickyFooter>
    </SafeAreaView>
  );
};

export default VisitConfirmedScreen;

import React from "react";
import { StyleSheet, View } from "react-native";
import Hyperlink from "react-native-hyperlink";
import { colors } from "../../theme";
import Heading from "../Heading";
import Text from "../Text";

interface Props {
  title?: string;
  description?: string;
  renderRightControl?: () => JSX.Element;
}

const styles = StyleSheet.create({
  container: {
    borderBottomColor: colors.darkestGrey,
    borderBottomWidth: 1,
    paddingVertical: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  rightContainer: {
    marginLeft: "auto",
  },
  title: {
    color: colors.white,
  },
  description: {
    fontStyle: "italic",
    color: colors.midGrey,
  },
  linkStyle: {
    color: colors.yellow,
    textDecorationColor: colors.yellow,
    textDecorationLine: "underline",
  },
});

const Header: React.FC<Props> = ({
  title,
  description,
  renderRightControl,
}) => (
  <View style={styles.container}>
    <View style={{ flex: 1 }}>
      {title && (
        <Heading size="xl" style={styles.title}>
          {title}
        </Heading>
      )}
      {description && (
        <Hyperlink linkDefault={true} linkStyle={styles.linkStyle}>
          <Text style={styles.description}>{description}</Text>
        </Hyperlink>
      )}
    </View>
    {renderRightControl && (
      <View style={styles.rightContainer}>{renderRightControl()}</View>
    )}
  </View>
);

export default Header;

import React from "react";
import { StyleSheet, View, Text, Platform } from "react-native";
import { colors, fonts } from "../../theme";

const styles = StyleSheet.create({
  container: {},
  title: {
    fontFamily: fonts.regular,
    textTransform: "uppercase",
    fontSize: 16,
    lineHeight: 18,
    color: colors.white,
    letterSpacing: 1,
    textAlign: "center",
    marginBottom: 8,
  },
  steps: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
    marginHorizontal: "auto",
    position: "relative",
    marginBottom: Platform.OS === "web" ? 0 : 10,
  },
  step: {
    width: 45,
    height: 5,
    marginHorizontal: 3,
    backgroundColor: colors.darkestGrey,
    borderRadius: 5,
  },
  step__complete: {
    backgroundColor: colors.yellow,
  },
  allComplete: {
    ...StyleSheet.absoluteFillObject,
    height: 5,
    backgroundColor: colors.yellow,
    borderRadius: 5,
  },
});

type Props = {
  steps: number;
  position: number;
};

const ScreenHeaderTitleWithProgress: React.FC<Props> = ({
  children,
  steps,
  position,
}) => {
  const isComplete = position > steps;

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{children}</Text>

      <View style={styles.steps}>
        {new Array(steps).fill(null).map((_, n) => {
          return (
            <View
              key={`step-${n}`}
              style={[styles.step, position >= n + 1 && styles.step__complete]}
            />
          );
        })}
        {isComplete && <View style={styles.allComplete} />}
      </View>
    </View>
  );
};

export default ScreenHeaderTitleWithProgress;

import React from "react";
import { TextInput, View, StyleSheet, TextInputProps } from "react-native";
import { colors, fonts } from "../../theme";

const styles = StyleSheet.create({
  input: {
    backgroundColor: colors.white,
    fontFamily: fonts.regular,
    fontSize: 15,
    lineHeight: 18,
    height: 90,
    alignItems: "flex-start",
  },
  inputWrapper: {
    backgroundColor: colors.white,
    borderRadius: 5,
    padding: 15,
  },
});

type Props = {
  name?: string;
  style?: Object;
} & TextInputProps;

const Textarea: React.FC<Props> = (props) => {
  return (
    <View style={styles.inputWrapper}>
      <TextInput
        numberOfLines={3}
        multiline={true}
        style={styles.input}
        textAlignVertical="top"
        {...props}
      />
    </View>
  );
};

export default Textarea;

import React from "react";
import {
  StyleSheet,
  Image,
  View,
  ScrollView,
  TouchableHighlight,
} from "react-native";
import * as Linking from "expo-linking";
import Heading from "../../components/Heading";
import Text from "../../components/Text";
import StoreInformation from "../../components/StoreInformation";
import { colors, fonts } from "../../theme";
import { VisitStatusColors } from "../../constants";
import { Visit, VisitService, VisitStatus } from "../../types";
import Artist from "../../components/Artist";
import Container from "../../components/Container";

type InformationCellProps = {
  label: string;
  headingStyle?: object;
};

const InformationCell: React.FC<InformationCellProps> = ({
  label,
  children,
  headingStyle = {},
}) => (
  <View style={styles.informationCell}>
    <Heading size="label" style={[styles.cellLabel, headingStyle]}>
      {label}
    </Heading>

    {children}
  </View>
);

const PastVisit = ({ visit }: { visit: Visit; navigation: any }) => {
  const visitServices = visit.services as VisitService[];
  const totalServices = visitServices.length;

  return (
    <ScrollView style={styles.scrollView}>
      {visit.status === VisitStatus.Denied && (
        <>
          <View
            style={[
              styles.statusBanner,
              {
                backgroundColor: VisitStatusColors[visit.status as VisitStatus],
              },
            ]}
          >
            <Heading size="label" style={styles.statusBanner__Text}>
              Visit Denied
            </Heading>
          </View>

          {visit.denyReason && (
            <View style={styles.denialReason}>
              <InformationCell
                label="Reason for denial"
                headingStyle={{ color: colors.statusRed }}
              >
                <Text size="m">{visit.denyReason}</Text>
              </InformationCell>
            </View>
          )}
        </>
      )}

      <Container>
        <View style={styles.container}>
          <View style={styles.section}>
            <InformationCell label="The Shop">
              <StoreInformation store={visit.store} />
            </InformationCell>
          </View>

          {visitServices.map((visitService: VisitService, idx: number) => (
            <View
              style={[
                styles.section,
                idx === totalServices - 1 && styles.section__last,
              ]}
              key={visitService.id}
            >
              <Heading size="smallcaps" style={styles.sectionHeader}>
                Service {idx + 1} of {totalServices}
              </Heading>

              {visitService.assignedEmployee && (
                <InformationCell label="The Artist">
                  <Artist artist={visitService.assignedEmployee} />
                </InformationCell>
              )}

              <InformationCell label="Procedure Type">
                <Text size="l" variant="bold">
                  {visitService.service.serviceType.name}
                </Text>
              </InformationCell>

              <InformationCell label="Procedure Name">
                <Text size="l" variant="bold">
                  {visitService.service.name}
                </Text>
              </InformationCell>

              {visitService.submittedInformation &&
                visitService.submittedInformation.length > 0 && (
                  <InformationCell
                    label={`${visitService.service.serviceType.name} Information`}
                  >
                    <Text size="l" variant="bold">
                      {visitService.submittedInformation
                        ?.map((si) => si.value)
                        .join(", ")}
                    </Text>
                  </InformationCell>
                )}

              {visitService.inventoryNotes && (
                <InformationCell label="Jewelry Type">
                  <Text size="l" variant="bold">
                    {visitService.inventoryNotes}
                  </Text>
                </InformationCell>
              )}

              {(visitService.inventorySelections || []).length > 0 && (
                <InformationCell label="Jewelry Type">
                  {visitService.inventorySelections?.map((selection) => (
                    <Text size="l" variant="bold" key={selection?.id}>
                      {selection?.inventoryItem.name}
                    </Text>
                  ))}
                </InformationCell>
              )}

              {(visitService.aftercareInstructions ||
                visitService.externalAftercareInstructionsUrl) && (
                <InformationCell label="Aftercare">
                  {visitService.externalAftercareInstructionsUrl && (
                    <TouchableHighlight
                      style={styles.aftercareLinkContainer}
                      onPress={() => {
                        Linking.openURL(
                          visitService.externalAftercareInstructionsUrl as string,
                        );
                      }}
                    >
                      <View style={styles.aftercareLink}>
                        <Image
                          source={require("../../../assets/icons/document.png")}
                          style={{ width: 24, height: 24 }}
                        />
                        <Heading
                          size="smallcaps"
                          style={styles.aftercareLink__Text}
                        >
                          Aftercare Instructions
                        </Heading>
                      </View>
                    </TouchableHighlight>
                  )}

                  {visitService.aftercareInstructions && (
                    <Text size="m">{visitService.aftercareInstructions}</Text>
                  )}
                </InformationCell>
              )}

              {visitService.service.healingTime && (
                <InformationCell label="Healing Time">
                  <Text size="l" variant="bold">
                    {visitService.service.healingTime}
                  </Text>
                </InformationCell>
              )}

              {visitService.service.downsizeTime && (
                <InformationCell label="Downsize Time">
                  <Text size="l" variant="bold">
                    {visitService.service.downsizeTime}
                  </Text>
                </InformationCell>
              )}
            </View>
          ))}
        </View>
      </Container>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  scrollView: {
    flex: 1,
  },
  section: {
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: colors.secondaryMidGrey,
    marginBottom: 20,
  },
  statusBanner: {
    paddingHorizontal: 20,
    paddingVertical: 5,
    justifyContent: "center",
  },
  statusBanner__Text: {
    fontFamily: fonts.semiBold,
    color: colors.white,
    lineHeight: 20,
    marginBottom: 0,
    letterSpacing: 1,
  },
  denialReason: {
    padding: 20,
    paddingBottom: 5,
    backgroundColor: colors.lightGrey,
  },
  section__last: {
    borderBottomColor: "transparent",
  },
  sectionHeader: {
    fontSize: 14,
    color: colors.darkGrey,
    marginBottom: 10,
  },
  label: {
    color: colors.midGrey,
    marginBottom: 10,
  },
  cellLabel: {
    color: colors.midGrey,
    marginBottom: 3,
  },
  informationCell: {
    marginBottom: 15,
  },
  medicalConcernQuestion: {
    marginVertical: 5,
  },
  aftercareLinkContainer: {
    marginBottom: 15,
  },
  aftercareLink: {
    backgroundColor: colors.white,
    flexDirection: "row",
    alignItems: "center",
  },
  aftercareLink__Text: {
    marginLeft: 5,
  },
});

export default PastVisit;

import React, { FunctionComponent } from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { StyleSheet, View, ScrollView, Image, Linking } from "react-native";
import Heading from "../../components/Heading";
import Text from "../../components/Text";
import StoreInformation from "../../components/StoreInformation";
import Button from "../../components/Button";
import { colors, fonts } from "../../theme";
import { summarizeAddress } from "../../utils/address";
import VisitServicesSummary from "../../components/VisitServicesSummary";
import {
  MEDICAL_CONCERN_QUESTIONS,
  MedicalConcernQuestions,
  VisitStatusColors,
  DEFAULT_APP_LINK,
} from "../../constants";
import { Visit, VisitCustomerInformation, VisitStatus } from "../../types";
import { formatDateOfBirth } from "../../utils/customer";
import { VisitStatusText } from "../../constants";
import { NavigatorParamList } from "../../navigators/types";
import Container from "../../components/Container";
import { includes } from "lodash";
import { useFirebaseAuth } from "../../firebase/AuthProvider";

type ScreenNavigationProp = StackNavigationProp<NavigatorParamList, "Visit">;

interface InformationCellProps {
  label: string;
}

const InformationCell: FunctionComponent<InformationCellProps> = ({
  label,
  children,
}) => (
  <View style={styles.informationCell}>
    <Heading size="label" style={styles.cellLabel}>
      {label}
    </Heading>

    {children}
  </View>
);

const UpcomingVisit = ({
  visit,
  navigation,
}: {
  visit: Visit;
  navigation: ScreenNavigationProp;
}) => {
  const customerInformation = visit.customerInformation as VisitCustomerInformation;
  const { currentCustomer } = useFirebaseAuth();

  return (
    <View style={styles.container}>
      <ScrollView style={styles.scroll}>
        <View
          style={[
            styles.statusBanner,
            {
              backgroundColor: VisitStatusColors[visit.status as VisitStatus],
            },
          ]}
        >
          <Heading size="label" style={styles.statusBanner__Text}>
            {VisitStatusText[visit.status as VisitStatus]}
          </Heading>
        </View>

        <Container>
          <View style={styles.contentInner}>
            <View style={styles.section}>
              <Heading size="label" style={styles.label}>
                The Shop
              </Heading>
              <StoreInformation store={visit.store} />
            </View>
            <View style={styles.section}>
              <Heading size="label" style={styles.label}>
                Basic Info
              </Heading>

              <InformationCell label="Name">
                <Text size="l" variant="bold">
                  {customerInformation.firstName} {customerInformation.lastName}
                </Text>
              </InformationCell>

              <InformationCell label="Date of Birth">
                <Text size="l" variant="bold">
                  {formatDateOfBirth(customerInformation.dateOfBirth)}
                </Text>
              </InformationCell>

              {customerInformation?.address && (
                <InformationCell label="Address">
                  <Text size="l" variant="bold">
                    {summarizeAddress(customerInformation.address)}
                  </Text>
                </InformationCell>
              )}

              {customerInformation.phoneNumber && (
                <InformationCell label="Phone Number">
                  <Text size="l" variant="bold">
                    {customerInformation.phoneNumber}
                  </Text>
                </InformationCell>
              )}

              {(customerInformation.idImageUrls || []).length > 0 && (
                <InformationCell label="ID">
                  <Image
                    source={{ uri: (customerInformation.idImageUrls || [])[0] }}
                    style={styles.idImagePreview}
                  />
                </InformationCell>
              )}
            </View>
            <View style={styles.section}>
              <Heading size="label" style={styles.label}>
                Procedure Details
              </Heading>

              <VisitServicesSummary
                services={visit.services ? visit.services : []}
                variant="l"
              />
            </View>
            <View style={[styles.section, styles.section__last]}>
              <Heading size="label" style={styles.label}>
                Health Information
              </Heading>

              <InformationCell label="Medical History">
                <Text size="l" variant="bold">
                  {(visit?.medicalHistory?.medicalConditions || []).length > 0
                    ? (visit?.medicalHistory?.medicalConditions || []).join(
                        "\n",
                      )
                    : "N/A"}
                </Text>
              </InformationCell>

              <InformationCell label="Medical Concerns">
                {Object.keys(MEDICAL_CONCERN_QUESTIONS).map((questionName) => {
                  const answer = (visit.medicalHistory || ({} as any))[
                    questionName
                  ];
                  const question =
                    MEDICAL_CONCERN_QUESTIONS[
                      questionName as keyof MedicalConcernQuestions
                    ];

                  return (
                    <View
                      style={styles.medicalConcernQuestion}
                      key={questionName}
                    >
                      <Text size="m">{question.text}</Text>
                      <Text size="l" variant="bold">
                        {answer === undefined
                          ? "N/A"
                          : answer === true
                          ? "Yes"
                          : "No"}
                      </Text>
                    </View>
                  );
                })}

                <View style={styles.medicalConcernQuestion}>
                  <Text size="m">Any other medical conditions or notes?</Text>
                  <Text size="l" variant="bold">
                    {visit.medicalHistory?.additionalNotes || "N/A"}
                  </Text>
                </View>
              </InformationCell>
            </View>
          </View>
        </Container>
      </ScrollView>

      {currentCustomer && visit.status === VisitStatus.Draft && (
        <View style={styles.actionContainer}>
          <Button
            title="Begin Consent Form"
            onPress={() =>
              navigation.navigate("ExistingVisitFlow", { visitId: visit.id })
            }
          />
        </View>
      )}

      {currentCustomer && visit.status === VisitStatus.ConsentRequested && (
        <View style={styles.actionContainer}>
          <Button
            title="Finish Consent Form"
            onPress={() =>
              navigation.navigate("ExistingVisitFlow", { visitId: visit.id })
            }
          />
        </View>
      )}

      {!currentCustomer &&
        includes(
          [VisitStatus.Draft, VisitStatus.ConsentRequested],
          visit.status,
        ) && (
          <View style={styles.actionContainer}>
            <Button
              title="Continue in app"
              onPress={() => {
                // If we're not a logged in customer, we want to drive them to the app
                // to continue processing their consent form
                Linking.openURL(
                  `${DEFAULT_APP_LINK}?link_path=/visits/${visit.id}`,
                );
              }}
            />
          </View>
        )}
    </View>
  );
};

const styles = StyleSheet.create({
  contentInner: {
    padding: 20,
  },
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  actionContainer: {
    backgroundColor: colors.black,
    padding: 20,
    paddingBottom: 35,
  },
  statusBanner: {
    paddingHorizontal: 20,
    paddingVertical: 5,
    justifyContent: "center",
  },
  statusBanner__Text: {
    fontFamily: fonts.semiBold,
    color: colors.white,
    lineHeight: 20,
    marginBottom: 0,
    letterSpacing: 1,
  },
  idImagePreview: {
    width: 140,
    height: 160,
    borderRadius: 10,
  },
  section: {
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: colors.secondaryMidGrey,
    marginBottom: 20,
  },
  section__last: {
    borderBottomColor: "transparent",
  },
  label: {
    color: colors.midGrey,
    marginBottom: 10,
  },
  cellLabel: {
    color: colors.darkGrey,
    textTransform: "none",
  },
  informationCell: {
    marginBottom: 10,
  },
  medicalConcernQuestion: {
    marginVertical: 5,
  },
});

export default UpcomingVisit;

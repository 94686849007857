import { uniqueId, get } from "lodash";
import React from "react";
import { StyleSheet, ScrollView, View, Image } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useDispatch } from "react-redux";
import { Formik, FormikValues } from "formik";
import RNPickerSelect from "react-native-picker-select";

import KeyboardAvoidingView from "../../components/KeyboardAvoidingView";
import Button from "../../components/Button";
import Header from "../../components/Header";
import { upsertService } from "../../state/consentFlow";
import { colors, fonts } from "../../theme";
import {
  Service,
  ServiceInformationField,
  ServiceInformationOptionField,
  ServiceInformationSelectField,
  ServiceInformationTextField,
  ServiceType,
} from "../../types";

import {
  ServiceSelectionTitle,
  ServiceSelectionDescription,
} from "./constants";
import RadioButton from "../../components/RadioButton";
import TextInputField, {
  TextInputFieldProps,
} from "../../components/TextInputField";
import Textarea from "../../components/Textarea";
import Heading from "../../components/Heading";
import { useRoute } from "@react-navigation/native";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  formContainer: {
    flex: 1,
  },
  scrollView: {
    paddingHorizontal: 20,
  },
  formInner: {
    paddingVertical: 20,
  },
  fieldWrapper: {
    marginBottom: 10,
  },
  fieldLabel: {
    color: colors.white,
    marginBottom: 5,
  },
  optionFieldOptions: {
    flexDirection: "row",
    marginBottom: 15,
  },
});

const pickerStyles = StyleSheet.create({
  inputIOS: {
    borderWidth: 1,
    backgroundColor: colors.white,
    paddingHorizontal: 10,
    paddingVertical: 12,
    borderRadius: 5,
    fontSize: 16,
    color: colors.black,
    fontFamily: fonts.regular,
  },
  inputAndroid: {
    borderWidth: 1,
    backgroundColor: colors.white,
    paddingHorizontal: 10,
    paddingVertical: 12,
    borderRadius: 5,
    fontSize: 16,
    color: colors.black,
    fontFamily: fonts.regular,
  },
  iconContainer: {
    right: 10,
    top: 15,
  },
});

type Props = {
  serviceType: ServiceType;
  service: Service;
  navigation: any;
};

type FieldWrapperProps = {
  label: string;
};

type TextInputProps = {
  field: ServiceInformationTextField;
} & TextInputFieldProps;

type OptionInputProps = {
  field: ServiceInformationOptionField;
  value: string;
  onChange: (value: string) => void;
};

type SelectInputProps = {
  field: ServiceInformationSelectField;
  value: string;
  onChange: (value: string) => void;
};

const FieldWrapper: React.FC<FieldWrapperProps> = ({ label, children }) => (
  <View style={styles.fieldWrapper}>
    <Heading size="label" style={styles.fieldLabel}>
      {label}
    </Heading>
    {children}
  </View>
);

const TextInput: React.FC<TextInputProps> = ({ field, ...props }) => {
  return (
    <FieldWrapper label={field.label}>
      {field.type === "TEXT" ? (
        <TextInputField
          {...props}
          name={field.name}
          placeholder={field.placeholder}
        />
      ) : (
        <Textarea
          {...props}
          name={field.name}
          placeholder={field.placeholder}
        />
      )}
    </FieldWrapper>
  );
};

const OptionInput: React.FC<OptionInputProps> = ({
  field,
  onChange,
  ...props
}) => (
  <FieldWrapper label={field.label}>
    <View style={styles.optionFieldOptions}>
      {(field.options || []).map((option, idx) => (
        <RadioButton
          key={idx}
          label={option}
          status={props.value === option ? "checked" : "unchecked"}
          onPress={() => onChange(option)}
          style={{ marginRight: 20 }}
        />
      ))}
    </View>
  </FieldWrapper>
);

const SelectInput: React.FC<SelectInputProps> = ({
  field,
  onChange,
  ...props
}) => (
  <FieldWrapper label={field.label}>
    <RNPickerSelect
      {...props}
      style={{ ...pickerStyles }}
      value={props.value}
      onValueChange={(value) => onChange(value as string)}
      Icon={() => (
        <Image source={require("../../../assets/icons/chevron.png")} />
      )}
      items={(field.options || []).map((option) => ({
        label: option,
        value: option,
      }))}
    />
  </FieldWrapper>
);

const SingleServiceEntry: React.FC<Props> = ({
  service,
  navigation,
  serviceType,
}) => {
  const route = useRoute();
  const dispatch = useDispatch();

  const fields = service.informationFields as ServiceInformationField[];
  const requiredFieldNames = fields
    .filter((field) => field.isRequired)
    .map((field) => field.name);

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <KeyboardAvoidingView style={styles.formContainer}>
        <Formik
          onSubmit={(input: FormikValues) => {
            const answers = fields.map((field) => {
              return {
                name: field.name,
                label: field.label,
                value: input[field.name],
              };
            });

            dispatch(
              upsertService({
                id: uniqueId("service"),
                serviceId: service.id,
                submittedInformation: answers,
                isExistingService: false,
              }),
            );

            navigation.navigate("ReviewServicesForVisit", { ...route.params });
          }}
          validate={(values: FormikValues) => {
            return requiredFieldNames.reduce((errors, fieldName) => {
              return (values[fieldName] as string) === ""
                ? { ...errors, [fieldName]: "is required" }
                : errors;
            }, {});
          }}
          initialValues={fields.reduce(
            (acc, field) => ({ ...acc, [field.name]: "" }),
            {},
          )}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
            isSubmitting,
            isValid,
            setFieldValue,
          }) => {
            return (
              <>
                <Container>
                  <ScrollView style={styles.scrollView}>
                    <Header
                      title={ServiceSelectionTitle[serviceType.type]}
                      description={
                        ServiceSelectionDescription[serviceType.type]
                      }
                    />

                    <View style={styles.formInner}>
                      {fields.map((field, idx) => {
                        switch (field.__typename) {
                          case "ServiceInformationOptionField": {
                            return (
                              <OptionInput
                                key={idx}
                                field={field}
                                value={get(values, field.name) as string}
                                onChange={(result: string) =>
                                  setFieldValue(field.name, result)
                                }
                              />
                            );
                          }
                          case "ServiceInformationSelectField": {
                            return (
                              <SelectInput
                                key={idx}
                                field={field}
                                value={get(values, field.name) as string}
                                onChange={(result: string) =>
                                  setFieldValue(field.name, result)
                                }
                              />
                            );
                          }
                          case "ServiceInformationTextField": {
                            return (
                              <TextInput
                                key={idx}
                                field={field}
                                name={field.name}
                                touched={!!get(touched, field.name)}
                                error={get(errors, field.name)}
                                onChangeText={handleChange(field.name)}
                                onBlur={handleBlur(field.name)}
                                value={get(values, field.name) as string}
                              />
                            );
                          }
                        }
                      })}
                    </View>
                  </ScrollView>
                </Container>
                <StickyFooter>
                  <Button
                    title="Next"
                    onPress={handleSubmit}
                    disabled={!isValid}
                    isLoading={isSubmitting}
                  />
                </StickyFooter>
              </>
            );
          }}
        </Formik>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default SingleServiceEntry;

import React from "react";
import {
  TouchableHighlight,
  View,
  Image,
  StyleSheet,
  GestureResponderEvent,
} from "react-native";
import { colors } from "../../theme";

type Props = {
  isChecked: boolean;
  onPress: (isChecked: boolean) => void;
};

const CheckBox: React.FC<Props> = ({ isChecked, onPress, children }) => {
  return (
    <TouchableHighlight
      onPress={() => onPress(!isChecked)}
      hitSlop={{ top: 20, bottom: 20, right: 20, left: 20 }}
    >
      <View style={styles.outerContainer}>
        <View style={styles.parentContainer}>
          <View
            style={[styles.container, isChecked && styles.container__selected]}
          >
            {isChecked && (
              <Image
                source={require("../../../assets/icons/check.png")}
                style={{ width: 24, height: 24 }}
              />
            )}
          </View>
        </View>
        <View style={{ flex: 1 }}>{children}</View>
      </View>
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  parentContainer: {
    width: 24,
    height: 24,
    borderRadius: 5,
    overflow: "hidden",
  },
  outerContainer: {
    alignItems: "flex-start",
    flexDirection: "row",
    backgroundColor: colors.black,
    flex: 1,
  },
  container: {
    flex: 1,
    backgroundColor: colors.darkestGrey,
  },
  container__selected: {
    backgroundColor: colors.yellow,
  },
});

export default CheckBox;

import React, { FunctionComponent, useState } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import Button from "../../components/Button";
import Container from "../../components/Container";
import Header from "../../components/Header";
import ProcedureLocationList from "../../components/ProcedureLocationList";
import StickyFooter from "../../components/StickyFooter";
import { colors } from "../../theme";
import { ServiceType, ServiceTypeCategory, Store } from "../../types";
import { filterCategories } from "../../utils/service";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  scrollView: {
    paddingHorizontal: 20,
    flex: 1,
  },
});

type RouteParams = {
  store: Store;
  serviceType: ServiceType;
};

interface Props {
  route: any;
  navigation: any;
}

const ServiceCategorySelectionForVisitScreen: FunctionComponent<Props> = ({
  route,
  navigation,
}) => {
  const { serviceType }: RouteParams = route.params;

  const [
    selectedServiceTypeCategory,
    setSelectedServiceTypeCategory,
  ] = useState<ServiceTypeCategory | null>(null);

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <Container>
        <ScrollView style={styles.scrollView}>
          <Header
            title={`${serviceType?.name} Location`}
            description={`Start by selecting the location for your ${serviceType?.name}.`}
          />
          {serviceType?.categories && (
            <ProcedureLocationList
              serviceType={serviceType}
              locations={filterCategories(serviceType?.categories)}
              selectedLocation={selectedServiceTypeCategory}
              onSelect={(category) => setSelectedServiceTypeCategory(category)}
            />
          )}
        </ScrollView>
      </Container>
      <StickyFooter>
        <Button
          title="Next"
          onPress={() =>
            selectedServiceTypeCategory &&
            navigation.navigate("ServiceSelectionForVisit", {
              serviceType,
              serviceTypeCategory: selectedServiceTypeCategory,
            })
          }
          disabled={!selectedServiceTypeCategory}
        />
      </StickyFooter>
    </SafeAreaView>
  );
};

export default ServiceCategorySelectionForVisitScreen;

import { gql } from "@apollo/client";

export const UPDATE_MEDICAL_HISTORY = gql`
  mutation UpdateMedicalHistory($input: UpdateMedicalHistoryInput!) {
    result: updateMedicalHistory(input: $input) {
      customer {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;

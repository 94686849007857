import { useQuery } from "@apollo/client";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { colors } from "../../theme";
import { VISIT } from "./gql";
import UpcomingVisit from "./UpcomingVisit";
import PastVisit from "./PastVisit";
import { isPastVisit } from "../../utils/visit";
import { NavigatorParamList } from "../../navigators/types";
import VisitNotFound from "./VisitNotFound";

type ScreenNavigationProp = StackNavigationProp<NavigatorParamList, "Visit">;
type ScreenRouteProp = RouteProp<NavigatorParamList, "Visit">;

type Props = {
  navigation: ScreenNavigationProp;
  route: ScreenRouteProp;
};

const VisitScreen: React.FC<Props> = ({ route, navigation }) => {
  const { visitId } = route.params;
  const { data, loading } = useQuery(VISIT, { variables: { visitId } });
  const visit = data?.visit;
  const isPast = isPastVisit(visit?.status);

  return (
    <SafeAreaView style={styles.container} edges={["right", "left"]}>
      {loading && (
        <View style={styles.loadingContainer}>
          <ActivityIndicator color={colors.yellow} size="large" />
        </View>
      )}

      {!loading && !visit && <VisitNotFound navigation={navigation} />}

      {visit && isPast && <PastVisit visit={visit} navigation={navigation} />}
      {visit && !isPast && (
        <UpcomingVisit visit={visit} navigation={navigation} />
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  loadingContainer: {
    padding: 10,
  },
});

export default VisitScreen;

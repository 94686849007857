import React from "react";
import { KeyboardAvoidingView, Platform, StatusBar } from "react-native";
import { useHeaderHeight } from "@react-navigation/stack";

type Props = {
  style: Object;
};

const statusBarHeight =
  Platform.OS === "ios" ? StatusBar.currentHeight || 0 : 0;

const CustomKeyboardAvoidingView: React.FC<Props> = ({ children, style }) => {
  const headerHeight = useHeaderHeight();

  return (
    <KeyboardAvoidingView
      style={style}
      behavior={Platform.OS === "ios" ? "padding" : undefined}
      keyboardVerticalOffset={headerHeight + statusBarHeight}
    >
      {children}
    </KeyboardAvoidingView>
  );
};

export default CustomKeyboardAvoidingView;

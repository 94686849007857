import React from "react";
import { StyleSheet, View } from "react-native";
import Text from "../../components/Text";
import CheckBox from "../../components/CheckBox";
import Textarea from "../../components/Textarea";
import RadioButton from "../../components/RadioButton";
import { colors } from "../../theme";

type Props = {
  value: string | boolean | null;
  isRequired: boolean;
  question: string;
  onChange: (value: string | boolean) => void;
};

const Required = () => (
  <Text size="l" style={styles.requiredText}>
    {" "}
    (Required)
  </Text>
);

export const BooleanQuestion: React.FC<Props> = ({
  value,
  isRequired,
  question,
  onChange,
}) => (
  <CheckBox isChecked={value === "true"} onPress={onChange}>
    <Text size="l" style={[styles.questionText, styles.questionText__Boolean]}>
      {question}
      {isRequired && <Required />}
    </Text>
  </CheckBox>
);

export const TextQuestion: React.FC<Props> = ({
  value,
  isRequired,
  question,
  onChange,
}) => (
  <View style={styles.question}>
    <Text style={[styles.questionText, { marginBottom: 10 }]} size="l">
      {question}
      {isRequired && <Required />}
    </Text>
    <Textarea value={value as string} onChangeText={onChange} />
  </View>
);

export const YesNoQuestion: React.FC<Props> = ({
  value,
  isRequired,
  question,
  onChange,
}) => (
  <View style={styles.question}>
    <Text style={styles.questionText} size="l">
      {question}
      {isRequired && <Required />}
    </Text>

    <View style={styles.optionFieldOptions}>
      {["yes", "no"].map((option, idx) => (
        <RadioButton
          key={idx}
          label={option === "yes" ? "Yes" : "No"}
          status={value === option ? "checked" : "unchecked"}
          onPress={() => onChange(option)}
          style={{ marginRight: 20 }}
        />
      ))}
    </View>
  </View>
);

const styles = StyleSheet.create({
  question: {},
  questionText: {
    color: colors.white,
  },
  questionText__Boolean: {
    marginLeft: 20,
  },
  requiredText: {
    color: colors.darkGrey,
    marginLeft: 20,
  },
  optionFieldOptions: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 15,
  },
});

import React from "react";
import { StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Formik } from "formik";
import * as Yup from "yup";

import { useNavigation } from "@react-navigation/native";

import KeyboardAvoidingView from "../../components/KeyboardAvoidingView";
import TextInputField from "../../components/TextInputField";
import Button from "../../components/Button";
import { colors } from "../../theme";
import Heading from "../../components/Heading";
import StickyFooter from "../../components/StickyFooter";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { NewVisitFlowScreens } from "../../constants";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  formContainer: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  scrollViewInner: {
    paddingHorizontal: 20,
    marginTop: 20,
  },
  forgotPassword: {
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 15,
  },
});

const Schema = Yup.object().shape({
  emailAddress: Yup.string()
    .email("Email address must be a valid email")
    .required("Email address is required"),
  password: Yup.string().required("Password is required"),
});

const LoginViaEmailScreen = () => {
  const auth = getAuth();
  const navigation = useNavigation();
  const postLogin = () => {
    navigation.navigate("DateOfBirthInput", {
      updateProfile: true,
      nextScreen: NewVisitFlowScreens.InitialScreen,
    });
  };

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <KeyboardAvoidingView style={styles.formContainer}>
        <Formik
          initialValues={{
            emailAddress: "",
            password: "",
          }}
          validationSchema={Schema}
          onSubmit={async (input, { setFieldError }) => {
            await signInWithEmailAndPassword(
              auth,
              input.emailAddress,
              input.password,
            );
            postLogin();
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            touched,
            errors,
            isSubmitting,
            isValid,
          }) => {
            return (
              <>
                <ScrollView
                  style={styles.scrollView}
                  contentContainerStyle={styles.scrollViewInner}
                >
                  <TextInputField
                    autoFocus
                    autoCompleteType="email"
                    name="emailAddress"
                    textContentType="emailAddress"
                    autoCapitalize="none"
                    keyboardType="email-address"
                    placeholder="Email Address"
                    hasError={!!errors?.emailAddress}
                    onChangeText={(text) =>
                      handleChange("emailAddress")(text.trim())
                    }
                    onBlur={handleBlur}
                    value={values.emailAddress}
                    touched={!!touched.emailAddress}
                    error={errors.emailAddress}
                  />
                  <TextInputField
                    autoCompleteType="password"
                    name="password"
                    autoCapitalize="none"
                    placeholder="Password"
                    hasError={!!errors?.password}
                    secureTextEntry
                    onChangeText={handleChange("password")}
                    onBlur={handleBlur}
                    value={values.password}
                    touched={!!touched.password}
                    error={errors.password}
                  />

                  <TouchableOpacity
                    onPress={() => navigation.navigate("ForgotPassword")}
                    style={styles.forgotPassword}
                  >
                    <Heading size="smallcaps" style={{ color: colors.yellow }}>
                      Forgot password?
                    </Heading>
                  </TouchableOpacity>
                </ScrollView>
                <StickyFooter>
                  <Button
                    title="Login"
                    onPress={handleSubmit}
                    disabled={!isValid}
                    isLoading={isSubmitting}
                  />
                </StickyFooter>
              </>
            );
          }}
        </Formik>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default LoginViaEmailScreen;

import React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { Formik } from "formik";
import * as Yup from "yup";

import TextInputField from "../../components/TextInputField";
import Button from "../../components/Button";
import { colors, fonts } from "../../theme";
import { Customer, SetPhoneNumberInput } from "../../types";
import { phoneRegExp } from "../../utils/forms/validations";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";

const styles = StyleSheet.create({
  scrollView: {
    padding: 20,
    flex: 1,
  },
  formInner: {
    marginBottom: 40,
  },
  formHeader: {
    fontFamily: fonts.medium,
    color: colors.white,
    textTransform: "uppercase",
    marginBottom: 5,
  },
});

type FormProps = {
  submitText: string;
  onSubmit: (values: SetPhoneNumberInput, actions: any) => Promise<unknown>;
  customer: Customer | null;
};

const Schema = Yup.object().shape({
  phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
});

const Form = ({ customer, onSubmit, submitText }: FormProps) => {
  const initialPhoneValue = customer?.phoneNumber || "";
  const initialValues = {
    phoneNumber: initialPhoneValue,
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Schema}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        isValid,
        isSubmitting,
      }) => {
        return (
          <>
            <Container>
              <ScrollView style={styles.scrollView}>
                <View style={styles.formInner}>
                  <Text style={styles.formHeader}>Phone Number</Text>
                  <TextInputField
                    name="phoneNumber"
                    placeholder="Phone number"
                    keyboardType="phone-pad"
                    hasError={!!errors?.phoneNumber}
                    onChangeText={(text) => handleChange("phoneNumber")(text)}
                    onBlur={handleBlur("phoneNumber")}
                    value={values.phoneNumber}
                    touched={!!touched.phoneNumber}
                    error={errors.phoneNumber}
                  />
                </View>
              </ScrollView>
            </Container>

            <StickyFooter>
              <Button
                title={submitText}
                onPress={handleSubmit}
                disabled={!isValid}
                isLoading={isSubmitting}
              />
            </StickyFooter>
          </>
        );
      }}
    </Formik>
  );
};

export default Form;

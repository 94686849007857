import React from "react";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import SocialButton from "./SocialButton";
import { SocialLoginType } from "../../types";

const provider = new GoogleAuthProvider();

interface Props {
  onSuccess: () => Promise<void>;
}

const GoogleLogin = ({ onSuccess }: Props) => {
  const auth = getAuth();

  return (
    <SocialButton
      title="Log In with Google"
      social={SocialLoginType.Google}
      onPress={async () => {
        await signInWithPopup(auth, provider);
        await onSuccess();
      }}
    />
  );
};

export default GoogleLogin;

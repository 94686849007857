import { colors } from "./theme";
import { ServiceTypeOffering, VisitStatus } from "./types";

export const FeatureFlags = {
  STORE_BROWSE_SCREEN: false,
};

export type VisitSubject = "minor" | "self";

export type MedicalConcernQuestion = {
  text: string;
  checked: boolean | null;
  errorMessage?: string;
};

export type MedicalConcernQuestions = {
  isGenerallyHealthy: MedicalConcernQuestion;
  hasSkinCondition: MedicalConcernQuestion;
  hasIndicatedMedicationUse: MedicalConcernQuestion;
  hasAllergyToProducts: MedicalConcernQuestion;
  isProneToFainting: MedicalConcernQuestion;
  hasBloodConditions: MedicalConcernQuestion;
  hasHistoryOfComplications: MedicalConcernQuestion;
};

export const MEDICAL_CONCERN_QUESTIONS: MedicalConcernQuestions = {
  isGenerallyHealthy: {
    text:
      "Do you believe you are healthy enough to receive and successfully heal body art such as piercing, tattooing or permanent makeup?",
    checked: null,
    errorMessage: "Yes is required in order to proceed",
  },
  hasSkinCondition: {
    text: "Do you have a history of skin conditions?",
    checked: null,
  },
  hasIndicatedMedicationUse: {
    text:
      "Do you use any medications that might affect the healing of your body art?",
    checked: null,
  },
  hasAllergyToProducts: {
    text:
      "Do you have sensitivities or allergies to soaps, cosmetics, antiseptics, natural rubber latex, or metals of any kind?",
    checked: null,
  },
  isProneToFainting: {
    text: "Are you prone to fainting, epilepsy, seizures, or narcolepsy?",
    checked: null,
  },
  hasBloodConditions: {
    text:
      "Do you have any medical conditions that might affect the healing of your body art, such as diabetes, high blood pressure, or hemophilia?",
    checked: null,
  },
  hasHistoryOfComplications: {
    text: "Do you have a history of complications with body art?",
    checked: null,
  },
};

export const MEDICAL_CONDITIONS = [
  "Antibiotic Allergies",
  "Asthma",
  "Blood Thinners",
  "Cardiac Disease",
  "Diabetes",
  "Hepatitis",
  "MRSA / Staph Infections",
  "Pregnant / Nursing",
  "Scarring / Keloiding",
  "Tuberculosis",
];

export const VisitStatusColors = {
  [VisitStatus.Draft]: colors.statusRed,
  [VisitStatus.Pending]: colors.statusRed,
  [VisitStatus.Reviewing]: colors.statusBlue,
  [VisitStatus.Submitted]: colors.statusBlue,
  [VisitStatus.Denied]: colors.statusRed,
  [VisitStatus.ConsentRequested]: colors.statusRed,
  [VisitStatus.Approved]: colors.statusGreen,
  [VisitStatus.Completed]: colors.midGrey,
};

export const VisitStatusText = {
  [VisitStatus.Draft]: "Consent Incomplete",
  [VisitStatus.Pending]: "Consent Incomplete",
  [VisitStatus.Submitted]: "Pending Review",
  [VisitStatus.ConsentRequested]: "Consent Incomplete",
  [VisitStatus.Reviewing]: "Pending Review",
  [VisitStatus.Denied]: "Denied",
  [VisitStatus.Approved]: "Approved",
  [VisitStatus.Completed]: "Completed",
};

export const NewVisitFlowScreens = {
  InitialScreen: "VisitSubject",
};

export const DEFAULT_MIN_AGE = 13;
export const DEFAULT_MAX_AGE = 120;

export const DEFAULT_APP_LINK = "https://bodyart.app.link/m1m6bdjeadb";

export const ServiceTypes = {
  [ServiceTypeOffering.Tattoo]: "Tattoo",
  [ServiceTypeOffering.PermanentMakeup]: "Permanent Makeup",
  [ServiceTypeOffering.Piercing]: "Piercing",
};

export const PRIVACY_URL = "https://www.bodyart.app/privacy-policy";
export const TERMS_URL = "https://www.bodyart.app/terms-of-service";

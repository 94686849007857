import React, { FunctionComponent, useState } from "react";
import { StyleSheet, ScrollView, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import KeyboardAvoidingView from "../../components/KeyboardAvoidingView";
import Button from "../../components/Button";
import Header from "../../components/Header";
import { colors } from "../../theme";
import RadioButton from "../../components/RadioButton";
import Textarea from "../../components/Textarea";
import Text from "../../components/Text";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state";
import { setMedicalConcerns } from "../../state/consentFlow";
import { useMutation } from "@apollo/client";
import { UPDATE_MEDICAL_HISTORY } from "./gql";
import { buildMedicalHistoryPayload } from "../../utils/medicalHistory";
import {
  MedicalConcernQuestions,
  MEDICAL_CONCERN_QUESTIONS,
  VisitSubject,
} from "../../constants";
import { Copy } from "./constants";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  formContainer: {
    flex: 1,
  },
  scrollView: {
    paddingHorizontal: 20,
  },
  question: {
    paddingVertical: 15,
    borderBottomColor: colors.darkestGrey,
    borderBottomWidth: 1,
  },
  questionLabel: {
    color: colors.white,
    marginBottom: 15,
  },
  questionButtons: {
    flexDirection: "row",
    marginLeft: "auto",
  },
});

interface MedicalConcernQuestionProps {
  question: string;
  errorMessage?: string;
  errorMessageActive?: boolean;
  checked: boolean | null;
  onPress: (b: boolean) => void;
}

const MedicalConcernQuestion: FunctionComponent<MedicalConcernQuestionProps> = ({
  question,
  errorMessage,
  errorMessageActive,
  checked,
  onPress,
}) => (
  <View style={styles.question}>
    <Text size="l" style={styles.questionLabel}>
      {question}
    </Text>

    <View style={styles.questionButtons}>
      <RadioButton
        label="Yes"
        status={checked === true ? "checked" : "unchecked"}
        onPress={() => onPress(true)}
        style={{ marginRight: 20 }}
      />
      <RadioButton
        label="No"
        status={checked === false ? "checked" : "unchecked"}
        onPress={() => onPress(false)}
      />
    </View>
    {!!errorMessage && (
      <Text
        style={[
          styles.questionLabel,
          {
            alignSelf: "flex-end",
            paddingTop: 10,
            marginBottom: -5,
            color: errorMessageActive ? colors.yellow : colors.white,
          },
        ]}
      >
        {errorMessage}
      </Text>
    )}
  </View>
);

type AnswerState = {
  [question: string]: boolean;
};

interface Props {
  route: any;
  navigation: any;
}

const MedicalConcernsForVisitScreen: FunctionComponent<Props> = ({
  route,
  navigation,
}) => {
  const dispatch = useDispatch();
  const { nextScreen } = route.params;
  const [updateMedicalHistory, { loading }] = useMutation(
    UPDATE_MEDICAL_HISTORY,
    {
      onCompleted: () => {
        navigation.navigate(nextScreen || "ConsentQuestions", route.params);
      },
    },
  );

  const { medicalConcerns, medicalHistory, subject } = useSelector(
    (state: RootState) => state.consentFlow,
  );
  const { answers: selectedAnswers, additionalNotes } = medicalConcerns;

  const [additionalText, setAdditionalText] = useState<string | undefined>(
    additionalNotes,
  );
  const [answers, setAnswers] = useState<AnswerState>(selectedAnswers);

  const questionNames = Object.keys(MEDICAL_CONCERN_QUESTIONS);
  const allAnswered =
    questionNames.every((qn) => answers[qn] !== undefined) &&
    answers["isGenerallyHealthy"];

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <KeyboardAvoidingView style={styles.formContainer}>
        <Container>
          <ScrollView style={styles.scrollView}>
            <Header
              title={Copy.title}
              description={Copy.description[subject.role as VisitSubject]}
            />

            {questionNames.map((name, idx) => {
              const item =
                MEDICAL_CONCERN_QUESTIONS[
                  name as keyof MedicalConcernQuestions
                ];
              const result =
                answers[name] !== undefined ? answers[name] : item.checked;
              const errorMessageActive =
                name === "isGenerallyHealthy" ? !result : false;

              return (
                <MedicalConcernQuestion
                  key={`question-${idx}`}
                  question={item.text}
                  errorMessage={item.errorMessage}
                  errorMessageActive={errorMessageActive}
                  checked={result}
                  onPress={(result: boolean) =>
                    setAnswers({ ...answers, [name]: result })
                  }
                />
              );
            })}
            <View style={styles.question}>
              <Text style={styles.questionLabel} size="l">
                Any other medical conditions or notes? (optional)
              </Text>
              <Textarea
                value={additionalText}
                onChangeText={(text: string) => setAdditionalText(text)}
              />
            </View>
          </ScrollView>
        </Container>
        <StickyFooter>
          <Button
            title="Next"
            isLoading={loading}
            onPress={() => {
              const nextMedicalConcernState = {
                answers,
                additionalNotes: additionalText,
              };

              dispatch(setMedicalConcerns(nextMedicalConcernState));

              updateMedicalHistory({
                variables: {
                  input: {
                    // Always send the customer that we're setting
                    customerId: subject.id,
                    medicalHistory: buildMedicalHistoryPayload(
                      medicalHistory,
                      nextMedicalConcernState,
                    ),
                  },
                },
              });
            }}
            disabled={!allAnswered}
          />
        </StickyFooter>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default MedicalConcernsForVisitScreen;

import React, { FunctionComponent } from "react";
import {
  StyleSheet,
  ScrollView,
  TouchableHighlight,
  TouchableOpacity,
  View,
  Image,
} from "react-native";
import { useApolloClient } from "@apollo/client";
import { SafeAreaView } from "react-native-safe-area-context";

import Heading from "../../components/Heading";
import Button from "../../components/Button";
import Header from "../../components/Header";
import Text from "../../components/Text";
import { partialService } from "../../fragments";
import { colors } from "../../theme";
import { Service, ServiceTypeOffering } from "../../types";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../state";
import { removeService } from "../../state/consentFlow";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";

interface Props {
  route: any;
  navigation: any;
}

const ReviewServicesForVisitScreen: FunctionComponent<Props> = ({
  route,
  navigation,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const servicesInStore = useSelector(
    (state: RootState) => state.consentFlow.services,
  );

  const totalServices = Object.keys(servicesInStore).length;
  const nextScreen = route.params?.nextScreenForSection;

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <Container>
        <ScrollView style={styles.scrollView}>
          <Header
            title="Review Procedures"
            description="Check to make sure everything looks right."
          />

          <View style={styles.services}>
            {Object.keys(servicesInStore).map((key, idx) => {
              const serviceDetails = servicesInStore[key];
              const service: Service | null = client.readFragment({
                fragment: partialService,
                fragmentName: "partialService",
                id: `Service:${serviceDetails.serviceId}`,
              });

              return service ? (
                <View style={styles.serviceCard} key={key}>
                  <View style={styles.serviceCard__Content}>
                    <Heading size="label" style={styles.serviceNumber}>
                      Procedure {idx + 1}
                    </Heading>

                    <Heading size="m" style={styles.serviceType}>
                      {service.serviceType.name}
                    </Heading>

                    {service.serviceType.type !==
                      ServiceTypeOffering.Tattoo && (
                      <Text style={styles.serviceDetails}>{service.name}</Text>
                    )}

                    {(serviceDetails?.submittedInformation || []).map(
                      (submitted, idx) => (
                        <Text style={styles.serviceDetails} key={idx}>
                          {submitted.value}
                        </Text>
                      ),
                    )}
                  </View>

                  <TouchableOpacity
                    onPress={() => dispatch(removeService(serviceDetails))}
                    style={styles.removeService}
                  >
                    <Image
                      source={require("../../../assets/icons/trash-selected.png")}
                      style={{ width: 24, height: 24 }}
                    />
                  </TouchableOpacity>
                </View>
              ) : null;
            })}
          </View>

          <TouchableHighlight
            style={styles.addServiceContainer}
            onPress={() =>
              navigation.navigate("NewServiceForVisit", {
                ...route.params,
                serviceIdx: totalServices + 1,
              })
            }
          >
            <>
              <Image
                source={require("../../../assets/icons/add-selected.png")}
                style={{ width: 24, height: 24 }}
              />
              <Heading size="smallcaps" style={styles.addServiceText}>
                Add another procedure
              </Heading>
            </>
          </TouchableHighlight>
        </ScrollView>
      </Container>
      <StickyFooter>
        <Button
          title={nextScreen ? "Save Changes" : "Next: Health"}
          onPress={() => {
            navigation.navigate(nextScreen || "MedicalHistoryForVisit");
          }}
          disabled={totalServices === 0}
        />
      </StickyFooter>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  scrollView: {
    paddingHorizontal: 20,
  },
  services: {
    marginTop: 20,
  },
  serviceCard: {
    marginBottom: 20,
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: colors.darkestGrey,
    flexDirection: "row",
  },
  serviceCard__Content: {
    flex: 1,
  },
  serviceNumber: {
    color: colors.darkGrey,
    marginBottom: 5,
  },
  serviceType: {
    textTransform: "uppercase",
    color: colors.white,
    letterSpacing: 1,
  },
  serviceDetails: {
    color: colors.midGrey,
  },
  removeService: {
    marginLeft: "auto",
    marginTop: 5,
  },
  addServiceContainer: {
    borderWidth: 2,
    borderColor: colors.yellow,
    borderRadius: 20,
    padding: 25,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginBottom: 20,
  },
  addServiceText: {
    color: colors.yellow,
    lineHeight: 25,
    marginLeft: 20,
    letterSpacing: 1,
  },
});

export default ReviewServicesForVisitScreen;

import { gql } from "@apollo/client";
import { fullVisit } from "../../fragments";

export const CREATE_VISIT = gql`
  mutation CreateVisit($input: CreateVisitInput!) {
    result: createVisit(input: $input) {
      visit {
        ...fullVisit
      }
      errors {
        field
        message
      }
    }
  }
  ${fullVisit}
`;

export const UPDATE_VISIT = gql`
  mutation UpdateVisit($input: UpdateVisitInput!) {
    result: updateVisit(input: $input) {
      visit {
        ...fullVisit
      }
      errors {
        field
        message
      }
    }
  }
  ${fullVisit}
`;

export const GENERATE_UPLOAD = gql`
  mutation GenerateImageUpload {
    result: generateUpload {
      authKey
      signature
      expires
      templateId
    }
  }
`;

import { format, differenceInYears, parseISO } from "date-fns";
import { Customer } from "../types";

export function formatDateOfBirth(dob: string): string | undefined {
  if (!dob) {
    return;
  }

  const date = parseISO(dob);
  const years = differenceInYears(new Date(), date);

  return format(date, "MM/dd/yyyy") + ` (${years} yrs old)`;
}

export function formatAgeInYears(dob: string): string | null {
  if (!dob) {
    return null;
  }

  const date = parseISO(dob);
  const years = differenceInYears(new Date(), date);

  return `${years} yrs old`;
}

export function ageInYears(dob: string): number | null {
  if (!dob) {
    return null;
  }

  const date = parseISO(dob);
  return differenceInYears(new Date(), date);
}

export function hasCompletedProfile(customer: Customer): boolean {
  return (
    !!customer &&
    !!customer.firstName &&
    !!customer.lastName &&
    !!customer.address
  );
}

import React, { FunctionComponent, useRef } from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import { padStart } from "lodash";
import { SafeAreaView } from "react-native-safe-area-context";
import { TextInputMask } from "react-native-masked-text";
import { parse, differenceInYears } from "date-fns";
import { Formik } from "formik";

import Button from "../../components/Button";
import KeyboardAvoidingView from "../../components/KeyboardAvoidingView";
import Text from "../../components/Text";
import { colors, fonts } from "../../theme";
import Container from "../../components/Container";
import Heading from "../../components/Heading";
import StickyFooter from "../../components/StickyFooter";
import { DEFAULT_MAX_AGE } from "../../constants";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  input: {
    borderWidth: 1,
    backgroundColor: colors.white,
    paddingHorizontal: 10,
    paddingVertical: 12,
    borderRadius: 5,
    fontSize: 16,
    color: colors.black,
    fontFamily: fonts.regular,
    marginBottom: 10,
  },
  formContainer: {
    flex: 1,
    marginTop: 20,
  },
  scrollView: {
    paddingHorizontal: 20,
    flex: 1,
  },
  formInner: {
    marginBottom: 40,
  },
  formHeader: {
    color: colors.white,
    marginBottom: 5,
  },
});

interface Props {
  navigation: any;
}

const MinorDateOfBirthInputScreen: FunctionComponent<Props> = ({
  navigation,
}) => {
  const inputRef = useRef<TextInputMask>();

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <KeyboardAvoidingView style={styles.formContainer}>
        <Formik
          initialValues={{ dateOfBirth: "" }}
          validateOnBlur
          validate={(values) => {
            const errors: { dateOfBirth?: string } = {};
            const dateOfBirth = values.dateOfBirth;
            const parts = dateOfBirth.split("/");

            const partsChars = parts.join("");

            const date = parse(dateOfBirth, "MM/dd/yyyy", new Date());
            const yearsOld = differenceInYears(new Date(), date);

            const isValid = !isNaN(date.getTime()) && partsChars.length === 8;

            if (!isValid) {
              errors.dateOfBirth = "Please enter date in format MM/DD/YYYY";
              return errors;
            }

            if (yearsOld > 18) {
              errors.dateOfBirth = "You cannot add a minor who is over 18";
            }

            if (yearsOld < 0) {
              errors.dateOfBirth = "You must enter a date in the past";
            }

            return errors;
          }}
          onSubmit={(values) => {
            navigation.navigate("MinorDetails", {
              dob: values.dateOfBirth,
            });
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            isValid,
          }) => {
            return (
              <>
                <Container>
                  <ScrollView style={styles.scrollView}>
                    <View style={styles.formInner}>
                      <Heading size="smallcaps" style={styles.formHeader}>
                        Date of Birth
                      </Heading>
                      <TextInputMask
                        type="datetime"
                        options={{ format: "MM/DD/YYYY" }}
                        value={values.dateOfBirth}
                        keyboardType="numbers-and-punctuation"
                        autoFocus
                        placeholder="MM/DD/YYYY"
                        onBlur={handleBlur("dateOfBirth")}
                        onChangeText={(val) => {
                          const parts = val.split("/");

                          // Handle padding the zero for > 2 && < 10
                          if (
                            parts.length === 1 &&
                            parts[0] !== "" &&
                            parts[0] !== "0" &&
                            parts[0] !== "1"
                          ) {
                            parts[0] = padStart(parts[0], 2, "0");
                          }

                          // Handle padding > 3 && < 10
                          if (
                            parts.length === 2 &&
                            parts[1] !== "" &&
                            parseInt(parts[1], 10) > 3
                          ) {
                            parts[1] = padStart(parts[1], 2, "0");
                          }

                          // Add `19` onto year when > 21 is entered
                          if (parts.length === 3 && parts[2].length === 2) {
                            const parsedYear = parseInt(parts[2], 10);

                            if (parsedYear > 21) {
                              parts[2] = `19${parsedYear}`;
                            }
                          }

                          handleChange("dateOfBirth")(parts.join("/"));
                        }}
                        ref={(ref: TextInputMask) => (inputRef.current = ref)}
                        style={styles.input}
                      />
                      {errors &&
                        Object.keys(errors).map((field) => (
                          <Text
                            key={`error-${field}`}
                            style={{ color: colors.red }}
                          >
                            {(errors as any)[field]}
                          </Text>
                        ))}
                    </View>
                  </ScrollView>
                </Container>

                <StickyFooter>
                  <Button
                    title="Next"
                    onPress={handleSubmit}
                    disabled={!isValid}
                  />
                </StickyFooter>
              </>
            );
          }}
        </Formik>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default MinorDateOfBirthInputScreen;

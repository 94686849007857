import React from "react";
import { TouchableOpacity, Text, View, StyleSheet } from "react-native";
import { colors, fonts } from "../../theme";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  button: {
    width: 24,
    height: 24,
    backgroundColor: colors.darkestGrey,
    borderRadius: 24,
    marginRight: 10,
  },
  button__checked: {
    backgroundColor: colors.yellow,
  },
  label: {
    color: colors.white,
    fontFamily: fonts.medium,
    fontSize: 18,
  },
});

export type Props = {
  status?: "checked" | "unchecked";
  onPress: () => void;
  label: string;
  style?: Object;
};

const RadioButton: React.FC<Props> = ({ onPress, label, status, style }) => {
  return (
    <TouchableOpacity onPress={() => onPress()} style={style}>
      <View style={styles.container}>
        <View
          style={[
            styles.button,
            status === "checked" && styles.button__checked,
          ]}
        />
        <Text style={styles.label}>{label}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default RadioButton;

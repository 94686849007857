import React, { FunctionComponent, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { View, Text, StyleSheet, Linking } from "react-native";
import uuid from "react-uuid";
import SignatureCanvas from "react-signature-canvas";
import { PRIVACY_URL, TERMS_URL } from "../../constants";
import { Store, Visit } from "../../types";
import { buildMedicalHistoryPayload } from "../../utils/medicalHistory";
import { ConsentFlowState, reset } from "../../state/consentFlow";
import { pick } from "lodash";
import Uppy from "@uppy/core";
import Transloadit from "@uppy/transloadit";
import dataURLtoBlob from "blueimp-canvas-to-blob";

import Button from "../../components/Button";
import { colors } from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import StickyFooter from "../../components/StickyFooter";
import useUppy from "../../hooks/useUppy";
import { CREATE_VISIT, UPDATE_VISIT, GENERATE_UPLOAD } from "./gql";
import { RootState } from "../../state";

type Params = {
  store: Store;
  visit?: Visit;
};

function buildPayloadFromConsentState(state: ConsentFlowState) {
  const consentAnswers = Object.keys(state.answeredConsentQuestions).map(
    (questionId) => state.answeredConsentQuestions[questionId],
  );
  return {
    customerId: state.subject.id,
    medicalHistory: buildMedicalHistoryPayload(
      state.medicalHistory,
      state.medicalConcerns,
    ),
    consentAnswers,
    services: Object.keys(state.services).map((key) => {
      const service = state.services[key];

      // For existing services we always want to also send over the id
      // but we can't use the id field to verify because the id is present
      // on all services (it is generated on the client for new services)
      const fields = service.isExistingService
        ? ["id", "serviceId", "submittedInformation"]
        : ["serviceId", "submittedInformation"];

      return pick(service, fields);
    }),
  };
}

interface SignatureCaptureProps {
  route: any;
  navigation: any;
  isUnscheduled: boolean;
}

const SignatureCaptureWeb: FunctionComponent<SignatureCaptureProps> = ({
  route,
  navigation,
  isUnscheduled,
}) => {
  const sigCanvasRef = useRef<SignatureCanvas | null>(null);

  const { store, visit }: Params = route.params;
  const dispatch = useDispatch();
  const [validSignature, setValidSignature] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [generateUpload] = useMutation(GENERATE_UPLOAD);
  const consentState = useSelector((state: RootState) => state.consentFlow);

  const [createOrUpdateVisit] = useMutation(
    visit ? UPDATE_VISIT : CREATE_VISIT,
    {
      onCompleted: ({ result }) => {
        if (result.visit) {
          dispatch(reset());
          navigation.replace("VisitConfirmed", {
            visitId: result.visit.id,
          });
        }
      },
    },
  );

  const uppy = useUppy(() =>
    Uppy({ autoProceed: true })
      .use(Transloadit, {
        getAssemblyOptions: async () => {
          setIsUploading(true);
          const { data } = await generateUpload();

          return {
            signature: data.result.signature,
            params: {
              auth: {
                key: data.result.authKey,
                expires: data.result.expires,
              },
              template_id: data.result.templateId,
            },
          };
        },
        waitForEncoding: true,
      })
      .on("transloadit:complete", (assembly: any) => {
        // TODO: revisit the assumption that everything will end with this `compress_image` step
        const file = assembly.results.compress_image[0];

        const input = {
          ...buildPayloadFromConsentState(consentState),
          ...(visit ? { visitId: visit.id } : { storeId: store.id }),
          customerSignatureImageUrl: file.url,
          unscheduled: isUnscheduled,
          consentAnsweredAt: new Date(),
        };

        createOrUpdateVisit({
          variables: { input },
        });

        uppy.reset();
        setIsUploading(false);
      }),
  );

  const uploadFile = async () => {
    const fileName = uuid();

    const canvas = sigCanvasRef.current?.getTrimmedCanvas();
    const dataUrl = canvas?.toDataURL("image/png");
    const blob = dataURLtoBlob(dataUrl);
    const file = new File([blob], fileName as string, { type: "image/png" });

    return uppy.addFile({
      source: "file input",
      name: fileName,
      type: "image/png",
      data: file,
    });
  };

  const handleClear = () => {
    sigCanvasRef.current?.clear();
    setValidSignature(false);
  };

  const handleOnEnd = () => {
    setValidSignature(true);
  };

  const handleConfirm = () => {
    uploadFile();
  };

  return (
    <View style={styles.container}>
      <View style={styles.signatureCanvasContainer}>
        <View style={styles.signatureCanvasInnerContainer}>
          <SignatureCanvas
            ref={sigCanvasRef}
            penColor="black"
            backgroundColor="white"
            onEnd={handleOnEnd}
            canvasProps={{ height: 200, width: 200 }}
          />
          <View style={styles.signatureInstrutionsTextContainer}>
            <Text style={styles.signatureInstrutionsText}>
              Please sign here
            </Text>
          </View>
          <View style={styles.signatureLineContainer}>
            <Text style={styles.xCharacter}>X</Text>
            <View style={styles.signatureLine} />
          </View>
        </View>
      </View>

      <View style={styles.footer}>
        <StickyFooter>
          <Text style={styles.conditionText}>
            By clicking "submit" I agree that the signature shown above will be
            my electronic signature, and when applied on a document at my
            direction, it will be just as legally binding as my pen-and-ink
            signature. I also agree to BodyArt App Inc's{" "}
            <Text
              onLongPress={() => Linking.openURL(TERMS_URL)}
              style={styles.conditionText__Link}
            >
              terms of service
            </Text>{" "}
            and{" "}
            <Text
              onLongPress={() => Linking.openURL(PRIVACY_URL)}
              style={styles.conditionText__Link}
            >
              privacy policy
            </Text>
            , and consent to receiving information electronically.
          </Text>
          <View style={styles.row}>
            <Button
              style={styles.button}
              title={"Clear"}
              onPress={handleClear}
            />
            <Button
              style={styles.button}
              title={"Submit"}
              onPress={handleConfirm}
              disabled={!validSignature}
              isLoading={isUploading}
            />
          </View>
        </StickyFooter>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    backgroundColor: colors.black,
  },
  signatureCanvasContainer: {
    height: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  signatureCanvasInnerContainer: {
    borderRadius: 10,
    height: 200,
    width: 300,
  },
  signatureInstrutionsTextContainer: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    top: 20,
    left: 75,
  },
  signatureInstrutionsText: {
    color: colors.black,
    fontSize: 18,
  },
  signatureLineContainer: {
    position: "absolute",
    width: "100%",
    bottom: 30,
    left: 10,
  },
  xCharacter: {
    fontSize: 18,
  },
  signatureLine: {
    marginHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: "black",
  },
  footer: {
    height: "50%",
    justifyContent: "flex-end",
    padding: 20,
    marginTop: "auto",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    alignItems: "center",
  },
  button: {
    width: 125,
  },
  questionText: {
    color: colors.white,
  },
  conditionText: {
    color: colors.darkGrey,
    textAlign: "center",
  },
  conditionText__Link: {
    color: colors.yellow,
    textDecorationLine: "underline",
    textDecorationColor: colors.yellow,
  },
});

export default SignatureCaptureWeb;

import React, { FunctionComponent } from "react";
import { StyleSheet, View } from "react-native";
import { colors } from "../../theme";
import Header from "../../components/Header";
import { useApolloClient, useMutation } from "@apollo/client";
import { SafeAreaView } from "react-native-safe-area-context";
import AdultForm from "./AdultForm";
import MinorForm from "./MinorForm";
import Link from "../../components/Link";
import { UPDATE_PROFILE, UPDATE_MINOR_PROFILE } from "./gql";
import { InputError } from "../../types";
import { Customer as customerFragment } from "../../fragments";
import { ageInYears } from "../../utils/customer";
import { FETCH_CUSTOMER } from "../ExistingCustomerScreen/gql";

interface Props {
  route: any;
  navigation: any;
}

const EditProfileScreen: FunctionComponent<Props> = ({ navigation, route }) => {
  const { customerId } = route.params;

  const client = useApolloClient();
  const customer = client.readFragment({
    fragment: customerFragment,
    id: `Customer:${customerId}`,
    fragmentName: "Customer",
  });

  const [updateAdultProfile] = useMutation(UPDATE_PROFILE);
  const [updateMinorProfile] = useMutation(UPDATE_MINOR_PROFILE, {
    refetchQueries: [{ query: FETCH_CUSTOMER }],
  });

  const ageInYrs = ageInYears(customer.dateOfBirth);
  const isMinor = ageInYrs && ageInYrs < 18;

  const FormComponent = isMinor ? MinorForm : AdultForm;
  const updateFun = isMinor ? updateMinorProfile : updateAdultProfile;

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.headerContainer}>
        <Header
          title="Edit Profile"
          renderRightControl={() => (
            <Link onPress={() => navigation.goBack()}>Cancel</Link>
          )}
        />
      </View>

      {customer && (
        <FormComponent
          customer={customer}
          onSubmit={async (input, { setFieldError }) => {
            const result = await updateFun({
              variables: {
                input,
              },
            });

            const errors: [InputError] = result.data?.result?.errors || [];

            if (errors.length > 0) {
              errors.forEach(({ field, message }) =>
                setFieldError(field, message),
              );
              return;
            }

            navigation.goBack();
          }}
        />
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  headerContainer: {
    paddingHorizontal: 20,
  },
});

export default EditProfileScreen;

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string, without a time component.
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: any;
};

/** An employee who works in a store */
export type StoreEmployee = {
  __typename?: "StoreEmployee";
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  person: Person;
  services?: Maybe<Array<Maybe<ServiceTypeOffering>>>;
};

export enum VisitStatus {
  Approved = "APPROVED",
  Completed = "COMPLETED",
  ConsentRequested = "CONSENT_REQUESTED",
  Denied = "DENIED",
  Draft = "DRAFT",
  Pending = "PENDING",
  Reviewing = "REVIEWING",
  Submitted = "SUBMITTED",
}

export type StoreEmployeeConnection = {
  __typename?: "StoreEmployeeConnection";
  edges?: Maybe<Array<Maybe<StoreEmployeeEdge>>>;
  pageInfo: PageInfo;
};

export type CustomerLocationInput = {
  lat: Scalars["Float"];
  lng: Scalars["Float"];
  radiusInMiles?: Maybe<Scalars["Int"]>;
};

export type StoreEdge = {
  __typename?: "StoreEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<Store>;
};

export type RequestGuestAccessInput = {
  dateOfBirth: Scalars["Date"];
  emailAddress: Scalars["String"];
  phoneNumber: Scalars["String"];
};

export type SetPhoneNumberInput = {
  phoneNumber: Scalars["String"];
};

/** A customer to be used in mutation responses */
export type CustomerMutationResponse = {
  __typename?: "CustomerMutationResponse";
  customer?: Maybe<Customer>;
  errors?: Maybe<Array<InputError>>;
};

export type AddressInput = {
  addressLineOne: Scalars["String"];
  addressLineTwo?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  country: Scalars["String"];
  state: Scalars["String"];
  zipCode: Scalars["String"];
};

/** A category containing one or more consent questions */
export type ConsentQuestionCategory = {
  __typename?: "ConsentQuestionCategory";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  /** Returns a list of consent questions for this category */
  questions?: Maybe<Array<ConsentQuestion>>;
};

export type ProcedureInventorySelectionValue = {
  __typename?: "ProcedureInventorySelectionValue";
  name: Scalars["String"];
  value: Scalars["String"];
};

export type Node = {
  /** The id of the object. */
  id: Scalars["ID"];
};

export enum ServiceTypeOffering {
  PermanentMakeup = "PERMANENT_MAKEUP",
  Piercing = "PIERCING",
  Tattoo = "TATTOO",
}

export type ActivityMutationResponse = {
  __typename?: "ActivityMutationResponse";
  activity?: Maybe<Activity>;
  errors?: Maybe<Array<InputError>>;
};

export type AttachServiceToVisitInput = {
  additionalInformation?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  serviceId: Scalars["ID"];
  submittedInformation?: Maybe<Array<SubmittedInformationForServiceInput>>;
};

export type GuestMutationResponse = {
  __typename?: "GuestMutationResponse";
  errors?: Maybe<Array<InputError>>;
  guest?: Maybe<Guest>;
};

export type UpdateMinorInput = {
  emailAddress?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  minorId: Scalars["ID"];
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type FilterStoreService = {
  minimumAge?: Maybe<Scalars["Int"]>;
};

export type SocialLoginInput = {
  /** Token */
  token: Scalars["String"];
  /** Type of Login */
  type: SocialLoginType;
};

/** An address, common to entities in the system */
export type Address = {
  __typename?: "Address";
  addressLineOne: Scalars["String"];
  addressLineTwo?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  country: Scalars["String"];
  state: Scalars["String"];
  zipCode: Scalars["String"];
};

export enum ConsentQuestionType {
  Boolean = "BOOLEAN",
  Text = "TEXT",
  YesNo = "YES_NO",
}

export type UpdateMedicalHistoryInput = {
  customerId?: Maybe<Scalars["ID"]>;
  medicalHistory: MedicalHistoryInput;
};

export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type ActivityEdge = {
  __typename?: "ActivityEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<Activity>;
};

/** A customer user, otherwise known as a customer */
export type Customer = Node & {
  __typename?: "Customer";
  /** All activities (notifications) for the customer */
  activities?: Maybe<ActivityConnection>;
  /** The current address for this customer */
  address?: Maybe<Address>;
  dateOfBirth?: Maybe<Scalars["Date"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  idImageUrls?: Maybe<Array<Scalars["String"]>>;
  insertedAt: Scalars["DateTime"];
  internalId: Scalars["ID"];
  isMinor: Scalars["Boolean"];
  lastName?: Maybe<Scalars["String"]>;
  /**
   * The stored medical history for this customer. Can only be accessed by the current customer.
   * Reads to this field will trigger an audit to be generated.
   */
  medicalHistory?: Maybe<MedicalHistory>;
  /** Any minor customers attached to this customer */
  minors?: Maybe<Array<Customer>>;
  name?: Maybe<Scalars["String"]>;
  /** The parent/guardian for this minor */
  parent?: Maybe<Customer>;
  phoneNumber?: Maybe<Scalars["String"]>;
  remoteId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** A customer user, otherwise known as a customer */
export type CustomerActivitiesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

/** A notification this customer has received */
export type Activity = {
  __typename?: "Activity";
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  readAt?: Maybe<Scalars["DateTime"]>;
  store: Store;
  type: ActivityType;
  updatedAt: Scalars["DateTime"];
  visit?: Maybe<Visit>;
};

export type InputError = {
  __typename?: "InputError";
  /** The originating field for the error */
  field: Scalars["String"];
  /** A description of the error */
  message: Scalars["String"];
  /** Validation type */
  type?: Maybe<ErrorType>;
};

/** A field to be captured during procedure selection */
export type ServiceInformationField =
  | ServiceInformationOptionField
  | ServiceInformationSelectField
  | ServiceInformationTextField;

export type SubmittedInformationForServiceInput = {
  label: Scalars["String"];
  name: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

/** What kind of text field is it? */
export enum ServiceInformationTextFieldType {
  Text = "TEXT",
  Textarea = "TEXTAREA",
}

/** A visit that the customer has or will perform */
export type Visit = Node & {
  __typename?: "Visit";
  consentAnsweredAt?: Maybe<Scalars["DateTime"]>;
  /** The questions answered during consent for the visit */
  consentAnswers?: Maybe<Array<AnsweredConsentQuestion>>;
  customer: Customer;
  customerInformation?: Maybe<VisitCustomerInformation>;
  customerSignatureImageUrl?: Maybe<Scalars["String"]>;
  denyReason?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  idImageUrls?: Maybe<Array<Scalars["String"]>>;
  insertedAt: Scalars["DateTime"];
  /** The medical history submitted with this visit */
  medicalHistory?: Maybe<MedicalHistory>;
  parentCustomerInformation?: Maybe<VisitCustomerInformation>;
  scheduledFor?: Maybe<Scalars["DateTime"]>;
  /** All of the services associated with this visit */
  services?: Maybe<Array<VisitService>>;
  status?: Maybe<VisitStatus>;
  store: Store;
  updatedAt: Scalars["DateTime"];
};

export type VisitEdge = {
  __typename?: "VisitEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<Visit>;
};

/** An icon on a service */
export type ServiceIcon = {
  __typename?: "ServiceIcon";
  selectedUrl: Scalars["String"];
  unselectedUrl: Scalars["String"];
};

export type RootMutationType = {
  __typename?: "RootMutationType";
  /** Adds a minor to the current customers account, only if they are > 18 themselves */
  addMinor?: Maybe<CustomerMutationResponse>;
  /** Authenticates a guest by the id provided and the totp code */
  authenticateGuest?: Maybe<SessionMutationResponse>;
  /** Creates a new visit for the logged in customer */
  createVisit: VisitMutationResponse;
  /** Exchanges a refresh token for a new JWT */
  exchangeRefreshToken?: Maybe<Session>;
  /** Generates a set of upload params for the given request */
  generateUpload?: Maybe<UploadResponse>;
  /** Login the customer with the supplied credentials */
  login?: Maybe<SessionMutationResponse>;
  /**
   * Logout the current customer, invalidating their refresh token in the process.
   *
   * Note: you must be authenticated to call this endpoint.
   */
  logout?: Maybe<GenericSuccessResponse>;
  /** Marks the given activity as read */
  markActivityAsRead?: Maybe<ActivityMutationResponse>;
  /** Registers a new device for the viewer */
  registerDevice: GenericSuccessResponse;
  /**
   * Authenticates a guest by finding an existing customer, or creating a new
   * customer and sending a totp code
   */
  requestGuestAccess?: Maybe<GuestMutationResponse>;
  /** Request a password reset email */
  requestPasswordReset?: Maybe<GenericSuccessResponse>;
  /** Resets the password given using the token provided. */
  resetPassword?: Maybe<SessionMutationResponse>;
  /** Sends the minor an invite link to create their own account on the platform */
  sendMinorInvite?: Maybe<CustomerMutationResponse>;
  /**
   * Sets the customers date of birth. Can only be issued if the current date of birth
   * is empty
   */
  setDateOfBirth: CustomerMutationResponse;
  /** Updates the id images with the set provided */
  setIdImages: CustomerMutationResponse;
  /** Sets the phone number on the customers profile */
  setPhoneNumber: CustomerMutationResponse;
  /** Allows the customer to sign up for a new Bodyart account */
  signUp?: Maybe<SessionMutationResponse>;
  /** Sign up via invite */
  signUpViaInvite?: Maybe<SessionMutationResponse>;
  socialLogin?: Maybe<SessionMutationResponse>;
  /**
   * Updates the medical information on file. Please note, this mutation
   * can be called for a minor that the parent is connected to or for the
   * current viewer.
   */
  updateMedicalHistory: CustomerMutationResponse;
  /** Updates the given minor, but only if the logged in viewer is a parent/guardian of the minor */
  updateMinor?: Maybe<CustomerMutationResponse>;
  /** Updates the customers profile information */
  updateProfileInformation: CustomerMutationResponse;
  /** Updates an existing visit for the customer */
  updateVisit: VisitMutationResponse;
};

export type RootMutationTypeAddMinorArgs = {
  input?: Maybe<AddMinorInput>;
};

export type RootMutationTypeAuthenticateGuestArgs = {
  input: AuthenticateGuestInput;
};

export type RootMutationTypeCreateVisitArgs = {
  input: CreateVisitInput;
};

export type RootMutationTypeExchangeRefreshTokenArgs = {
  refreshToken: Scalars["String"];
};

export type RootMutationTypeLoginArgs = {
  input: LoginInput;
};

export type RootMutationTypeMarkActivityAsReadArgs = {
  input: MarkActivityAsReadInput;
};

export type RootMutationTypeRegisterDeviceArgs = {
  input?: Maybe<RegisterDeviceInput>;
};

export type RootMutationTypeRequestGuestAccessArgs = {
  input: RequestGuestAccessInput;
};

export type RootMutationTypeRequestPasswordResetArgs = {
  emailAddress: Scalars["String"];
};

export type RootMutationTypeResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type RootMutationTypeSendMinorInviteArgs = {
  input?: Maybe<SendMinorInviteInput>;
};

export type RootMutationTypeSetDateOfBirthArgs = {
  input: SetDateOfBirthInput;
};

export type RootMutationTypeSetIdImagesArgs = {
  input: SetIdImagesInput;
};

export type RootMutationTypeSetPhoneNumberArgs = {
  input: SetPhoneNumberInput;
};

export type RootMutationTypeSignUpArgs = {
  input: SignUpInput;
};

export type RootMutationTypeSignUpViaInviteArgs = {
  input: SignUpViaInviteInput;
};

export type RootMutationTypeSocialLoginArgs = {
  input: SocialLoginInput;
};

export type RootMutationTypeUpdateMedicalHistoryArgs = {
  input: UpdateMedicalHistoryInput;
};

export type RootMutationTypeUpdateMinorArgs = {
  input?: Maybe<UpdateMinorInput>;
};

export type RootMutationTypeUpdateProfileInformationArgs = {
  input: UpdateProfileInformationInput;
};

export type RootMutationTypeUpdateVisitArgs = {
  input: UpdateVisitInput;
};

/** A requested or performed service in the visit */
export type VisitService = {
  __typename?: "VisitService";
  additionalInformation?: Maybe<Scalars["String"]>;
  aftercareInstructions?: Maybe<Scalars["String"]>;
  /** The artist assigned to perform this procedure */
  assignedEmployee?: Maybe<StoreEmployee>;
  denyReason?: Maybe<Scalars["String"]>;
  externalAftercareInstructionsUrl?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  /**
   * Selection for inventory when the inventory selections are not being
   * used (structured inventory)
   */
  inventoryNotes?: Maybe<Scalars["String"]>;
  /** Inventory associated with this procedure */
  inventorySelections?: Maybe<Array<Maybe<ProcedureInventorySelection>>>;
  /** The service to be performed in the visit */
  service: Service;
  submittedInformation?: Maybe<Array<SubmittedInformationForVisitService>>;
  updatedAt: Scalars["DateTime"];
};

export enum InviteType {
  Minor = "MINOR",
  Visit = "VISIT",
}

export type SignUpInput = {
  dateOfBirth?: Maybe<Scalars["Date"]>;
  emailAddress: Scalars["String"];
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
};

export type ResetPasswordInput = {
  /** The new password */
  password: Scalars["String"];
  /** The confirmation of the new password */
  passwordConfirmation: Scalars["String"];
  /** The reset password token */
  token: Scalars["String"];
};

/** A select piece of inventory for a procedure */
export type ProcedureInventorySelection = {
  __typename?: "ProcedureInventorySelection";
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  inventoryItem: StoreInventoryItem;
  selectedValues?: Maybe<Array<Maybe<ProcedureInventorySelectionValue>>>;
  updatedAt: Scalars["DateTime"];
};

export type ServiceInformationOptionField = {
  __typename?: "ServiceInformationOptionField";
  isRequired: Scalars["Boolean"];
  label: Scalars["String"];
  name: Scalars["String"];
  options?: Maybe<Array<Scalars["String"]>>;
};

export type UpdateVisitInput = {
  consentAnsweredAt?: Maybe<Scalars["DateTime"]>;
  consentAnswers?: Maybe<Array<AnsweredConsentQuestionInput>>;
  customerId: Scalars["ID"];
  customerSignatureImageUrl?: Maybe<Scalars["String"]>;
  idImageUrls?: Maybe<Array<Scalars["String"]>>;
  medicalHistory: MedicalHistoryInput;
  services: Array<AttachServiceToVisitInput>;
  unscheduled?: Maybe<Scalars["Boolean"]>;
  visitId: Scalars["ID"];
};

export type SetIdImagesInput = {
  idImageUrls?: Maybe<Array<Scalars["String"]>>;
};

export type AddMinorInput = {
  dateOfBirth: Scalars["Date"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

/** A type of services that the store offers */
export type ServiceType = {
  __typename?: "ServiceType";
  /** The available categories for this service type (ordered) */
  categories?: Maybe<Array<ServiceTypeCategory>>;
  id: Scalars["ID"];
  index: Scalars["Int"];
  insertedAt: Scalars["DateTime"];
  name: Scalars["String"];
  type: ServiceTypeOffering;
  updatedAt: Scalars["DateTime"];
};

export type UploadResponse = {
  __typename?: "UploadResponse";
  authKey: Scalars["String"];
  expires: Scalars["String"];
  signature: Scalars["String"];
  templateId: Scalars["String"];
};

export type VisitConnection = {
  __typename?: "VisitConnection";
  edges?: Maybe<Array<Maybe<VisitEdge>>>;
  pageInfo: PageInfo;
};

/** A category of options within a service type */
export type ServiceTypeCategory = {
  __typename?: "ServiceTypeCategory";
  icon?: Maybe<ServiceIcon>;
  id: Scalars["ID"];
  index: Scalars["Int"];
  insertedAt: Scalars["DateTime"];
  name: Scalars["String"];
  /** The available services for this category (ordered) */
  services?: Maybe<Array<Service>>;
  updatedAt: Scalars["DateTime"];
};

/** A category of options within a service type */
export type ServiceTypeCategoryServicesArgs = {
  filterBy?: Maybe<FilterStoreService>;
};

export type VisitFilters = {
  status?: Maybe<VisitFilterStatusType>;
};

/** Represents a generic 'success' response */
export type GenericSuccessResponse = {
  __typename?: "GenericSuccessResponse";
  success: Scalars["Boolean"];
};

export type SignUpViaInviteInput = {
  dateOfBirth?: Maybe<Scalars["Date"]>;
  emailAddress: Scalars["String"];
  inviteToken: Scalars["String"];
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
};

export type StoreConnection = {
  __typename?: "StoreConnection";
  edges?: Maybe<Array<Maybe<StoreEdge>>>;
  pageInfo: PageInfo;
};

export type RegisterDeviceInput = {
  pushToken: Scalars["String"];
};

export type LoginInput = {
  /** Email address */
  emailAddress: Scalars["String"];
  /** Password */
  password: Scalars["String"];
};

export type ServiceInformationSelectField = {
  __typename?: "ServiceInformationSelectField";
  isRequired: Scalars["Boolean"];
  label: Scalars["String"];
  name: Scalars["String"];
  options?: Maybe<Array<Scalars["String"]>>;
};

/** A store location for a piercing and or tattoo shop */
export type Store = Node & {
  __typename?: "Store";
  /** The associated address for this store */
  address: Address;
  /** Returns a paginated list of artists who work at this store */
  artists?: Maybe<StoreEmployeeConnection>;
  /** A list of the consent questions to be answered for this user at the store */
  consentQuestionCategories?: Maybe<Array<ConsentQuestionCategory>>;
  /** A list of the consent questions to be answered for this user at the store */
  consentQuestions: Array<ConsentQuestion>;
  distanceInMiles?: Maybe<Scalars["Float"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  headerImageUrl?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  name: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  profileImageUrl?: Maybe<Scalars["String"]>;
  /** A list of service types offered at this store */
  serviceTypes: Array<ServiceType>;
  updatedAt: Scalars["DateTime"];
  website?: Maybe<Scalars["String"]>;
};

/** A store location for a piercing and or tattoo shop */
export type StoreArtistsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

/**
 * The medical history that the customer either added to their
 * profile or submitted in their most recent consent form.
 */
export type MedicalHistory = {
  __typename?: "MedicalHistory";
  additionalNotes?: Maybe<Scalars["String"]>;
  hasAllergyToProducts: Scalars["Boolean"];
  hasBloodConditions?: Maybe<Scalars["Boolean"]>;
  hasHistoryOfComplications?: Maybe<Scalars["Boolean"]>;
  hasIndicatedMedicationUse: Scalars["Boolean"];
  hasSkinCondition: Scalars["Boolean"];
  isGenerallyHealthy?: Maybe<Scalars["Boolean"]>;
  isProneToFainting?: Maybe<Scalars["Boolean"]>;
  medicalConditions?: Maybe<Array<Scalars["String"]>>;
};

/** An invite to the bodyart app */
export type Invite = {
  __typename?: "Invite";
  customerInformation?: Maybe<InviteCustomerInformation>;
  insertedAt: Scalars["DateTime"];
  inviteeName?: Maybe<Scalars["String"]>;
  token: Scalars["String"];
  type: InviteType;
  updatedAt: Scalars["DateTime"];
};

/** A service that the store offers */
export type Service = {
  __typename?: "Service";
  aftercareInstructions?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  downsizeTime?: Maybe<Scalars["String"]>;
  externalAftercareInstructionsUrl?: Maybe<Scalars["String"]>;
  healingTime?: Maybe<Scalars["String"]>;
  icon?: Maybe<ServiceIcon>;
  id: Scalars["ID"];
  /**
   * A list of fields that should be shown to the customer to collect information
   * about this service when they are stipulating a procedure in their visit.
   */
  informationFields?: Maybe<Array<ServiceInformationField>>;
  insertedAt: Scalars["DateTime"];
  minimumAgeRequirement?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  requiresAdditionalInformation: Scalars["Boolean"];
  serviceType: ServiceType;
  updatedAt: Scalars["DateTime"];
};

export type ActivityConnection = {
  __typename?: "ActivityConnection";
  edges?: Maybe<Array<Maybe<ActivityEdge>>>;
  pageInfo: PageInfo;
};

/** A person who works at a store */
export type Person = {
  __typename?: "Person";
  emailAddress: Scalars["String"];
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  name?: Maybe<Scalars["String"]>;
  profileImageUrl?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export enum SocialLoginType {
  Apple = "APPLE",
  Facebook = "FACEBOOK",
  Google = "GOOGLE",
}

/** Represents a customer session, including a token, refresh token and a currrent customer */
export type Session = {
  __typename?: "Session";
  /** The authed customer */
  customer: Customer;
  /** Long lived refresh token to be persisted */
  refreshToken: Scalars["String"];
  /** JWT for the authed customer */
  token: Scalars["String"];
};

/** A question asked to a customer in the consent flow */
export type ConsentQuestion = {
  __typename?: "ConsentQuestion";
  flagVisitWhenEqual?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isRequired?: Maybe<Scalars["Boolean"]>;
  question: Scalars["String"];
  type?: Maybe<ConsentQuestionType>;
};

export type StoreEmployeeEdge = {
  __typename?: "StoreEmployeeEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<StoreEmployee>;
};

export type SubmittedInformationForVisitService = {
  __typename?: "SubmittedInformationForVisitService";
  label: Scalars["String"];
  name: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type RootQueryType = {
  __typename?: "RootQueryType";
  /** Retrieves an invite from a token if the invite exists */
  inviteByToken?: Maybe<Invite>;
  /** Returns the given node by its global id */
  node?: Maybe<Node>;
  /** Fetches a store by its short link */
  storeByShortLink?: Maybe<Store>;
  /** Fetches all stores in Bodyart */
  stores?: Maybe<StoreConnection>;
  /** Takes a reset password token and returns whether or not it's currently valid */
  validateResetPasswordToken?: Maybe<GenericSuccessResponse>;
  /** The currently authenticated viewer */
  viewer: Customer;
  /** A paginated list of visits for the authenticated viewer */
  visits?: Maybe<VisitConnection>;
};

export type RootQueryTypeInviteByTokenArgs = {
  token: Scalars["String"];
};

export type RootQueryTypeNodeArgs = {
  id: Scalars["ID"];
};

export type RootQueryTypeStoreByShortLinkArgs = {
  shortLink: Scalars["String"];
};

export type RootQueryTypeStoresArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filterBy?: Maybe<StoreFilters>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type RootQueryTypeValidateResetPasswordTokenArgs = {
  token: Scalars["String"];
};

export type RootQueryTypeVisitsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filterBy?: Maybe<VisitFilters>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type SetDateOfBirthInput = {
  dateOfBirth: Scalars["Date"];
};

export type StoreFilters = {
  location?: Maybe<CustomerLocationInput>;
  searchTerm?: Maybe<Scalars["String"]>;
  serviceTypes?: Maybe<Array<ServiceTypeOffering>>;
};

export type MedicalHistoryInput = {
  additionalNotes?: Maybe<Scalars["String"]>;
  hasAllergyToProducts: Scalars["Boolean"];
  hasBloodConditions?: Maybe<Scalars["Boolean"]>;
  hasHistoryOfComplications?: Maybe<Scalars["Boolean"]>;
  hasIndicatedMedicationUse: Scalars["Boolean"];
  hasSkinCondition: Scalars["Boolean"];
  isGenerallyHealthy?: Maybe<Scalars["Boolean"]>;
  isProneToFainting?: Maybe<Scalars["Boolean"]>;
  medicalConditions?: Maybe<Array<Scalars["String"]>>;
};

export type AnsweredConsentQuestionInput = {
  answer?: Maybe<Scalars["Boolean"]>;
  category: Scalars["String"];
  categoryId: Scalars["ID"];
  isFlagged?: Maybe<Scalars["Boolean"]>;
  question: Scalars["String"];
  questionId: Scalars["ID"];
  value: Scalars["String"];
};

export type ServiceInformationTextField = {
  __typename?: "ServiceInformationTextField";
  isRequired: Scalars["Boolean"];
  label: Scalars["String"];
  name: Scalars["String"];
  placeholder: Scalars["String"];
  type: ServiceInformationTextFieldType;
};

/** The various kinds of input validation errors that can occur */
export enum ErrorType {
  Confirmation = "CONFIRMATION",
  Exclusion = "EXCLUSION",
  Format = "FORMAT",
  Inclusion = "INCLUSION",
  Length = "LENGTH",
  Number = "NUMBER",
  Other = "OTHER",
  Required = "REQUIRED",
  Subset = "SUBSET",
}

export type CreateVisitInput = {
  consentAnsweredAt?: Maybe<Scalars["DateTime"]>;
  consentAnswers?: Maybe<Array<AnsweredConsentQuestionInput>>;
  customerId: Scalars["ID"];
  customerSignatureImageUrl?: Maybe<Scalars["String"]>;
  idImageUrls?: Maybe<Array<Scalars["String"]>>;
  medicalHistory: MedicalHistoryInput;
  services: Array<AttachServiceToVisitInput>;
  storeId: Scalars["ID"];
  unscheduled?: Maybe<Scalars["Boolean"]>;
};

/** Customer fields stored when submitting the visit */
export type VisitCustomerInformation = {
  __typename?: "VisitCustomerInformation";
  address?: Maybe<Address>;
  dateOfBirth?: Maybe<Scalars["Date"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  idImageUrls?: Maybe<Array<Scalars["String"]>>;
  lastName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type MarkActivityAsReadInput = {
  activityId: Scalars["ID"];
};

/** An item in the inventory for the store */
export type StoreInventoryItem = {
  __typename?: "StoreInventoryItem";
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  name: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type AnsweredConsentQuestion = {
  __typename?: "AnsweredConsentQuestion";
  answer?: Maybe<Scalars["Boolean"]>;
  category?: Maybe<Scalars["String"]>;
  categoryId?: Maybe<Scalars["ID"]>;
  isFlagged?: Maybe<Scalars["Boolean"]>;
  question: Scalars["String"];
  questionId: Scalars["ID"];
  value?: Maybe<Scalars["String"]>;
};

/**
 * A minimal set of information about this customer to pre-fill
 * forms from.
 */
export type InviteCustomerInformation = {
  __typename?: "InviteCustomerInformation";
  dateOfBirth?: Maybe<Scalars["Date"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
};

export type AuthenticateGuestInput = {
  guestId: Scalars["ID"];
  totpCode: Scalars["String"];
};

/** A session, but with errors attached */
export type SessionMutationResponse = {
  __typename?: "SessionMutationResponse";
  errors?: Maybe<Array<InputError>>;
  session?: Maybe<Session>;
};

/** A partial representation of a customer */
export type Guest = {
  __typename?: "Guest";
  id: Scalars["ID"];
};

export enum ActivityType {
  VisitApproved = "VISIT_APPROVED",
  VisitConsentRequested = "VISIT_CONSENT_REQUESTED",
  VisitDenied = "VISIT_DENIED",
  VisitSent = "VISIT_SENT",
}

export type SendMinorInviteInput = {
  emailAddress: Scalars["String"];
  minorId: Scalars["ID"];
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type VisitMutationResponse = {
  __typename?: "VisitMutationResponse";
  errors?: Maybe<Array<InputError>>;
  visit?: Maybe<Visit>;
};

export type UpdateProfileInformationInput = {
  address: AddressInput;
  emailAddress?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  idImageUrls?: Maybe<Array<Maybe<Scalars["String"]>>>;
  lastName: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
};

export enum VisitFilterStatusType {
  Past = "PAST",
  Upcoming = "UPCOMING",
}

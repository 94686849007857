import { gql } from "@apollo/client";

export const CONSENT_QUESTIONS = gql`
  query ConsentQuestionsForStore($storeId: ID!) {
    store: node(id: $storeId) {
      ... on Store {
        id
        consentQuestionCategories {
          id
          name
          description
          questions {
            id
            question
            isRequired
            type
            flagVisitWhenEqual
          }
        }

        consentQuestions {
          id
          question
        }
      }
    }
  }
`;

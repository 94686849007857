import React from "react";
import { StyleSheet, View, Text } from "react-native";
import { colors, fonts } from "../../theme";

const styles = StyleSheet.create({
  title: {
    fontFamily: fonts.regular,
    textTransform: "uppercase",
    fontSize: 16,
    lineHeight: 18,
    color: colors.white,
    letterSpacing: 1,
    textAlign: "center",
  },
});

type Props = {};

const ScreenHeaderTitle: React.FC<Props> = ({ children }) => {
  return <Text style={styles.title}>{children}</Text>;
};

export default ScreenHeaderTitle;

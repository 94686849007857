import React from "react";
import { StyleSheet, View, Image } from "react-native";
import { colors } from "../../theme";
import { summarizeAddress } from "../../utils/address";
import { Visit } from "../../types";
import Heading from "../../components/Heading";
import Text from "../../components/Text";
import VisitServicesSummary from "../../components/VisitServicesSummary";

const styles = StyleSheet.create({
  storeCard: {
    marginVertical: 20,
    borderRadius: 20,
    backgroundColor: colors.white,
    overflow: "hidden",
  },
  storeCard__Header: {
    backgroundColor: colors.secondaryMidGrey,
    height: 140,
    justifyContent: "center",
    alignItems: "center",
  },
  storeCard__HeaderImage: {
    width: "100%",
    height: "100%",
  },
  storeCard__Content: {
    padding: 20,
  },
  storeCard__StoreInformation: {
    borderBottomColor: colors.darkestGrey,
    borderBottomWidth: 1,
    paddingBottom: 20,
  },
  storeCard__StoreName: {
    marginRight: 20,
    marginBottom: 4,
  },
  storeCard__StoreAddress: {
    color: colors.darkGrey,
  },
  storeCard__Chin: {
    paddingTop: 20,
  },
  storeCard__ProcedureHeader: {
    color: colors.midGrey,
    marginBottom: 10,
  },
});

type Props = {
  visit: Visit;
};

const VisitSummaryCard: React.FC<Props> = ({ visit }) => (
  <View style={styles.storeCard}>
    <View style={styles.storeCard__Header}>
      {visit?.store.headerImageUrl ? (
        <Image
          source={{ uri: visit.store.headerImageUrl }}
          style={styles.storeCard__HeaderImage}
        />
      ) : (
        <Image
          source={require("../../../assets/illustrations/no-store/no-store.png")}
          style={{ width: 90, height: 90 }}
        />
      )}
    </View>
    <View style={styles.storeCard__Content}>
      <View style={styles.storeCard__StoreInformation}>
        <Heading size="l" style={styles.storeCard__StoreName}>
          {visit.store.name}
        </Heading>
        <Text style={styles.storeCard__StoreAddress}>
          {summarizeAddress(visit.store.address)}
        </Text>
      </View>
      <View style={styles.storeCard__Chin}>
        <Heading size="label" style={styles.storeCard__ProcedureHeader}>
          Procedures
        </Heading>

        <VisitServicesSummary
          services={visit.services ? visit.services : []}
          variant="m"
        />
      </View>
    </View>
  </View>
);

export default VisitSummaryCard;

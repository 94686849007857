import React, { useEffect, useState } from "react";
import AppLoading from "expo-app-loading";
import { Asset } from "expo-asset";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import * as Segment from "./analytics";

import {
  Poppins_400Regular,
  Poppins_500Medium,
  Poppins_600SemiBold,
  Poppins_700Bold,
} from "@expo-google-fonts/poppins";
import { RobotoMono_400Regular } from "@expo-google-fonts/roboto-mono";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { ApolloProvider } from "@apollo/client";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import client from "./graphql";
import store, { persistor } from "./state";
import AppNavigator from "./AppNavigator";
import "./firebase/config";
import { FirebaseAuthProvider } from "./firebase/AuthProvider";

type Props = {
  skipLoadingScreen: boolean;
};

const App: React.FC<Props> = ({ skipLoadingScreen }) => {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    SplashScreen.preventAutoHideAsync();
  }, []);

  const loadResourcesAsync = async () => {
    await Promise.all([
      Asset.loadAsync([]),
      Font.loadAsync({
        Poppins_400Regular,
        Poppins_500Medium,
        Poppins_600SemiBold,
        Poppins_700Bold,
        RobotoMono_400Regular,
      }),
    ]);
  };

  const handleLoadingError = () => {
    console.error("An erroring happened with loading");
  };

  const handleFinishLoading = () => {
    Segment.track("Application Opened");
    setLoadingComplete(true);
  };

  if (!isLoadingComplete && !skipLoadingScreen) {
    return (
      <AppLoading
        startAsync={loadResourcesAsync}
        onError={handleLoadingError}
        onFinish={handleFinishLoading}
        autoHideSplash={false}
      />
    );
  }

  return (
    <SafeAreaProvider>
      <ApolloProvider client={client}>
        <ActionSheetProvider>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <FirebaseAuthProvider>
                <AppNavigator />
              </FirebaseAuthProvider>
            </PersistGate>
          </ReduxProvider>
        </ActionSheetProvider>
      </ApolloProvider>
    </SafeAreaProvider>
  );
};

export default App;

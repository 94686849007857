import React from "react";
import { StyleSheet, ScrollView, View } from "react-native";
import Header from "../../components/Header";
import Button from "../../components/Button";
import KeyboardAvoidingView from "../../components/KeyboardAvoidingView";
import { colors } from "../../theme";
import {
  ConsentQuestion,
  ConsentQuestionCategory,
  ConsentQuestionType,
} from "../../types";
import {
  BooleanQuestion,
  TextQuestion,
  YesNoQuestion,
} from "./ConsentQuestion";
import {
  AnsweredConsentQuestion,
  AnsweredConsentQuestions,
} from "../../state/consentFlow";
import Container from "../../components/Container";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  formContainer: {
    flex: 1,
    paddingHorizontal: 20,
  },
  questions: {
    marginTop: 20,
  },
  question: {
    marginBottom: 20,
    paddingBottom: 20,
    borderBottomColor: colors.darkestGrey,
    borderBottomWidth: 1,
  },
  question__isLast: {
    borderBottomColor: "transparent",
  },
  questionText: {
    marginLeft: 20,
    color: colors.white,
  },
  footer: {
    padding: 20,
    marginTop: "auto",
    borderTopColor: colors.darkestGrey,
    borderTopWidth: 1,
  },
});

type Props = {
  category: ConsentQuestionCategory;
  setAnswer: (questionId: string, attrs: AnsweredConsentQuestion) => void;
  answers: AnsweredConsentQuestions;
  onNextPress: () => void;
  isLastQuestion: boolean;
};

const QuestionType = {
  [ConsentQuestionType.Boolean]: BooleanQuestion,
  [ConsentQuestionType.Text]: TextQuestion,
  [ConsentQuestionType.YesNo]: YesNoQuestion,
};

function determineIfValid(
  questions: ConsentQuestion[],
  answers: AnsweredConsentQuestions,
) {
  return questions
    .filter((q) => q.isRequired)
    .every((q) => {
      switch (q.type) {
        case ConsentQuestionType.Boolean: {
          return answers[q.id]?.value === "true";
        }
        case ConsentQuestionType.Text: {
          return answers[q.id]?.value && answers[q.id]?.value !== "";
        }
        case ConsentQuestionType.YesNo: {
          return answers[q.id]?.value;
        }
      }
    });
}

const ConsentCategory: React.FC<Props> = ({
  category,
  answers,
  setAnswer,
  onNextPress,
  isLastQuestion,
}) => {
  const questions = category.questions || [];
  const totalQuestions = questions.length;
  const isValid = determineIfValid(questions, answers);
  const scrollRef = React.useRef<ScrollView | any>();

  return (
    <KeyboardAvoidingView style={styles.container}>
      <Container>
        <ScrollView style={styles.formContainer} ref={scrollRef}>
          <Header
            title={category.name}
            description={category.description as string | undefined}
          />

          <View style={styles.questions}>
            {questions.map((consentQuestion, idx) => {
              const Question =
                QuestionType[consentQuestion.type as ConsentQuestionType];

              const value = answers[consentQuestion.id]?.value;

              return (
                <View
                  key={consentQuestion.id}
                  style={[
                    styles.question,
                    totalQuestions === idx + 1 && styles.question__isLast,
                  ]}
                >
                  <Question
                    isRequired={consentQuestion.isRequired as boolean}
                    onChange={(value) => {
                      const normalizedValue = value.toString();
                      const attrs = {
                        categoryId: category.id,
                        category: category.name,
                        questionId: consentQuestion.id,
                        question: consentQuestion.question,
                        value: normalizedValue,
                        isFlagged:
                          normalizedValue ===
                          consentQuestion.flagVisitWhenEqual,
                      };

                      setAnswer(consentQuestion.id, attrs);
                    }}
                    question={consentQuestion.question}
                    value={value}
                  />
                </View>
              );
            })}
          </View>
        </ScrollView>
      </Container>
      <View style={styles.footer}>
        <Button
          title={isLastQuestion ? "Next: Review" : "Next"}
          onPress={() => {
            scrollRef.current?.scrollTo({ x: 0, y: 0, animated: true });
            onNextPress();
          }}
          disabled={!isValid}
        />
      </View>
    </KeyboardAvoidingView>
  );
};

export default ConsentCategory;

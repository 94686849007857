import React from "react";
import { StyleSheet, ScrollView, Text } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigation, useRoute } from "@react-navigation/native";

import KeyboardAvoidingView from "../../components/KeyboardAvoidingView";
import TextInputField from "../../components/TextInputField";
import Button from "../../components/Button";
import { colors, fonts } from "../../theme";
import { InputError } from "../../types";
import { NewVisitFlowScreens } from "../../constants";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { SET_DOB } from "../DateOfBirthInputScreen/gql";
import { useFirebaseAuth } from "../../firebase/AuthProvider";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  formContainer: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  scrollViewInner: {
    paddingHorizontal: 20,
    marginTop: 20,
  },
  formHeader: {
    fontFamily: fonts.medium,
    color: colors.white,
    textTransform: "uppercase",
    marginBottom: 5,
  },
});

const Schema = Yup.object().shape({
  emailAddress: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Your password must be longer than 6 characters."),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), ""], "Passwords must match")
    .required("Password confirmation is required"),
});

const RegisterViaEmailScreen = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { dob: dateOfBirth, nextScreen } = route.params as any;
  const [formattedDate] = dateOfBirth
    ? new Date(dateOfBirth).toISOString().split("T")
    : [null, null];
  const auth = getAuth();
  const [setDateOfBirth] = useMutation(SET_DOB);
  const { refetch } = useFirebaseAuth();

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <KeyboardAvoidingView style={styles.formContainer}>
        <Formik
          validationSchema={Schema}
          initialValues={{
            emailAddress: "",
            password: "",
            passwordConfirmation: "",
          }}
          onSubmit={async (input, { setFieldError }) => {
            try {
              await createUserWithEmailAndPassword(
                auth,
                input.emailAddress,
                input.password,
              );

              if (formattedDate) {
                await setDateOfBirth({
                  variables: { input: { dateOfBirth: formattedDate } },
                });
                await refetch();
              }

              if (nextScreen) {
                navigation.navigate(nextScreen);
              } else {
                navigation.navigate(NewVisitFlowScreens.InitialScreen);
              }
            } catch (error) {
              switch (error.code) {
                case "auth/email-already-in-use": {
                  setFieldError("emailAddress", "Email already in use");
                  break;
                }
                default: {
                  setFieldError("emailAddress", "Something went wrong");
                  break;
                }
              }
            }
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            touched,
            errors,
            isValid,
            isSubmitting,
          }) => {
            return (
              <>
                <Container>
                  <ScrollView
                    style={styles.scrollView}
                    contentContainerStyle={styles.scrollViewInner}
                  >
                    <Text style={styles.formHeader}>Account Information</Text>
                    <TextInputField
                      autoFocus
                      autoCompleteType="email"
                      name="emailAddress"
                      textContentType="emailAddress"
                      autoCapitalize="none"
                      keyboardType="email-address"
                      placeholder="Email Address"
                      hasError={!!errors?.emailAddress}
                      onChangeText={(text) =>
                        handleChange("emailAddress")(text.trim())
                      }
                      onBlur={handleBlur("emailAddress")}
                      value={values.emailAddress}
                      touched={!!touched.emailAddress}
                      error={errors.emailAddress}
                    />
                    <TextInputField
                      autoCompleteType="password"
                      name="password"
                      autoCapitalize="none"
                      placeholder="Password"
                      hasError={!!errors?.password}
                      secureTextEntry
                      textContentType="password"
                      onChangeText={handleChange("password")}
                      onBlur={handleBlur("password")}
                      value={values.password}
                      touched={!!touched.password}
                      error={errors.password}
                    />
                    <TextInputField
                      autoCompleteType="password"
                      name="passwordConfirmation"
                      autoCapitalize="none"
                      placeholder="Confirm Password"
                      hasError={!!errors?.passwordConfirmation}
                      secureTextEntry
                      textContentType="password"
                      onChangeText={handleChange("passwordConfirmation")}
                      onBlur={handleBlur("passwordConfirmation")}
                      value={values.passwordConfirmation}
                      touched={!!touched.passwordConfirmation}
                      error={errors.passwordConfirmation}
                    />
                  </ScrollView>
                </Container>
                <StickyFooter>
                  <Button
                    title="Next"
                    onPress={handleSubmit}
                    disabled={!isValid}
                    isLoading={isSubmitting}
                    style={{ marginTop: "auto" }}
                  />
                </StickyFooter>
              </>
            );
          }}
        </Formik>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default RegisterViaEmailScreen;

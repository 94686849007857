import { Address } from "../types";

export function summarizeAddress(address: Address): string {
  return [
    address.addressLineOne,
    address.addressLineTwo,
    address.city,
    address.state,
    address.zipCode,
  ].join(", ");
}

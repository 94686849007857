import React, { FunctionComponent } from "react";
import { View, Image, StyleSheet } from "react-native";
import { summarizeAddress } from "../../utils/address";
import Heading from "../Heading";
import Text from "../Text";
import { colors } from "../../theme";

interface Props {
  store: any;
}

const StoreInformation: FunctionComponent<Props> = ({ store }) => {
  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        {store.headerImageUrl ? (
          <Image
            source={{ uri: store.headerImageUrl }}
            style={styles.headerImage}
            resizeMode="cover"
          />
        ) : (
          <Image
            source={require("../../../assets/illustrations/no-store/no-store.png")}
            style={styles.noStoreImage}
          />
        )}
      </View>

      <Heading size="l" style={styles.name}>
        {store.name}
      </Heading>
      <Text size="m" style={styles.address}>
        {summarizeAddress(store.address)}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  imageContainer: {
    borderRadius: 20,
    overflow: "hidden",
    height: 125,
    backgroundColor: colors.secondaryMidGrey,
    marginBottom: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  headerImage: {
    width: "100%",
    height: 125,
  },
  noStoreImage: {
    width: 80,
    height: 80,
  },
  name: {
    color: colors.black,
  },
  address: {
    color: colors.darkGrey,
  },
});

export default StoreInformation;

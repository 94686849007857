import React from "react";
import { Text, ScrollView, StyleSheet, View } from "react-native";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import {
  TextInputField,
  Button,
  KeyboardAvoidingView,
  ImagePicker,
} from "../../components";

import { phoneRegExp } from "../../utils/forms/validations";
import { Customer } from "../../types";

import { Colors, Sizing, Outlines, Typography } from "../../styles";

interface Values {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  idImageUrls: string[];
  address: {
    addressLineOne: string;
    addressLineTwo: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  };
}

const adultValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name required").nullable(),
  lastName: Yup.string().required("Last name required").nullable(),
  emailAddress: Yup.string()
    .required("Email address is required")
    .email("Must be a valid email"),
  phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  address: Yup.object().shape({
    addressLineOne: Yup.string().required("Address line 1 is required"),
    addressLineTwo: Yup.string().nullable(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zipCode: Yup.string().required("Zipcode is required"),
  }),
});

type Props = {
  customer: Customer;
  onSubmit: (input: Values, actions: FormikHelpers<Values>) => void;
};

const EditProfileForm: React.FC<Props> = ({ customer, onSubmit }) => {
  const initialValues: Values = {
    firstName: customer.firstName || "",
    lastName: customer.lastName || "",
    emailAddress: customer.emailAddress || "",
    phoneNumber: customer.phoneNumber || "",
    idImageUrls: customer.idImageUrls || [],
    address: {
      addressLineOne: customer.address?.addressLineOne || "",
      addressLineTwo: customer.address?.addressLineTwo || "",
      city: customer.address?.city || "",
      state: customer.address?.state || "",
      zipCode: customer.address?.state || "",
      country: "US",
    },
  };

  return (
    <KeyboardAvoidingView style={{ flex: 1 }}>
      <Formik
        initialValues={initialValues}
        onSubmit={(input, actions) => onSubmit(input, actions)}
        validationSchema={adultValidationSchema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          isSubmitting,
          isValid,
        }) => {
          return (
            <>
              <ScrollView
                style={styles.container}
                contentContainerStyle={styles.contentContainer}
              >
                <View style={styles.field}>
                  <Text style={styles.formHeader}>Name</Text>
                  <TextInputField
                    name="firstName"
                    placeholder="First name"
                    textContentType="givenName"
                    onChangeText={(text) => handleChange("firstName")(text)}
                    onEndEditing={(e) => {
                      handleChange("firstName")(e.nativeEvent.text.trim());
                      handleBlur("firstName");
                    }}
                    value={values.firstName}
                    error={errors?.firstName}
                  />
                  <TextInputField
                    name="lastName"
                    placeholder="Last name"
                    textContentType="familyName"
                    onChangeText={(text) => handleChange("lastName")(text)}
                    onEndEditing={(e) => {
                      handleChange("lastName")(e.nativeEvent.text.trim());
                      handleBlur("lastName");
                    }}
                    value={values.lastName}
                    error={errors?.lastName}
                  />
                </View>
                <View style={styles.field}>
                  <Text style={styles.formHeader}>Email Address</Text>
                  <TextInputField
                    name="emailAddress"
                    textContentType="emailAddress"
                    autoCapitalize="none"
                    autoCompleteType="email"
                    placeholder="email@domain.com"
                    onChangeText={(text) =>
                      handleChange("emailAddress")(text.trim())
                    }
                    onBlur={handleBlur("emailAddress")}
                    value={values.emailAddress}
                    error={errors?.emailAddress}
                  />
                </View>
                <View style={styles.field}>
                  <Text style={styles.formHeader}>Phone Number</Text>
                  <TextInputField
                    name="phoneNumber"
                    textContentType="telephoneNumber"
                    keyboardType="phone-pad"
                    onChangeText={(text) => handleChange("phoneNumber")(text)}
                    onBlur={handleBlur("phoneNumber")}
                    value={values.phoneNumber}
                    error={errors?.phoneNumber}
                  />
                </View>
                <View style={styles.field}>
                  <Text style={styles.formHeader}>Address</Text>
                  <TextInputField
                    name="address.addressLineOne"
                    placeholder="Street Address Line 1"
                    textContentType="streetAddressLine1"
                    onChangeText={handleChange("address.addressLineOne")}
                    onBlur={handleBlur("address.addressLineOne")}
                    value={values.address.addressLineOne}
                    error={errors?.address?.addressLineOne}
                  />
                  <TextInputField
                    name="address.addressLineTwo"
                    placeholder="Street Address Line 2"
                    textContentType="streetAddressLine2"
                    onChangeText={handleChange("address.addressLineTwo")}
                    onBlur={handleBlur("address.addressLineTwo")}
                    value={values.address.addressLineTwo}
                    error={errors?.address?.addressLineTwo}
                  />
                  <TextInputField
                    name="address.city"
                    placeholder="City"
                    textContentType="addressCity"
                    onChangeText={handleChange("address.city")}
                    onBlur={handleBlur("address.city")}
                    value={values.address.city}
                    error={errors?.address?.city}
                  />
                  <View style={styles.dualInput}>
                    <TextInputField
                      name="address.state"
                      placeholder="State"
                      textContentType="addressState"
                      onChangeText={handleChange("address.state")}
                      onBlur={handleBlur("address.state")}
                      value={values.address.state}
                      style={styles.flexInput}
                      error={errors?.address?.state}
                    />
                    <TextInputField
                      name="address.zipCode"
                      placeholder="Zip code"
                      textContentType="postalCode"
                      onChangeText={handleChange("address.zipCode")}
                      onBlur={handleBlur("address.zipCode")}
                      value={values.address.zipCode}
                      style={styles.flexInput}
                      error={errors?.address?.zipCode}
                    />
                  </View>
                </View>
                <View style={styles.field}>
                  <Text style={styles.formHeader}>ID Image</Text>

                  <ImagePicker
                    onUpload={(file) =>
                      handleChange("idImageUrls[0]")(file.ssl_url)
                    }
                    value={values.idImageUrls[0]}
                  />
                </View>
              </ScrollView>
              <View style={styles.footer}>
                <Button
                  title="Save Changes"
                  onPress={handleSubmit}
                  disabled={!isValid}
                  isLoading={isSubmitting}
                  style={styles.button}
                />
              </View>
            </>
          );
        }}
      </Formik>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: Sizing.x20,
  },
  contentContainer: {
    paddingBottom: Sizing.x20,
  },
  formInner: {
    marginBottom: 40,
  },
  formHeader: {
    ...Typography.label.x30,
    marginBottom: Sizing.x5,
  },
  field: {
    marginBottom: Sizing.x10,
  },
  dualInput: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flexInput: {
    flex: 1,
    marginLeft: Sizing.x5,
  },
  button: {
    width: "100%",
    marginTop: "auto",
    marginBottom: Sizing.x10,
  },
  footer: {
    backgroundColor: Colors.brand.blue,
    paddingVertical: Sizing.x20,
    paddingHorizontal: Sizing.x30,
    borderTopColor: Colors.neutral.s600,
    borderTopWidth: Outlines.borderWidth.thin,
  },
});

export default EditProfileForm;

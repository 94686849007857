import { ApolloLink, ApolloClient, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import getEnv from "./environment";
import { relayStylePagination } from "@apollo/client/utilities";
import { getAuth } from "firebase/auth";

const { apiUrl } = getEnv();

const httpLink = createHttpLink({
  uri: apiUrl + "/customer/graphql",
});

const authLink = setContext(
  async (_: any, { headers }: { headers: Object }) => {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
      },
    };
  },
);

const authedHttpLink = authLink.concat(httpLink);

const client = new ApolloClient({
  link: (authedHttpLink as unknown) as ApolloLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          stores: relayStylePagination(["filterBy"]),
        },
      },
    },
  }),
});

export default client;

type Neutral =
  | "white"
  | "s100"
  | "s150"
  | "s200"
  | "s250"
  | "s300"
  | "s400"
  | "s500"
  | "s600"
  | "s700"
  | "s800"
  | "s900"
  | "black";
export const neutral: Record<Neutral, string> = {
  white: "#ffffff",
  s100: "#fbf4e5",
  s150: "#f3f3f3",
  s200: "#dddddd",
  s250: "#b0b1b7",
  s300: "#9f9ea4",
  s400: "#7c7c82",
  s500: "#666A74",
  s600: "#3b3c46",
  s700: "#2d2c2e",
  s800: "#212123",
  s900: "#151815",
  black: "#000000",
};

type Brand = "yellow" | "blue";
export const brand: Record<Brand, string> = {
  yellow: "#FEB94C",
  blue: "#151829",
};

type Status = "error" | "success" | "warning";
export const status: Record<Status, string> = {
  error: "#FF766C",
  success: "#5FB6A8",
  warning: "#FEB94C",
};

const applyOpacity = (hexColor: string, opacity: number): string => {
  const red = parseInt(hexColor.slice(1, 3), 16);
  const green = parseInt(hexColor.slice(3, 5), 16);
  const blue = parseInt(hexColor.slice(5, 7), 16);

  return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
};

type Transparent = "clear" | "lightGray" | "darkGray";
export const transparent: Record<Transparent, string> = {
  clear: "rgba(255, 255, 255, 0)",
  lightGray: applyOpacity(neutral.s300, 0.4),
  darkGray: applyOpacity(neutral.s800, 0.8),
};

export const shadeColor = (hexColor: string, percent: number): string => {
  const redGamut: number = parseInt(hexColor.slice(1, 3), 16);
  const greenGamut: number = parseInt(hexColor.slice(3, 5), 16);
  const blueGamut: number = parseInt(hexColor.slice(5, 7), 16);

  const rgb: Array<number> = [redGamut, greenGamut, blueGamut];

  const toShadedGamut = (gamut: number): number => {
    return Math.floor(Math.min(gamut * (1 + percent / 100), 255));
  };

  const toHex = (gamut: number): string => {
    return gamut.toString(16).length === 1
      ? `0${gamut.toString(16)}`
      : gamut.toString(16);
  };

  const shadedRGB: Array<number> = rgb.map(toShadedGamut);
  const shadedHex: Array<string> = shadedRGB.map(toHex);

  const hexString: string = shadedHex.join("");

  return `#${hexString}`;
};

import { ServiceTypeOffering } from "../../types";

export const ServiceSelectionTitle = {
  [ServiceTypeOffering.Tattoo]: "About Your Tattoo",
  [ServiceTypeOffering.PermanentMakeup]: "Permanent Makeup Type",
  [ServiceTypeOffering.Piercing]: "Piercing Type",
};

export const ServiceSelectionDescription = {
  [ServiceTypeOffering.Tattoo]:
    "Please tell us as much as you can about your desired tattoo.",
  [ServiceTypeOffering.PermanentMakeup]: "Please select one",
  [ServiceTypeOffering.Piercing]: "Please select one",
};

import React from "react";
import {
  createStackNavigator,
  StackNavigationOptions,
} from "@react-navigation/stack";

import LoginViaEmailScreen from "../screens/LoginViaEmailScreen";
import { ScreenHeaderTitle } from "../components/ScreenHeader";
import { colors } from "../theme";
import AuthenticationScreen from "../screens/AuthenticationScreen";
import RegisterViaEmailScreen from "../screens/RegisterViaEmailScreen";
import ForgotPasswordScreen from "../screens/ForgotPasswordScreen";
import { Platform } from "react-native";

const AuthStack = createStackNavigator();

const headerHeight =
  Platform.OS === "web" ? 70 : Platform.OS === "ios" ? 110 : 120;

export const commonHeader: Partial<StackNavigationOptions> = {
  headerStyle: {
    backgroundColor: colors.black,
    height: headerHeight,
    shadowColor: "transparent",
    elevation: 0,
    shadowRadius: 0,
    shadowOffset: {
      height: 0,
      width: 0,
    },
    borderBottomWidth: 1,
    borderBottomColor: colors.darkestGrey,
  },
  headerTintColor: "#fff",
  headerBackTitleVisible: false,
};

const AuthNavigator = () => (
  <AuthStack.Navigator screenOptions={{ headerTitleAlign: "center" }}>
    <AuthStack.Screen
      name="Authentication"
      component={AuthenticationScreen}
      initialParams={{ nextScreen: null }}
      options={{ headerShown: false }}
    />
    <AuthStack.Screen
      name="LoginViaEmail"
      component={LoginViaEmailScreen}
      initialParams={{ nextScreen: null }}
      options={{
        ...commonHeader,
        title: "Login",
        headerTitle: (props) => <ScreenHeaderTitle {...props} />,
      }}
    />
    <AuthStack.Screen
      name="ForgotPassword"
      component={ForgotPasswordScreen}
      initialParams={{ nextScreen: null }}
      options={{
        ...commonHeader,
        title: "Forgot Password",
        headerTitle: (props) => <ScreenHeaderTitle {...props} />,
      }}
    />
    <AuthStack.Screen
      name="RegisterViaEmail"
      component={RegisterViaEmailScreen}
      initialParams={{ nextScreen: null }}
      options={{
        ...commonHeader,
        title: "Register",
        headerTitle: (props) => <ScreenHeaderTitle {...props} />,
      }}
    />
  </AuthStack.Navigator>
);

export default AuthNavigator;

import React, { FunctionComponent, useState } from "react";
import {
  StyleSheet,
  View,
  Image,
  TouchableHighlight,
  ActivityIndicator,
  Platform,
} from "react-native";
import { v4 as uuidv4 } from "react-native-uuid";
import { useActionSheet } from "@expo/react-native-action-sheet";
import * as Segment from "../../analytics";
import * as ExpoImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";
import * as ImageManipulator from "expo-image-manipulator";
import { useMutation } from "@apollo/client";
import Uppy from "@uppy/core";
import Transloadit from "@uppy/transloadit";

import Heading from "../Heading";
import useUppy from "../../hooks/useUppy";
import { GENERATE_UPLOAD } from "./gql";
import { colors } from "../../theme";
import { UploadResult } from "./types";

type Props = {
  onUpload: (file: UploadResult) => void;
  value: string | null;
  blankMessage?: string;
};

const ImagePicker: FunctionComponent<Props> = ({
  onUpload,
  value,
  blankMessage = "Upload from image or take picture",
}) => {
  const [generateUpload] = useMutation(GENERATE_UPLOAD);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { showActionSheetWithOptions } = useActionSheet();

  const uppy = useUppy(() =>
    Uppy({ autoProceed: true })
      .use(Transloadit, {
        getAssemblyOptions: async () => {
          setIsUploading(true);
          const { data } = await generateUpload();

          return {
            signature: data.result.signature,
            params: {
              auth: {
                key: data.result.authKey,
                expires: data.result.expires,
              },
              template_id: data.result.templateId,
            },
          };
        },
        waitForEncoding: true,
      })
      .on("transloadit:complete", (assembly: any) => {
        // TODO: revisit the assumption that everything will end with this `compress_image` step
        const file = assembly.results.compress_image[0];
        uppy.reset();
        setIsUploading(false);
        onUpload(file);
      }),
  );

  const uploadFile = async (result: ExpoImagePicker.ImagePickerResult) => {
    if (result.cancelled) {
      return;
    }

    // Half image size and compress
    const fileName = uuidv4();
    const resizedImage = await ImageManipulator.manipulateAsync(
      result.uri,
      [{ resize: { width: result.width / 2, height: result.height / 2 } }],
      { compress: 0.8 },
    );

    if (Platform.OS === "web") {
      const res: Response = await fetch(resizedImage.uri);
      const blob: Blob = await res.blob();
      const file = new File([blob], fileName as string, { type: result.type });

      return uppy.addFile({
        source: "file input",
        name: fileName,
        type: result.type,
        data: file,
      });
    }

    return uppy.addFile({
      source: "file input",
      name: fileName,
      type: result.type,
      data: resizedImage,
    });
  };

  const onImagePickerSelect = async () => {
    if (Platform.OS !== "web") {
      const {
        status,
      } = await ExpoImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        alert("Sorry, we need camera roll permissions to make this work!");
      }
    }

    Segment.trackWithProperties("Camera Roll Launched", {});

    const result = await ExpoImagePicker.launchImageLibraryAsync({});
    uploadFile(result);
  };

  const openActionSheet = () => {
    // TODO: Finish the action sheet
    const options = ["Take new picture", "Select existing image", "Cancel"];
    const cancelButtonIndex = 2;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      async (idx) => {
        switch (idx) {
          case 0: {
            const { status } = await Permissions.askAsync(Permissions.CAMERA);

            Segment.trackWithProperties("Camera Launched", {
              permissionStatus: status,
            });

            if (status === "granted") {
              const result = await ExpoImagePicker.launchCameraAsync();
              uploadFile(result);
            } else {
              alert("You must allow access to access this feature");
            }
            break;
          }
          case 1: {
            await onImagePickerSelect();
            break;
          }
          default: {
            return;
          }
        }
      },
    );
  };

  return (
    <View style={styles.container}>
      {!value && !isUploading && (
        <TouchableHighlight
          style={styles.blankState__Button}
          onPress={
            Platform.OS === "web" ? onImagePickerSelect : openActionSheet
          }
        >
          <View style={styles.blankState}>
            <Image
              source={require("../../../assets/icons/camera.png")}
              style={{ width: 60, height: 50 }}
            />
            <Heading size="smallcaps" style={styles.blankState__Text}>
              {blankMessage}
            </Heading>
          </View>
        </TouchableHighlight>
      )}

      {value && (
        <View>
          <Image source={{ uri: value }} style={styles.image} />

          <TouchableHighlight
            style={styles.existingImage__Button}
            onPress={
              Platform.OS === "web" ? onImagePickerSelect : openActionSheet
            }
          >
            <View style={styles.existingImage}>
              <Image
                source={require("../../../assets/icons/camera.png")}
                style={styles.existingImage__Icon}
              />
            </View>
          </TouchableHighlight>
        </View>
      )}

      {isUploading && (
        <View style={styles.loading}>
          <ActivityIndicator
            color={colors.yellow}
            style={{ marginBottom: 10 }}
          />
          <Heading size="smallcaps" style={{ color: colors.white }}>
            Uploading...
          </Heading>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.darkestGrey,
    borderRadius: 20,
    overflow: "hidden",
    height: 365,
    marginTop: 20,
    marginBottom: 20,
    position: "relative",
  },
  blankState: {
    backgroundColor: colors.yellow,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  blankState__Button: {
    flex: 1,
    height: 365,
    width: "100%",
    backgroundColor: colors.yellow,
  },
  blankState__Text: {
    marginTop: 20,
    marginHorizontal: 80,
    textAlign: "center",
  },
  loading: {
    backgroundColor: colors.black,
    width: 150,
    height: 80,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    marginLeft: -75,
    marginTop: -40,
  },
  image: {
    height: 365,
  },
  existingImage: {
    backgroundColor: colors.yellow,
    width: 50,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
  },
  existingImage__Button: {
    backgroundColor: colors.yellow,
    borderRadius: 50,
    overflow: "hidden",
    position: "absolute",
    right: 20,
    top: 20,
  },
  existingImage__Icon: {
    width: 24,
    height: 20,
  },
});

export default ImagePicker;

import { gql } from "@apollo/client";
import {
  fullVisit,
  medicalHistory,
  visitCustomerDetails,
} from "../../fragments";

export const VISIT = gql`
  query Visit($visitId: ID!) {
    visit: node(id: $visitId) {
      ... on Visit {
        ...fullVisit
        ...visitCustomerDetails
        medicalHistory {
          ...medicalHistory
        }
      }
    }
  }
  ${fullVisit}
  ${visitCustomerDetails}
  ${medicalHistory}
`;

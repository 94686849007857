import React from "react";
import { View, Image, StyleSheet, ViewStyle, ImageStyle } from "react-native";
import { colors } from "../../theme";

type Size = "s" | "m" | "l" | "xl";

const Avatar = ({
  size = "m",
  imageUrl,
  hasBorder = false,
}: {
  size?: Size;
  imageUrl?: string | null;
  hasBorder?: boolean;
}) => {
  const determineContainerStyle = (size: Size): ViewStyle => {
    switch (size) {
      case "s":
        return styles.container__s;
      case "m":
        return styles.container__m;
      case "l":
        return styles.container__l;
      case "xl":
        return styles.container__xl;
    }
  };

  const determineIconStyle = (size: Size): ImageStyle => {
    switch (size) {
      case "s":
        return styles.icon__s;
      case "m":
        return styles.icon__m;
      case "l":
        return styles.icon__l;
      case "xl":
        return styles.icon__l;
    }
  };

  return (
    <View
      style={[
        styles.container,
        determineContainerStyle(size),
        hasBorder && styles.container__withBorder,
      ]}
    >
      {imageUrl ? (
        <Image source={{ uri: imageUrl }} style={styles.image} />
      ) : (
        <Image
          source={require("../../../assets/icons/profile.png")}
          style={determineIconStyle(size)}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.secondaryMidGrey,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  container__s: {
    width: 20,
    height: 20,
    borderRadius: 20,
  },
  container__m: {
    width: 30,
    height: 30,
    borderRadius: 30,
  },
  container__l: {
    width: 40,
    height: 40,
    borderRadius: 40,
  },
  container__xl: {
    width: 80,
    height: 80,
    borderRadius: 80,
  },
  container__withBorder: {
    borderColor: colors.white,
    borderWidth: 2,
  },
  icon__s: {
    width: 12,
    height: 12,
    marginTop: -2,
  },
  icon__m: {
    width: 17,
    height: 17,
  },
  icon__l: {
    width: 22,
    height: 22,
  },
  image: {
    width: "100%",
    height: "100%",
  },
});

export default Avatar;

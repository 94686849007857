import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  ViewStyle,
  TextStyle,
} from "react-native";
import { colors, fonts } from "../../theme";

export enum Appearance {
  primary = "primary",
  ghost = "ghost",
  unstyled = "unstyled",
}

interface Props {
  isLoading?: boolean;
  disabled?: boolean;
  title: string;
  onPress: any;
  style?: Object;
  appearance?: Appearance;
}

const Button: React.FC<Props> = ({
  title,
  disabled = false,
  onPress,
  isLoading = false,
  appearance = Appearance.primary,
  style = {},
}) => {
  const determineAppearanceStyle = (appearance: Appearance): ViewStyle => {
    switch (appearance) {
      case Appearance.primary:
        return styles.base__primary;
      case Appearance.ghost:
        return styles.base__ghost;
      case Appearance.unstyled:
        return styles.base__unstyled;
    }
  };

  const determineTextStyle = (appearance: Appearance): TextStyle => {
    switch (appearance) {
      case Appearance.primary:
        return styles.text__primary;
      case Appearance.ghost:
        return styles.text__ghost;
      case Appearance.unstyled:
        return styles.text__unstyled;
    }
  };

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      accessibilityRole="button"
      style={[
        styles.base,
        appearance && determineAppearanceStyle(appearance),
        disabled && styles.disabled,
        style,
      ]}
    >
      {isLoading && (
        <View style={{ marginRight: 10, marginLeft: -30 }}>
          <ActivityIndicator size="small" color={colors.black} />
        </View>
      )}
      <Text style={[styles.text, appearance && determineTextStyle(appearance)]}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  base: {
    borderWidth: 2,
    borderColor: "transparent",
    borderRadius: 60,
    padding: 10,
    height: 45,
    width: "100%",
    maxWidth: 450,
    alignSelf: "center",
    marginHorizontal: "auto",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  base__primary: {
    backgroundColor: colors.yellow,
  },
  base__ghost: {
    backgroundColor: "transparent",
    borderColor: colors.yellow,
  },
  base__unstyled: {
    backgroundColor: "transparent",
    borderColor: "transparent",
  },
  disabled: {
    backgroundColor: colors.darkestGrey,
  },
  text: {
    fontFamily: fonts.semiBold,
    textTransform: "uppercase",
    color: colors.black,
    fontSize: 15,
    letterSpacing: 1,
    textAlign: "center",
  },
  text__primary: {
    color: colors.black,
  },
  text__ghost: {
    color: colors.yellow,
  },
  text__unstyled: {
    color: colors.black,
  },
});

export default Button;

import React, { FunctionComponent } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import { colors } from "../../theme";
import Header from "../../components/Header";
import Text from "../../components/Text";
import Button from "../../components/Button";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  formContainer: {
    flex: 1,
    marginTop: 20,
  },
  scrollView: {
    paddingHorizontal: 20,
    flex: 1,
  },
  text: {
    color: colors.white,
    marginTop: 20,
  },
});

interface VisitNotFoundProps {
  navigation: any;
}

const VisitNotFound: FunctionComponent<VisitNotFoundProps> = ({
  navigation,
}) => (
  <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
    <Container>
      <ScrollView style={styles.scrollView}>
        <Header title="We couldn't find that visit" />
        <Text size="l" style={styles.text}>
          We're sorry but we couldn't find the visit specified. Please check the
          link and try again, or contact BodyArt support for help.
        </Text>
      </ScrollView>
    </Container>
    <StickyFooter>
      <Button
        title="Go back"
        onPress={() => {
          navigation.navigate("Main");
        }}
      />
    </StickyFooter>
  </SafeAreaView>
);

export default VisitNotFound;

import React, { FunctionComponent } from "react";
import { TouchableOpacity } from "react-native";
import { colors } from "../../theme";
import Heading from "../Heading";

interface LinkProps {
  onPress: () => void;
}

const Link: FunctionComponent<LinkProps> = ({ children, onPress }) => (
  <TouchableOpacity onPress={onPress}>
    <Heading
      size="smallcaps"
      style={{ color: colors.yellow, letterSpacing: 1 }}
    >
      {children}
    </Heading>
  </TouchableOpacity>
);

export default Link;

import { useRoute } from "@react-navigation/native";
import { uniqueId } from "lodash";
import React, { useState } from "react";
import { StyleSheet, ScrollView, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useDispatch } from "react-redux";

import Button from "../../components/Button";
import Container from "../../components/Container";
import Header from "../../components/Header";
import ServiceList from "../../components/ServiceList";
import StickyFooter from "../../components/StickyFooter";
import { upsertService } from "../../state/consentFlow";
import { colors } from "../../theme";
import { Service, ServiceType } from "../../types";

import {
  ServiceSelectionTitle,
  ServiceSelectionDescription,
} from "./constants";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  scrollView: {
    paddingHorizontal: 20,
  },
});

type Props = {
  serviceType: ServiceType;
  services: Service[];
  navigation: any;
};

const SelectFromManyServices: React.FC<Props> = ({
  services,
  navigation,
  serviceType,
}) => {
  const route = useRoute();
  const dispatch = useDispatch();
  const [selectedService, setSelectedService] = useState<Service | null>(null);

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <Container>
        <ScrollView style={styles.scrollView}>
          <Header
            title={ServiceSelectionTitle[serviceType.type]}
            description={ServiceSelectionDescription[serviceType.type]}
          />

          <ServiceList
            serviceType={serviceType}
            services={services}
            selectedService={selectedService}
            onSelect={setSelectedService}
          />
        </ScrollView>
      </Container>
      <StickyFooter>
        <Button
          title="Next"
          onPress={() => {
            if (
              selectedService &&
              (selectedService.informationFields || []).length > 0
            ) {
              navigation.navigate("ServiceDetailsEntryForVisit", {
                ...route.params,
                service: selectedService,
                serviceType: serviceType,
              });
            } else if (selectedService) {
              dispatch(
                upsertService({
                  id: uniqueId("service"),
                  serviceId: selectedService.id,
                  isExistingService: false,
                }),
              );

              navigation.navigate("ReviewServicesForVisit", {
                ...route.params,
              });
            }
          }}
          disabled={!selectedService}
        />
      </StickyFooter>
    </SafeAreaView>
  );
};

export default SelectFromManyServices;

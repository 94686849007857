import { take } from "lodash";

export function avatarName(name: string): string {
  const splitName = name.split(" ");

  if (splitName.length === 1) {
    return splitName[0].substr(0, 2);
  }

  return take(splitName, 2)
    .map((n) => n.charAt(0))
    .join("");
}

import React, { FunctionComponent } from "react";
import { StyleSheet } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMutation } from "@apollo/client";
import { useNavigation, useRoute, Route } from "@react-navigation/native";
import { omit } from "lodash";

import KeyboardAvoidingView from "../../components/KeyboardAvoidingView";
import { InputError, Customer } from "../../types";
import { UPDATE_PROFILE } from "./gql";
import Form from "./Form";

import { Colors } from "../../styles";
import { useFirebaseAuth } from "../../firebase/AuthProvider";

interface Props {
  nextScreen: string;
}

const PersonalInformationInputScreen: FunctionComponent<Props> = ({
  nextScreen = "PhoneNumberInput",
}) => {
  const navigation = useNavigation();
  const route = useRoute<Route<string, { nextScreen: string }>>();
  const [updateProfile] = useMutation(UPDATE_PROFILE);
  const { currentCustomer, refetch } = useFirebaseAuth();

  const { nextScreen: routeParamsNextScreen } = route.params || {
    nextScreen: null,
  };

  const nextScreenOnNavigate = routeParamsNextScreen || nextScreen;

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <KeyboardAvoidingView style={styles.formContainer}>
        <Form
          customer={currentCustomer as Customer}
          onSubmit={async (input, { setFieldError }) => {
            const result = await updateProfile({
              variables: {
                input,
              },
            });

            await refetch();

            const errors: [InputError] = result.data?.result?.errors || [];

            if (errors.length > 0) {
              errors.forEach(({ field, message }) =>
                setFieldError(field, message),
              );
              return;
            }

            navigation.navigate(nextScreenOnNavigate, {
              ...omit(route.params, "nextScreen"),
            });
          }}
        />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.brand.blue,
    flex: 1,
  },
  formContainer: {
    flex: 1,
  },
});

export default PersonalInformationInputScreen;

import React from "react";
import {
  ActivityIndicator,
  Platform,
  StatusBar,
  StyleSheet,
  View,
} from "react-native";
import {
  LinkingOptions,
  NavigationContainer,
  NavigationContainerRef,
} from "@react-navigation/native";
import * as Linking from "expo-linking";
import * as Notifications from "expo-notifications";
import * as Segment from "./analytics";
import Branch from "./branch";

import { colors } from "./theme";
import Navigation from "./Navigation";

const prefix = Linking.makeUrl("/");

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
});

type BranchParams = {
  link_path?: string;
  "+non_branch_link"?: string;
  $canonical_url?: string;
};

export const navigationRef = React.createRef<NavigationContainerRef>();

export function navigate(name: string, params: object) {
  navigationRef.current?.navigate(name, params);
}

const navigateFromNotification = ({ visitId }: any) => {
  // Defer this for 400ms to ensure the navigator is initialized
  setTimeout(() => navigate("Visit", { visitId }), 400);
};

const handleNotificationForeground = ({ request }: any) => {
  Segment.track("Push Notification Received");

  if (request.content.data.visitId) {
    navigateFromNotification(request.content.data);
  }
};

const handleNotificationBackground = ({ notification }: any) => {
  Segment.track("Push Notification Received");

  handleNotificationForeground(notification);
};

function handlePartialBranchLink(path: string | null) {
  if (path && path.startsWith("/")) {
    // The linking module expects a full path here so we
    // want to artificially construct one based on the incoming path
    return "https://link.bodyart.app" + path;
  }

  return path;
}

const AppNavigator = () => {
  const routeNameRef = React.useRef<string>();
  const linking: LinkingOptions = {
    prefixes: [
      prefix,
      "https://link.bodyart.app",
      "https://bodyart.app.link",
      "https://bodyart-alternate.app.link",
    ],
    config: {
      initialRouteName: "Main",
      screens: {
        NewVisitFlow: "visits/new/:storeId",
        NewVisitUnscheduledFlow: "visits/new-unscheduled/:storeId",
        Visit: "visits/:visitId",
        ResetPassword: "reset-password/:token",
        InviteFlow: "invites/:token",
      },
    },
    async getInitialURL() {
      const url = await Linking.getInitialURL();
      const params = (await Branch.getLatestReferringParams()) as BranchParams;

      // If it's not a branch link, do the default behavior
      if (params["+non_branch_link"]) {
        return url;
      }

      if (params["link_path"]) {
        return handlePartialBranchLink(params["link_path"]);
      }

      return params?.$canonical_url;
    },
    subscribe(listener: any) {
      const onReceiveURL = ({ url }: { url: string }) => listener(url);
      Linking.addEventListener("url", onReceiveURL);

      Branch.initSessionTtl = 10000;
      Branch.subscribe(
        ({ error, params }: { error?: string; params: BranchParams }) => {
          if (error) {
            console.error("Error from Branch: " + error);
            return;
          }

          if (params["+non_branch_link"]) {
            return;
          }

          // The link path is a bit of a hack so we can just pass any old path
          // to a branch link without the need to generate a new link everytime
          if (params["link_path"]) {
            const url = handlePartialBranchLink(params["link_path"]);

            Segment.trackWithProperties("Deep Link Opened", {
              provider: "Branch Metrics",
              url,
            });

            listener(url);
            return;
          }

          if (params.$canonical_url) {
            Segment.trackWithProperties("Deep Link Opened", {
              provider: "Branch Metrics",
              url: params.$canonical_url,
            });

            listener(params.$canonical_url);
          }
        },
      );

      return () => {
        // Clean up the event listeners
        Linking.removeEventListener("url", onReceiveURL);
        Branch.unsubscribe();
      };
    },
  };

  React.useEffect(() => {
    const subscriptions = [
      Notifications.addNotificationReceivedListener(
        handleNotificationForeground,
      ),
      Notifications.addNotificationResponseReceivedListener(
        handleNotificationBackground,
      ),
    ];

    return () => {
      subscriptions.forEach((s) => s.remove());
    };
  }, []);

  return (
    <NavigationContainer
      linking={linking}
      fallback={<ActivityIndicator />}
      ref={navigationRef}
      onReady={() =>
        (routeNameRef.current = navigationRef.current?.getCurrentRoute()?.name)
      }
      documentTitle={{
        formatter: (options, route) =>
          `${options?.title ?? route?.name} - BodyArt`,
      }}
      onStateChange={() => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current?.getCurrentRoute()?.name;

        if (currentRouteName && previousRouteName !== currentRouteName) {
          Segment.screen(currentRouteName);
        }

        // Save the current route name for later comparision
        routeNameRef.current = currentRouteName;
      }}
    >
      <View style={styles.container}>
        <Navigation />
        {Platform.OS === "ios" && <StatusBar barStyle="light-content" />}
      </View>
    </NavigationContainer>
  );
};

export default AppNavigator;

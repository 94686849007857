import { gql } from "@apollo/client";

export const Customer = gql`
  fragment Customer on Customer {
    id
    emailAddress
    dateOfBirth
    firstName
    lastName
    name
    phoneNumber
    isMinor
    address {
      addressLineOne
      addressLineTwo
      city
      state
      zipCode
      country
    }
    idImageUrls
    insertedAt
    updatedAt
  }
`;

export const pageInfo = gql`
  fragment pageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;

export const storeInformation = gql`
  fragment storeInformation on Store {
    id
    name
    website
    phoneNumber
    emailAddress
    profileImageUrl
    headerImageUrl
    address {
      addressLineOne
      addressLineTwo
      city
      state
      zipCode
      country
    }
  }
`;

export const partialService = gql`
  fragment partialService on Service {
    id
    name
    description
    healingTime
    downsizeTime
    serviceType {
      id
      name
      type
    }
  }
`;

export const serviceForCategoryListing = gql`
  fragment serviceForCategoryListing on Service {
    ...partialService
    icon {
      selectedUrl
      unselectedUrl
    }
    informationFields {
      ... on ServiceInformationTextField {
        name
        label
        type
        placeholder
        isRequired
      }
      ... on ServiceInformationOptionField {
        name
        label
        isRequired
        options
      }
      ... on ServiceInformationSelectField {
        name
        label
        isRequired
        options
      }
    }
  }
  ${partialService}
`;

export const serviceTypeCategory = gql`
  fragment serviceTypeCategory on ServiceTypeCategory {
    id
    name
    icon {
      selectedUrl
      unselectedUrl
    }
  }
`;

export const serviceType = gql`
  fragment serviceType on ServiceType {
    id
    name
    type
  }
`;

export const storeWithServices = gql`
  fragment storeWithServices on Store {
    id
    serviceTypes {
      ...serviceType
    }
  }
  ${serviceType}
`;

export const Session = gql`
  fragment Session on Session {
    refreshToken
    token
    customer {
      ...Customer
    }
  }
  ${Customer}
`;

export const fullVisit = gql`
  fragment fullVisit on Visit {
    id
    scheduledFor
    status
    consentAnsweredAt
    idImageUrls
    denyReason
    customer {
      id
      name
      dateOfBirth
    }
    services {
      id
      additionalInformation
      aftercareInstructions
      externalAftercareInstructionsUrl
      denyReason
      submittedInformation {
        name
        label
        value
      }
      assignedEmployee {
        id
        services
        person {
          id
          name
          profileImageUrl
        }
      }
      service {
        ...partialService
      }
      inventorySelections {
        id
        inventoryItem {
          id
          name
        }
        selectedValues {
          name
          value
        }
      }
    }
    store {
      ...storeInformation
    }
  }
  ${storeInformation}
  ${partialService}
`;

export const visitCustomerDetails = gql`
  fragment visitCustomerDetails on Visit {
    customerInformation {
      firstName
      lastName
      emailAddress
      dateOfBirth
      phoneNumber
      idImageUrls
      address {
        addressLineOne
        addressLineTwo
        city
        state
        zipCode
        country
      }
    }
  }
`;

export const medicalHistory = gql`
  fragment medicalHistory on MedicalHistory {
    additionalNotes
    medicalConditions
    hasSkinCondition
    hasIndicatedMedicationUse
    hasAllergyToProducts
    isGenerallyHealthy
    isProneToFainting
    hasBloodConditions
    hasHistoryOfComplications
  }
`;

export const activity = gql`
  fragment activity on Activity {
    id
    type
    visit {
      id
    }
    store {
      id
      name
    }
    readAt
    insertedAt
  }
`;

import React from "react";
import { StyleSheet, View, useWindowDimensions } from "react-native";
import { ServiceType, ServiceTypeCategory } from "../../types";
import Cell from "./Cell";

interface Props {
  serviceType: ServiceType;
  locations: ServiceTypeCategory[];
  onSelect: (category: ServiceTypeCategory) => any;
  selectedLocation: ServiceTypeCategory | null;
}

const ProcedureLocationList: React.FC<Props> = ({
  locations,
  onSelect,
  selectedLocation,
}) => {
  const { width: windowWidth } = useWindowDimensions();

  return (
    <View style={styles.container}>
      {locations.map((location, idx) => {
        const isSelected =
          selectedLocation && location.id === selectedLocation.id;

        const icon = location.icon
          ? isSelected
            ? location.icon.selectedUrl
            : location.icon.unselectedUrl || location.icon.selectedUrl
          : null;

        return (
          <Cell
            key={location.id}
            name={location.name}
            iconUrl={icon}
            isSelected={!!isSelected}
            onSelect={() => onSelect(location)}
            windowWidth={Math.min(windowWidth, 590)}
          />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "flex-start",
  },
});

export default ProcedureLocationList;

import React, { FunctionComponent, useEffect } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { StyleSheet, View, ScrollView } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Formik } from "formik";
import * as Yup from "yup";

import KeyboardAvoidingView from "../../components/KeyboardAvoidingView";
import Button, { Appearance } from "../../components/Button";
import { colors, fonts } from "../../theme";
import { Customer as CustomerFragment } from "../../fragments";
import TextInputField from "../../components/TextInputField";
import { Customer } from "../../types";
import Heading from "../../components/Heading";
import Text from "../../components/Text";
import { hasCompletedProfile } from "../../utils/customer";
import { INVITE_MINOR } from "./gql";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";
import {useFirebaseAuth} from "../../firebase/AuthProvider";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  formContainer: {
    flex: 1,
  },
  scrollContainer: {
    flex: 1,
    paddingHorizontal: 20,
  },
  formInner: {
    paddingVertical: 20,
  },
  header: {
    paddingBottom: 20,
    paddingHorizontal: 20,
    borderBottomColor: colors.darkestGrey,
    borderBottomWidth: 1,
    alignItems: "center",
  },
  header__Title: {
    color: colors.white,
    textAlign: "center",
    marginHorizontal: 15,
    marginBottom: 10,
  },
  header__Body: {
    color: colors.midGrey,
    textAlign: "center",
  },
  formHeader: {
    fontSize: 12,
    fontFamily: fonts.medium,
    color: colors.white,
    textTransform: "uppercase",
    marginBottom: 5,
  },
});

const validationSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
});

interface Props {
  route: any;
  navigation: any;
}

const VisitConfirmedScreen: FunctionComponent<Props> = ({
  route,
  navigation,
}) => {
  const { currentCustomer } = useFirebaseAuth();
  const client = useApolloClient();

  const navigateToNextScreen = () => {
    // There are 3 paths in total from this screen, depending on the entry point
    // either it's passed in as `nextScreen` or we pick one of these options
    const nextCustomerScreen = hasCompletedProfile(currentCustomer as Customer)
      ? "ExistingCustomerReview"
      : "IdVerification";

    navigation.navigate(nextScreen || nextCustomerScreen);
  };

  const [sendInvite] = useMutation(INVITE_MINOR, {
    onCompleted: ({ result }) => {
      if (!result.errors) {
        navigateToNextScreen();
      }
    },
  });

  const { minorId, nextScreen } = route.params;

  const minor = client.readFragment({
    fragment: CustomerFragment,
    id: `Customer:${minorId}`,
  });

  useEffect(() => {
    navigation.addListener("beforeRemove", (e: any) => {
      e.preventDefault();
    });
  }, [navigation]);

  return (
    <SafeAreaView
      style={styles.container}
      edges={["right", "bottom", "top", "left"]}
    >
      <KeyboardAvoidingView style={styles.formContainer}>
        <Formik
          initialValues={{
            emailAddress: "",
            minorId: minor.id,
          }}
          validationSchema={validationSchema}
          onSubmit={(input) => {
            return sendInvite({ variables: { input } });
          }}
        >
          {(formProps) => (
            <>
              <Container>
                <ScrollView style={styles.scrollContainer}>
                  <View style={styles.header}>
                    <Heading size="xl" style={styles.header__Title}>
                      {minor?.firstName || "Minor"} was successfully added to
                      your account!
                    </Heading>
                    <Text style={styles.header__Body}>
                      Would you like to invite them to create their own account
                      on Bodyart? They will only be able to see their past
                      visits and won't be able to create a new visit without
                      your permission.
                    </Text>
                  </View>
                  <View style={styles.formInner}>
                    <Text style={styles.formHeader}>Their Email Address</Text>

                    <TextInputField
                      autoCompleteType="email"
                      name="emailAddress"
                      textContentType="emailAddress"
                      autoCapitalize="none"
                      keyboardType="email-address"
                      placeholder="Email Address"
                      hasError={!!formProps.errors?.emailAddress}
                      onChangeText={(text) =>
                        formProps.handleChange("emailAddress")(text.trim())
                      }
                      onBlur={formProps.handleBlur("emailAddress")}
                      value={formProps.values.emailAddress}
                      touched={!!formProps.touched.emailAddress}
                      error={formProps.errors.emailAddress as string}
                    />
                  </View>
                </ScrollView>
              </Container>

              <StickyFooter>
                <Button
                  title="Skip"
                  onPress={navigateToNextScreen}
                  appearance={Appearance.ghost}
                  style={{ marginBottom: 15 }}
                />

                <Button
                  title="Next"
                  isLoading={formProps.isSubmitting}
                  disabled={!formProps.isValid}
                  onPress={formProps.handleSubmit}
                />
              </StickyFooter>
            </>
          )}
        </Formik>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default VisitConfirmedScreen;

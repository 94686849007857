import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AuthState = {
  customerId: string | null;
  refreshToken: string | null;
  token: string | null;
};

const initialState: AuthState = {
  customerId: null,
  refreshToken: null,
  token: null,
};

type SetAuthSessionFromSessionPayload = {
  customerId: string;
  refreshToken: string;
  token: string;
};

type SetCurrentId = { customerId: string };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthFromSession: (
      _state,
      action: PayloadAction<SetAuthSessionFromSessionPayload>,
    ) => {
      return action.payload;
    },
    resetAuth: () => initialState,
    setCurrentCustomer: (state, action: PayloadAction<SetCurrentId>) => {
      return { ...state, ...action.payload };
    },
  },
});

// Export for convienience
export const {
  setAuthFromSession,
  resetAuth,
  setCurrentCustomer,
} = authSlice.actions;

export default authSlice;

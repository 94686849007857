import React, { FunctionComponent } from "react";
import { ActivityIndicator, StyleSheet } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { colors } from "../../theme";
import {
  AnsweredConsentQuestion,
  AnsweredConsentQuestions,
  setAnsweredConsentQuestions,
} from "../../state/consentFlow";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { CONSENT_QUESTIONS } from "./gql";
import { ConsentQuestionCategory } from "../../types";
import ConsentCategory from "./ConsentCategory";
import { RootState } from "../../state";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
});

export type ConsentAnswers = {
  [questionId: string]: AnsweredConsentQuestion;
};

interface Props {
  route: any;
  navigation: any;
}

const ConsentQuestions: FunctionComponent<Props> = ({ route, navigation }) => {
  const dispatch = useDispatch();
  const existingAnswers = useSelector(
    (state: RootState) => state.consentFlow.answeredConsentQuestions,
  );

  const { store } = route.params;
  const [categoryIdx, setCategoryIdx] = React.useState(0);
  const [answers, setAnswers] = React.useState<AnsweredConsentQuestions>(
    existingAnswers,
  );
  const { data, loading } = useQuery(CONSENT_QUESTIONS, {
    variables: { storeId: store.id },
  });

  const categories = data?.store?.consentQuestionCategories || [];
  const totalCategories = categories.length;
  const category = categories[categoryIdx] as ConsentQuestionCategory;

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      {loading && <ActivityIndicator color={colors.white} size="small" />}

      {!loading && category && (
        <ConsentCategory
          category={category}
          answers={answers}
          setAnswer={(questionId: string, attrs: AnsweredConsentQuestion) =>
            setAnswers({ ...answers, [questionId]: attrs })
          }
          isLastQuestion={categoryIdx === totalCategories - 1}
          onNextPress={() => {
            if (categoryIdx < totalCategories - 1) {
              setCategoryIdx(categoryIdx + 1);
            } else {
              dispatch(setAnsweredConsentQuestions(answers));
              navigation.navigate("ReviewVisit", {
                ...route.params,
              });
            }
          }}
        />
      )}
    </SafeAreaView>
  );
};

export default ConsentQuestions;

import React from "react";
import { StyleSheet, ScrollView, Linking } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigation, useRoute, Route } from "@react-navigation/native";

import KeyboardAvoidingView from "../../components/KeyboardAvoidingView";
import TextInputField from "../../components/TextInputField";
import Button from "../../components/Button";
import { colors } from "../../theme";
import { InputError } from "../../types";
import { RESET_PASSWORD, VERIFY_TOKEN } from "./gql";
import Dialog from "../../components/Dialog";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";
import { DEFAULT_APP_LINK } from "../../constants";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  formContainer: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  scrollViewInner: {
    paddingHorizontal: 20,
    marginTop: 20,
  },
  forgotPassword: {
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 15,
  },
});

const Schema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Your password must be longer than 6 characters."),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), ""], "Passwords must match")
    .required("Password confirmation is required"),
});

const ResetPasswordScreen = () => {
  const navigation = useNavigation();
  const { params } = useRoute<
    Route<string, { token: string; isGuestFlow: boolean }>
  >();
  const [invalidDialogOpen, setInvalidDialogOpen] = React.useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const { loading: isValidating } = useQuery(VERIFY_TOKEN, {
    variables: { token: params?.token },
    onCompleted: ({ result }) => {
      if (result.success === false) {
        setInvalidDialogOpen(true);
      }
    },
    onError: () => {
      setInvalidDialogOpen(true);
    },
  });

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onError: () => setInvalidDialogOpen(true),
  });

  const onConfirmClick = () => {
    setConfirmDialogOpen(false);

    return params?.isGuestFlow
      ? Linking.openURL(DEFAULT_APP_LINK)
      : navigation.navigate("LoginViaEmail");
  };

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <Dialog
        isOpen={invalidDialogOpen}
        onClose={() => setInvalidDialogOpen(false)}
        title="Token is invalid"
        description="We're sorry, but your token has either expired or is invalid. Please request another."
        confirmLabel="OK"
        onConfirm={() => {
          setInvalidDialogOpen(false);
          navigation.navigate("Main", { screen: "ForgotPassword" });
        }}
      />

      <Dialog
        isOpen={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        title="Password reset"
        description="Your password has been reset successfully. Please login to continue."
        confirmLabel="Continue"
        onConfirm={onConfirmClick}
      />

      <KeyboardAvoidingView style={styles.formContainer}>
        <Formik
          initialValues={{
            password: "",
            passwordConfirmation: "",
          }}
          validationSchema={Schema}
          onSubmit={async (input, { setFieldError }) => {
            try {
              const result = await resetPassword({
                variables: {
                  input: {
                    ...input,
                    token: params.token,
                  },
                },
              });
              const errors: [InputError] = result.data?.result?.errors || [];

              if (errors.length > 0) {
                errors.forEach(({ field, message }) =>
                  setFieldError(field, message),
                );
                return;
              }

              setConfirmDialogOpen(true);

              return result;
            } catch (e) {
              console.error(e);
            }
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            touched,
            errors,
            isSubmitting,
            isValid,
          }) => {
            return (
              <>
                <Container>
                  <ScrollView
                    style={styles.scrollView}
                    contentContainerStyle={styles.scrollViewInner}
                  >
                    <TextInputField
                      autoCompleteType="password"
                      name="password"
                      autoCapitalize="none"
                      placeholder="Password"
                      hasError={!!errors?.password}
                      secureTextEntry
                      onChangeText={handleChange("password")}
                      onBlur={handleBlur("password")}
                      textContentType="newPassword"
                      value={values.password}
                      touched={!!touched.password}
                      error={errors.password}
                    />
                    <TextInputField
                      autoCompleteType="password"
                      name="passwordConfirmation"
                      autoCapitalize="none"
                      placeholder="Confirm Password"
                      hasError={!!errors?.passwordConfirmation}
                      secureTextEntry
                      onChangeText={handleChange("passwordConfirmation")}
                      onBlur={handleBlur("passwordConfirmation")}
                      textContentType="newPassword"
                      value={values.passwordConfirmation}
                      touched={!!touched.passwordConfirmation}
                      error={errors.passwordConfirmation}
                    />
                  </ScrollView>
                </Container>
                <StickyFooter>
                  <Button
                    title="Reset Password"
                    onPress={handleSubmit}
                    disabled={!isValid}
                    isLoading={isSubmitting || isValidating}
                  />
                </StickyFooter>
              </>
            );
          }}
        </Formik>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default ResetPasswordScreen;

import React, { FunctionComponent, useState } from "react";
import { includes, without } from "lodash";
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
  TouchableOpacity,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import Button from "../../components/Button";
import Header from "../../components/Header";
import { colors, fonts } from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state";
import { setMedicalHistory } from "../../state/consentFlow";
import { MEDICAL_CONDITIONS, VisitSubject } from "../../constants";
import { Copy } from "./constants";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black,
    flex: 1,
  },
  scrollView: {
    paddingHorizontal: 20,
  },
  conditions: {
    marginVertical: 20,
  },
  condition: {
    borderWidth: 2,
    borderColor: colors.darkestGrey,
    backgroundColor: colors.darkestGrey,
    borderRadius: 60,
    paddingHorizontal: 20,
    marginBottom: 10,
  },
  condition__selected: {
    borderColor: colors.yellow,
  },
  conditionText: {
    lineHeight: 50,
    fontSize: 16,
    fontFamily: fonts.regular,
    color: colors.white,
  },
  conditionText__selected: {
    color: colors.yellow,
  },
});

interface Props {
  route: any;
  navigation: any;
}

const MedicalHistoryForVisitScreen: FunctionComponent<Props> = ({
  route,
  navigation,
}) => {
  const dispatch = useDispatch();
  const medicalHistory = useSelector(
    (state: RootState) => state.consentFlow.medicalHistory,
  );

  const subject = useSelector((state: RootState) => state.consentFlow.subject);
  const [selectedNone, setSelectedNone] = useState<boolean>(false);
  const [selectedMedicalConditions, setSelectedMedicalConditions] = useState<
    string[]
  >(medicalHistory || []);

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <Container>
        <ScrollView style={styles.scrollView}>
          <Header
            title={Copy.title}
            description={Copy.description[subject.role as VisitSubject]}
          />
          <View style={styles.conditions}>
            {MEDICAL_CONDITIONS.map((condition) => {
              const isSelected = includes(selectedMedicalConditions, condition);

              return (
                <TouchableOpacity
                  style={[
                    styles.condition,
                    isSelected && styles.condition__selected,
                  ]}
                  key={condition}
                  onPress={() => {
                    setSelectedNone(false);

                    if (isSelected) {
                      setSelectedMedicalConditions(
                        without(selectedMedicalConditions, condition),
                      );
                    } else {
                      setSelectedMedicalConditions(
                        selectedMedicalConditions.concat([condition]),
                      );
                    }
                  }}
                >
                  <Text
                    style={[
                      styles.conditionText,
                      isSelected && styles.conditionText__selected,
                    ]}
                  >
                    {condition}
                  </Text>
                </TouchableOpacity>
              );
            })}
            <TouchableOpacity
              style={[
                styles.condition,
                selectedNone && styles.condition__selected,
              ]}
              onPress={() => {
                setSelectedMedicalConditions([]);
                setSelectedNone(!selectedNone);
              }}
            >
              <Text
                style={[
                  styles.conditionText,
                  selectedNone && styles.conditionText__selected,
                ]}
              >
                None
              </Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </Container>
      <StickyFooter>
        <Button
          title="Next"
          onPress={() => {
            dispatch(setMedicalHistory(selectedMedicalConditions));
            navigation.navigate("MedicalConcernsForVisit");
          }}
          disabled={selectedMedicalConditions.length === 0 && !selectedNone}
        />
      </StickyFooter>
    </SafeAreaView>
  );
};

export default MedicalHistoryForVisitScreen;

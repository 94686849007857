import React from "react";
import { StyleSheet, View, Image, Text, TouchableOpacity } from "react-native";
import { colors, fonts } from "../../theme";
import { ServiceType, ServiceTypeOffering } from "../../types";

interface Props {
  procedures: ServiceType[];
  onSelect: (category: ServiceType) => any;
  selectedProcedure: ServiceType | null;
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
  },
  procedure: {
    borderWidth: 1,
    borderColor: colors.darkestGrey,
    borderRadius: 21,
    height: 142,
    backgroundColor: colors.white,
    marginBottom: 10,
    flexDirection: "row",
  },
  procedure__selected: {
    borderColor: colors.yellow,
    backgroundColor: colors.yellow,
  },
  procedureInner: {
    flex: 1,
    justifyContent: "center",
    paddingHorizontal: 50,
  },
  procedureName: {
    fontFamily: fonts.semiBold,
    textTransform: "uppercase",
    letterSpacing: 1,
    fontSize: 16,
    lineHeight: 21,
    color: colors.black,
    marginTop: 5,
  },
  procedureImage: {
    marginLeft: "auto",
  },
});

const Images = {
  [ServiceTypeOffering.Tattoo]: {
    width: 134,
    unselected: require("../../../assets/illustrations/service-types/tattoo-unselected.png"),
    selected: require("../../../assets/illustrations/service-types/tattoo-selected.png"),
  },
  [ServiceTypeOffering.PermanentMakeup]: {
    width: 111,
    unselected: require("../../../assets/illustrations/service-types/perm-makeup-unselected.png"),
    selected: require("../../../assets/illustrations/service-types/perm-makeup-selected.png"),
  },
  [ServiceTypeOffering.Piercing]: {
    width: 112,
    unselected: require("../../../assets/illustrations/service-types/piercing-unselected.png"),
    selected: require("../../../assets/illustrations/service-types/piercing-selected.png"),
  },
};

const ProcedureList: React.FC<Props> = ({
  procedures,
  onSelect,
  selectedProcedure,
}) => (
  <View style={styles.container}>
    {procedures.map((procedure) => {
      const isSelected =
        selectedProcedure && procedure.id === selectedProcedure.id;

      const imageMap = Images[procedure.type];

      return (
        <TouchableOpacity
          key={procedure.id}
          onPress={() => onSelect(procedure)}
          style={[styles.procedure, isSelected && styles.procedure__selected]}
        >
          <View style={styles.procedureInner}>
            <Text style={styles.procedureName}>{procedure.name}</Text>
          </View>
          <Image
            source={isSelected ? imageMap.selected : imageMap.unselected}
            style={[
              styles.procedureImage,
              { width: imageMap.width, height: 140 },
            ]}
          />
        </TouchableOpacity>
      );
    })}
  </View>
);

export default ProcedureList;

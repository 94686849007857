type Branch = {
  subscribe: (listener: any) => void;
  initSessionTtl: number | null;
  getLatestReferringParams: () => Promise<() => void>;
  createBranchUniversalObject: () => Promise<unknown>;
  unsubscribe: () => void;
};

const branch: Branch = {
  subscribe: console.log,
  initSessionTtl: null,
  getLatestReferringParams: async () => {
    return console.log;
  },
  createBranchUniversalObject: async () => ({
    showShareSheet: console.log,
  }),
  unsubscribe: console.log,
};

export default branch;

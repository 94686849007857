import * as React from "react";
import "./config";
import {
  getAuth,
  onAuthStateChanged,
  User as FirebaseUser,
} from "firebase/auth";
import client from "../graphql";
import { CURRENT_CUSTOMER } from "../screens/queries";
import { Customer } from "../types";

interface UserState {
  firebase: FirebaseUser | null;
  currentCustomer: Customer | null;
}

interface Context extends UserState {
  refetch: () => Promise<void>;
}

const FirebaseAuthContext = React.createContext<Context | undefined>(undefined);

const auth = getAuth();

const useFirebaseAuth = () => {
  const context = React.useContext(FirebaseAuthContext);
  if (context === undefined) {
    throw new Error(
      "useFirebaseAuth must be used within a FirebaseAuthProvider",
    );
  }
  return context;
};

const FirebaseAuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<UserState>({
    firebase: null,
    currentCustomer: null,
  });

  const refetch = async () => {
    const result = await client.query({ query: CURRENT_CUSTOMER });
    setUser((currentState) => {
      return {
        ...currentState,
        currentCustomer: result.data.viewer,
      };
    });
  };

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && user.uid) {
        const result = await client.query({ query: CURRENT_CUSTOMER });
        setUser({ firebase: user, currentCustomer: result.data.viewer });
      } else {
        setUser({ firebase: null, currentCustomer: null });
      }
    });
    return unsubscribe;
  }, []);

  return (
    <FirebaseAuthContext.Provider value={{ ...user, refetch }}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export { FirebaseAuthProvider, useFirebaseAuth };

import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { Formik } from "formik";
import * as Yup from "yup";
import TextInputField from "../../components/TextInputField";
import Button, { Appearance } from "../../components/Button";
import KeyboardAvoidingView from "../../components/KeyboardAvoidingView";
import Text from "../../components/Text";
import { fonts, colors } from "../../theme";
import { phoneRegExp } from "../../utils/forms/validations";
import { Customer } from "../../types";
import Container from "../../components/Container";
import StickyFooter from "../../components/StickyFooter";

const styles = StyleSheet.create({
  scrollView: {
    padding: 20,
    flex: 1,
  },
  formInner: {
    marginBottom: 40,
  },
  formHeader: {
    fontSize: 12,
    fontFamily: fonts.medium,
    color: colors.white,
    textTransform: "uppercase",
    marginBottom: 5,
  },
  field: {
    marginBottom: 10,
  },
});

const minorValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name required").nullable(),
  lastName: Yup.string().required("Last name required").nullable(),
  emailAddress: Yup.string().email("Must be a valid email").nullable(),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .nullable(),
});

type Props = {
  customer: Customer;
  onSubmit: (input: any, actions: any) => void;
};

const MinorForm: React.FC<Props> = ({ customer, onSubmit }) => {
  const initialValues = {
    minorId: customer.id,
    firstName: customer?.firstName || "",
    lastName: customer?.lastName || "",
    emailAddress: customer?.emailAddress || "",
    phoneNumber: customer?.phoneNumber || "",
  };

  return (
    <KeyboardAvoidingView style={{ flex: 1 }}>
      <Formik
        initialValues={initialValues}
        onSubmit={(input, actions) => onSubmit(input, actions)}
        validationSchema={minorValidationSchema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
          isSubmitting,
          isValid,
        }) => {
          return (
            <>
              <Container>
                <ScrollView
                  style={styles.scrollView}
                  contentContainerStyle={{ paddingBottom: 20 }}
                >
                  <View style={styles.field}>
                    <Text style={styles.formHeader}>Name</Text>
                    <TextInputField
                      name="firstName"
                      placeholder="First name"
                      hasError={!!errors?.firstName}
                      onChangeText={(text) => handleChange("firstName")(text)}
                      onEndEditing={(e) => {
                        handleChange("firstName")(e.nativeEvent.text.trim());
                        handleBlur("firstName");
                      }}
                      value={values.firstName}
                      error={errors?.firstName as string}
                      touched={!!touched.firstName}
                    />
                    <TextInputField
                      name="lastName"
                      placeholder="Last name"
                      hasError={!!errors?.lastName}
                      onChangeText={(text) => handleChange("lastName")(text)}
                      onEndEditing={(e) => {
                        handleChange("lastName")(e.nativeEvent.text.trim());
                        handleBlur("lastName");
                      }}
                      value={values.lastName}
                      error={errors?.lastName as string}
                      touched={!!touched.lastName}
                    />
                  </View>
                  <View style={styles.field}>
                    <Text style={styles.formHeader}>Email Address</Text>
                    <TextInputField
                      name="emailAddress"
                      textContentType="emailAddress"
                      autoCapitalize="none"
                      autoCompleteType="email"
                      placeholder="email@domain.com"
                      hasError={!!errors?.emailAddress}
                      onChangeText={(text) =>
                        handleChange("emailAddress")(text.trim())
                      }
                      onBlur={handleBlur("emailAddress")}
                      value={values.emailAddress}
                      error={errors?.emailAddress as string}
                      touched={!!touched.emailAddress}
                    />
                  </View>
                  <View style={styles.field}>
                    <Text style={styles.formHeader}>Phone Number</Text>
                    <TextInputField
                      name="phoneNumber"
                      placeholder="123-456-7890"
                      keyboardType="phone-pad"
                      hasError={!!errors?.phoneNumber}
                      onChangeText={(text) => handleChange("phoneNumber")(text)}
                      onBlur={handleBlur("phoneNumber")}
                      value={values.phoneNumber}
                      error={errors?.phoneNumber as string}
                      touched={!!touched.phoneNumber}
                    />
                  </View>
                </ScrollView>
              </Container>
              <StickyFooter>
                <Button
                  title="Save Changes"
                  onPress={handleSubmit}
                  disabled={!isValid}
                  isLoading={isSubmitting}
                  appearance={Appearance.primary}
                  style={{ width: "100%", marginTop: "auto", marginBottom: 10 }}
                />
              </StickyFooter>
            </>
          );
        }}
      </Formik>
    </KeyboardAvoidingView>
  );
};

export default MinorForm;

import React from "react";
import { gql } from "@apollo/client";
import { StyleSheet } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMutation } from "@apollo/client";
import { useNavigation, useRoute, Route } from "@react-navigation/native";

import KeyboardAvoidingView from "../../components/KeyboardAvoidingView";
import { InputError } from "../../types";
import { Customer } from "../../fragments";
import Form from "./Form";

import { Colors } from "../../styles";
import { useFirebaseAuth } from "../../firebase/AuthProvider";

const PhoneNumberInputScreen = () => {
  const navigation = useNavigation();
  const route = useRoute<Route<string, { nextScreenForSection: string }>>();
  const [setPhoneNumber] = useMutation(SET_PHONE_NUMBER);
  const { currentCustomer } = useFirebaseAuth();
  const nextScreen = route.params?.nextScreenForSection;

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      <KeyboardAvoidingView style={styles.formContainer}>
        <Form
          customer={currentCustomer}
          submitText={nextScreen ? "Save Changes" : "Next: Visit Details"}
          onSubmit={async (input, { setFieldError }) => {
            const result = await setPhoneNumber({
              variables: {
                input,
              },
            });

            const errors: [InputError] = result.data?.result?.errors || [];

            if (errors.length > 0) {
              errors.forEach(({ field, message }) =>
                setFieldError(field, message),
              );
              return;
            }

            navigation.navigate(nextScreen || "NewServiceForVisit");
          }}
        />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export const SET_PHONE_NUMBER = gql`
  mutation SetPhoneNumber($input: SetPhoneNumberInput!) {
    result: setPhoneNumber(input: $input) {
      errors {
        message
        field
      }
      customer {
        ...Customer
      }
    }
  }
  ${Customer}
`;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.brand.blue,
    flex: 1,
  },
  formContainer: {
    flex: 1,
  },
});

export default PhoneNumberInputScreen;

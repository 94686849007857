import { gql } from "@apollo/client";
import { Customer } from "../../fragments";

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($input: UpdateProfileInformationInput!) {
    result: updateProfileInformation(input: $input) {
      customer {
        ...Customer
      }
      errors {
        field
        message
      }
    }
  }
  ${Customer}
`;

export const UPDATE_MINOR_PROFILE = gql`
  mutation UpdateMinorProfile($input: UpdateMinorInput!) {
    result: updateMinor(input: $input) {
      customer {
        ...Customer
      }
      errors {
        field
        message
      }
    }
  }
  ${Customer}
`;

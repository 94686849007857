import { TextStyle } from "react-native";

import * as Colors from "./colors";

type FontSize = "x10" | "x20" | "x30" | "x40" | "x50" | "x60" | "x70";
export const fontSize: Record<FontSize, TextStyle> = {
  x10: {
    fontSize: 13,
  },
  x20: {
    fontSize: 14,
  },
  x30: {
    fontSize: 16,
  },
  x40: {
    fontSize: 19,
  },
  x50: {
    fontSize: 24,
  },
  x60: {
    fontSize: 32,
  },
  x70: {
    fontSize: 38,
  },
};

type Font = "regular" | "medium" | "semibold" | "bold" | "monospace";
export const font: Record<Font, TextStyle> = {
  regular: {
    fontFamily: "Poppins_400Regular",
  },
  medium: {
    fontFamily: "Poppins_500Medium",
  },
  semibold: {
    fontFamily: "Poppins_600SemiBold",
  },
  bold: {
    fontFamily: "Poppins_700Bold",
  },
  monospace: {
    fontFamily: "RobotoMono_400Regular",
  },
};

type LetterSpacing = "x30" | "x40";
export const letterSpacing: Record<LetterSpacing, number> = {
  x30: 2,
  x40: 3,
};

type LineHeight = "x10" | "x20" | "x30" | "x40" | "x50" | "x60" | "x70";
export const lineHeight: Record<LineHeight, TextStyle> = {
  x10: {
    lineHeight: 20,
  },
  x20: {
    lineHeight: 22,
  },
  x30: {
    lineHeight: 24,
  },
  x40: {
    lineHeight: 26,
  },
  x50: {
    lineHeight: 32,
  },
  x60: {
    lineHeight: 38,
  },
  x70: {
    lineHeight: 44,
  },
};

type Bold = "x10" | "x20" | "x30" | "x40" | "x50" | "x60" | "x70";
export const bold: Record<Bold, TextStyle> = {
  x10: {
    ...fontSize.x10,
    ...lineHeight.x10,
    ...font.bold,
  },
  x20: {
    ...fontSize.x20,
    ...lineHeight.x20,
    ...font.bold,
  },
  x30: {
    ...fontSize.x30,
    ...lineHeight.x30,
    ...font.bold,
  },
  x40: {
    ...fontSize.x40,
    ...lineHeight.x40,
    ...font.bold,
  },
  x50: {
    ...fontSize.x50,
    ...lineHeight.x50,
    ...font.bold,
  },
  x60: {
    ...fontSize.x60,
    ...lineHeight.x60,
    ...font.bold,
  },
  x70: {
    ...fontSize.x70,
    ...lineHeight.x70,
    ...font.bold,
  },
};

type Header = "x10" | "x20" | "x30" | "x40" | "x50" | "x60" | "x70";
export const header: Record<Header, TextStyle> = {
  x10: {
    ...bold.x10,
    color: Colors.neutral.white,
  },
  x20: {
    ...bold.x20,
    color: Colors.neutral.white,
  },
  x30: {
    ...bold.x30,
    color: Colors.neutral.white,
  },
  x40: {
    ...bold.x40,
    color: Colors.neutral.white,
  },
  x50: {
    ...bold.x50,
    color: Colors.neutral.white,
  },
  x60: {
    ...bold.x60,
    color: Colors.neutral.white,
  },
  x70: {
    ...bold.x70,
    color: Colors.neutral.white,
  },
};

type Semibold = "x10" | "x20" | "x30" | "x40" | "x50";
export const semibold: Record<Semibold, TextStyle> = {
  x10: {
    ...fontSize.x10,
    ...lineHeight.x10,
    ...font.semibold,
  },
  x20: {
    ...fontSize.x20,
    ...lineHeight.x20,
    ...font.semibold,
  },
  x30: {
    ...fontSize.x30,
    ...lineHeight.x30,
    ...font.semibold,
  },
  x40: {
    ...fontSize.x40,
    ...lineHeight.x40,
    ...font.semibold,
  },
  x50: {
    ...fontSize.x50,
    ...lineHeight.x50,
    ...font.semibold,
  },
};

type Subheader = "x10" | "x20" | "x30" | "x40" | "x50";
export const subheader: Record<Subheader, TextStyle> = {
  x10: {
    ...semibold.x10,
    color: Colors.neutral.white,
  },
  x20: {
    ...semibold.x20,
    color: Colors.neutral.white,
  },
  x30: {
    ...semibold.x30,
    color: Colors.neutral.white,
  },
  x40: {
    ...semibold.x40,
    color: Colors.neutral.white,
  },
  x50: {
    ...semibold.x50,
    color: Colors.neutral.white,
  },
};

type Label = "x30";
export const label: Record<Label, TextStyle> = {
  x30: {
    ...semibold.x30,
    color: Colors.neutral.white,
    textTransform: "uppercase",
  },
};

type Regular = "x10" | "x20" | "x30" | "x40" | "x50";
export const regular: Record<Regular, TextStyle> = {
  x10: {
    ...fontSize.x10,
    ...lineHeight.x10,
    ...font.regular,
    color: Colors.neutral.s800,
  },
  x20: {
    ...fontSize.x20,
    ...lineHeight.x20,
    ...font.regular,
    color: Colors.neutral.s800,
  },
  x30: {
    ...fontSize.x30,
    ...lineHeight.x30,
    ...font.regular,
    color: Colors.neutral.s800,
  },
  x40: {
    ...fontSize.x40,
    ...lineHeight.x40,
    ...font.regular,
    color: Colors.neutral.s800,
  },
  x50: {
    ...fontSize.x50,
    ...lineHeight.x50,
    ...font.regular,
    color: Colors.neutral.s800,
  },
};

type Body = "x10" | "x20" | "x30" | "x40" | "x50";
export const body: Record<Body, TextStyle> = {
  x10: {
    ...regular.x10,
    color: Colors.neutral.s800,
  },
  x20: {
    ...regular.x20,
    color: Colors.neutral.s800,
  },
  x30: {
    ...regular.x30,
    color: Colors.neutral.s800,
  },
  x40: {
    ...regular.x40,
    color: Colors.neutral.s800,
  },
  x50: {
    ...regular.x50,
    color: Colors.neutral.s800,
  },
};

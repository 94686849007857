import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SubmittedInformation = {
  name: string;
  label: string;
  value: string;
};

export type MedicalConcerns = {
  answers: {
    [questionName: string]: boolean;
  };
  additionalNotes?: string;
};

export type SelectedService = {
  id: string;
  serviceId: string;
  submittedInformation?: SubmittedInformation[];
  isExistingService: boolean;
};

export type AnsweredConsentQuestion = {
  questionId: string;
  question: string;
  value: string;
  category: string;
  categoryId: string;
  isFlagged: boolean;
};

export type AnsweredConsentQuestions = {
  [id: string]: AnsweredConsentQuestion;
};

export type ConsentSubject = {
  id: string | null;
  role: "self" | "minor" | null;
};

export type ConsentFlowState = {
  seenStoreSplashScreen: boolean;
  subject: ConsentSubject;
  services: {
    [id: string]: SelectedService;
  };
  answeredConsentQuestions: AnsweredConsentQuestions;
  medicalHistory: string[];
  medicalConcerns: MedicalConcerns;
};

export type BootstrapConsentFlowState = {
  seenStoreSplashScreen?: boolean;
  subject?: ConsentSubject;
  services?: SelectedService[];
  answeredConsentQuestions?: AnsweredConsentQuestions;
  medicalHistory: string[];
  medicalConcerns: MedicalConcerns;
};

const initialState: ConsentFlowState = {
  seenStoreSplashScreen: false,
  subject: {
    id: null,
    role: null,
  },
  services: {},
  answeredConsentQuestions: {},
  medicalHistory: [],
  medicalConcerns: {
    answers: {},
    additionalNotes: undefined,
  },
};

const consentFlow = createSlice({
  name: "consentFlow",
  initialState,
  reducers: {
    reset: () => initialState,
    bootstrap: (state, action: PayloadAction<BootstrapConsentFlowState>) => {
      state.medicalConcerns = action.payload.medicalConcerns;
      state.medicalHistory = action.payload.medicalHistory;

      // Services are bootstrapped as a list and we want to transform them into a map
      state.services = (action.payload.services || []).reduce(
        (acc: { [id: string]: SelectedService }, service: SelectedService) => {
          return {
            ...acc,
            [service.id]: service,
          };
        },
        {},
      );

      return state;
    },
    upsertService: (state, action: PayloadAction<SelectedService>) => {
      state.services[action.payload.id] = action.payload;
    },
    removeService: (state, action: PayloadAction<SelectedService>) => {
      delete state.services[action.payload.id];
    },
    setMedicalHistory: (state, action: PayloadAction<string[]>) => {
      state.medicalHistory = action.payload;
    },
    setMedicalConcerns: (state, action: PayloadAction<MedicalConcerns>) => {
      state.medicalConcerns = action.payload;
    },
    setAnsweredConsentQuestions: (
      state,
      action: PayloadAction<AnsweredConsentQuestions>,
    ) => {
      state.answeredConsentQuestions = action.payload;
    },
    setSubject: (state, action: PayloadAction<ConsentSubject>) => {
      state.subject = action.payload;
    },
    setHasSeenStoreSplashScreen: (state) => {
      state.seenStoreSplashScreen = true;
    },
  },
});

// Export for convienience
export const {
  bootstrap,
  reset,
  upsertService,
  removeService,
  setMedicalConcerns,
  setMedicalHistory,
  setAnsweredConsentQuestions,
  setHasSeenStoreSplashScreen,
  setSubject,
} = consentFlow.actions;

export default consentFlow;
